import {
  AlarmTypeMap,
  EndpointType,
  EndpointTypeMap,
  ReportType,
  ReportTypeMap
} from "generated-proto-files/schema_gen_pb";
import {
  ContentRuleType,
  ContentRuleTypeMap,
  FeatureRuleType,
  FeatureRuleTypeMap,
  GeneralRuleType,
  GeneralRuleTypeMap,
  PeeringRuleType,
  PeeringRuleTypeMap,
  SubscriptionTier,
  SubscriptionTierMap
} from "generated-proto-files/ccsubs_gen_pb";
import { SubscriptionType } from "generated-proto-files/subscriptions_pb";
import { Endpoint } from "generated-proto-files/api_pb";
import { unreachable } from "../../../utils/switch";
import { label } from "../../../core/global";
import { PlanCategories } from "./commonTierRules";

export const ccniPlanCategory = [
  "price",
  "basic",
  "premium",
  "general",
  "peering",
  "lookback",
  "report",
  "endpoints",
  "sso",
  "api"
] as const;

export const enforceCCNIRules = (): boolean => {
  return !!window.xw.tierInfo?.featureTierInfo?.getCcniRules()?.getDoEnforce();
};

// This enables tiering only for historical data lookup.
// Switch back to enforceCCNIRules() ^^^ once doEnforce is enabled at global level for all tiers.
export const enforceCCNIContentRules = (): boolean => {
  const enforceContentRules = !!window.xw.tierInfo?.featureTierInfo
    ?.getCcniRules()
    ?.getDoEnforceContentRules();
  // if false, revert back to default behavior
  if (!enforceContentRules) {
    return enforceCCNIRules();
  }
  return enforceContentRules;
};

// This enables tiering only for policy rules enforcement.
// Switch back to enforceCCNIRules() ^^^ once doEnforce is enabled at global level for all tiers.
export const enforceCCNIPolicyRules = (): boolean => {
  const enforcePolicyRules = !!window.xw.tierInfo?.featureTierInfo
    ?.getCcniRules()
    ?.getDoEnforcePolicyRules();
  // if false, revert back to default behavior
  if (!enforcePolicyRules) {
    return enforceCCNIRules();
  }
  return enforcePolicyRules;
};

// Backend unable to use oneOf Endpoint. Larger change required if it has to be an enum.
// UI maintaining mapping for now
export function getSubscriptionNotificationEnumType(
  type: EndpointTypeMap[keyof EndpointTypeMap]
): Endpoint.EndpointTypeCase | undefined {
  switch (type) {
    case EndpointType.EMAIL:
      return Endpoint.EndpointTypeCase.EMAIL;
    case EndpointType.S3:
      return Endpoint.EndpointTypeCase.S3;
    case EndpointType.GOOGLE_STORAGE:
      return Endpoint.EndpointTypeCase.GOOGLE_STORAGE;
    case EndpointType.SMS:
      return Endpoint.EndpointTypeCase.SMS;
    case EndpointType.SLACK:
      return Endpoint.EndpointTypeCase.SLACK;
    case EndpointType.TEAMS:
      return Endpoint.EndpointTypeCase.MICROSOFT_TEAMS;
    case EndpointType.WEBEX:
      return Endpoint.EndpointTypeCase.WEBEX_TEAMS;
    case EndpointType.PAGER_DUTY:
      return Endpoint.EndpointTypeCase.PAGER_DUTY;
    case EndpointType.TEST:
      return undefined;
    case EndpointType.INVALID_ENDPOINT:
      return undefined;
    default:
      unreachable(type);
      return undefined;
  }
}

export function getNotificationEnumMap(): Map<
  EndpointTypeMap[keyof EndpointTypeMap],
  Endpoint.EndpointTypeCase
> {
  const notificationMapper = new Map<
    EndpointTypeMap[keyof EndpointTypeMap],
    Endpoint.EndpointTypeCase
  >();
  for (const value of Object.values(EndpointType)) {
    const endpointValue = getSubscriptionNotificationEnumType(value);
    if (value !== undefined && endpointValue) {
      notificationMapper.set(
        value as unknown as EndpointTypeMap[keyof EndpointTypeMap],
        endpointValue
      );
    }
  }
  return notificationMapper;
}

export const tierRestrictedAlarms = ():
  | AlarmTypeMap[keyof AlarmTypeMap][]
  | undefined => {
  return enforceCCNIPolicyRules()
    ? window.xw.tierInfo?.featureTierInfo
        ?.getCcniRules()
        ?.getProhibitedAlarmsList()
    : undefined;
};

export const getCurrentCCNIPlan = ():
  | SubscriptionTierMap[keyof SubscriptionTierMap]
  | undefined => {
  return window.xw.tierInfo?.featureTierInfo?.getCcniRules()?.getFeatureTier();
};

export const isCCNITrial = (): boolean => {
  return (
    window.xw.tierInfo?.features?.getNetworkInsightsType() ===
    SubscriptionType.TYPE_TRIAL
  );
};

export const isMaxCCNITier = (): boolean => {
  return getCurrentCCNIPlan() === SubscriptionTier.PREMIER;
};

export const isFreeCCNIUser = (): boolean => {
  return getCurrentCCNIPlan() === SubscriptionTier.FREE;
};
export const hasROVAccess = (): boolean => {
  const currentTier = getCurrentCCNIPlan();
  return (
    currentTier === SubscriptionTier.ADVANTAGE ||
    currentTier === SubscriptionTier.PREMIER
  );
};

export const tierRestrictedNotifications = ():
  | Endpoint.EndpointTypeCase[]
  | undefined => {
  if (enforceCCNIRules()) {
    const notificationEnumMap = getNotificationEnumMap();
    const prohibitedEndpoints: Endpoint.EndpointTypeCase[] = [];
    window.xw.tierInfo?.featureTierInfo
      ?.getCcniRules()
      ?.getProhibitedEndpointsList()
      .forEach((subscriptionNotification) => {
        const ep = notificationEnumMap.get(subscriptionNotification);
        if (ep) {
          prohibitedEndpoints.push(ep);
        }
      });
    return prohibitedEndpoints;
  }
  return undefined;
};

export const tierRestrictedFeatures = ():
  | FeatureRuleTypeMap[keyof FeatureRuleTypeMap][]
  | undefined => {
  return enforceCCNIRules()
    ? window.xw.tierInfo?.featureTierInfo
        ?.getCcniRules()
        ?.getProhibitedFeaturesList()
    : undefined;
};

export const hasAPIAccess = (): boolean => {
  return true;
  // Saving this for future change of mind
  /*!tierRestrictedFeatures()?.includes(
    FeatureRuleType.FEATURE_RULE_API_ACCESS
  );*/
};

// Utility method to access if user has report access
export const hasReportAccess = (
  reportName?: ReportTypeMap[keyof ReportTypeMap]
): boolean => {
  const reportRules = enforceCCNIRules()
    ? window.xw.tierInfo?.featureTierInfo
        ?.getCcniRules()
        ?.getProhibitedReportsList()
    : undefined;
  return reportName && reportRules
    ? !reportRules?.find((rule) => rule === reportName)
    : !reportRules?.length;
};

// To check if topology available based on Config value. We need a day to collect the data before enabling UI.
export const isPrefixTopologyConfigOn = (): boolean => {
  if (window.xw.environmentConfig === undefined) {
    return true;
  }
  return window.xw.environmentConfig?.topology_ui_enable === "true";
};

// Utility method to check if the user has access to Topology based on tiering
export const hasPrefixTopologyAccess = (): boolean => {
  if (isPrefixTopologyConfigOn()) {
    const contentRules = window.xw.tierInfo?.featureTierInfo
      ?.getCcniRules()
      ?.getContentRulesList();
    const currentRule = contentRules?.filter(
      (rule) => rule.getRule() === ContentRuleType.CONTENT_RULE_PREFIX_TOPOLOGY
    );
    if (currentRule && currentRule?.length > 0) {
      return true;
    }
  }
  return false;
};

// Utility method to access if user has notifcation access
export const hasNotificationAccess = (
  notificationType: Endpoint.EndpointTypeCase
): boolean => {
  return !tierRestrictedNotifications()?.includes(notificationType);
};

export const generalRuleLabelDescription = (
  generalRuleMap: GeneralRuleTypeMap[keyof GeneralRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (generalRuleMap) {
    case GeneralRuleType.GENERAL_RULE_POLICIES:
      return {
        label: label.intentBasedPolicies,
        description: label.intentBasedPoliciesDescription
      };
    case GeneralRuleType.GENERAL_RULE_ALARMS:
      return {
        label: label.alarmDetails,
        description: label.alarmDetailDescription
      };
    case GeneralRuleType.GENERAL_RULE_PREFIXES:
      return {
        label: label.prefixDetails,
        description: label.prefixDetailDescription
      };
    case GeneralRuleType.GENERAL_RULE_PREFIX_LOOKING_GLASS:
      return {
        label: label.prefixLookingGlass,
        description: label.prefixLookingGlassDescription
      };
    case GeneralRuleType.GENERAL_RULE_ASNS:
      return {
        label: label.asnDetails,
        description: label.asnDetailsDescription
      };
    case GeneralRuleType.GENERAL_RULE_ASN_LOOKING_GLASS:
      return {
        label: label.asnLookingGlass,
        description: label.asnLookingGlassDescription
      };
    case GeneralRuleType.INVALID_GENERAL_RULE_TYPE:
      return undefined;
    default:
      unreachable(generalRuleMap);
      return undefined;
  }
};

export const peeringRulesLabelDescription = (
  peeringRuleMap: PeeringRuleTypeMap[keyof PeeringRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (peeringRuleMap) {
    case PeeringRuleType.PEERING_RULE_MANAGE_DEVICES:
      return {
        label: label.manageExistingPeerDevices,
        description: label.manageExistingPeerDevicesDescription
      };
    case PeeringRuleType.PEERING_RULE_ADD_DEVICES:
      return {
        label: label.addNewPeerDevices,
        description: label.addNewPeerDevicesDescription
      };
    case PeeringRuleType.PEERING_RULE_GET_DEVICES:
      return {
        label: label.peerDeviceDetails,
        description: label.peerDeviceDetailsDescription
      };
    case PeeringRuleType.INVALID_PEERING_RULE_TYPE:
      return undefined;
    default:
      unreachable(peeringRuleMap);
      return undefined;
  }
};

export const lookbackRulesLabelDescription = (
  contentRuleMap: ContentRuleTypeMap[keyof ContentRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (contentRuleMap) {
    case ContentRuleType.CONTENT_RULE_BGP_ALARM_UPDATE_HISTORY:
      return {
        label: label.alarmBGPUpdateHistory,
        description: label.alarmBGPUpdateHistoryDescription
      };
    case ContentRuleType.CONTENT_RULE_BGP_ASN_UPDATE_HISTORY:
      return {
        label: label.asnBGPUpdateHistory,
        description: label.asnBGPUpdateHistoryDescription
      };
    case ContentRuleType.CONTENT_RULE_BGP_PEER_DEVICE_UPDATE_HISTORY:
      return {
        label: label.peerDeviceBGPUpdateHistory,
        description: label.peerDeviceBGPUpdateHistoryDescription
      };
    case ContentRuleType.CONTENT_RULE_BGP_PREFIX_UPDATE_HISTORY:
      return {
        label: label.prefixBGPUpdateHistory,
        description: label.prefixBGPUpdateHistoryDescription
      };
    case ContentRuleType.CONTENT_RULE_BGP_UPDATE_HISTORY:
      return {
        label: label.bgpUpdateHistory,
        description: label.bgpUpdateHistoryDescription
      };
    case ContentRuleType.CONTENT_RULE_ALARM_HISTORY:
      return {
        label: label.alarmHistory,
        description: label.alarmHistoryDescription
      };
    case ContentRuleType.CONTENT_RULE_PREFIX_TOPOLOGY:
      return {
        label: label.prefixTopologyHistory,
        description: label.prefixTopologyDescription
      };
    case ContentRuleType.CONTENT_RULE_ROUTE_ORIGIN_VALIDATION:
      return {
        label: label.routeOriginValidation,
        description: label.routeOriginValidationDescription
      };
    case ContentRuleType.INVALID_CONTENT_RULE_TYPE:
      return undefined;
    default:
      unreachable(contentRuleMap);
      return undefined;
  }
};

export const reportRulesLabelDescription = (
  reportMap: ReportTypeMap[keyof ReportTypeMap]
): { label: string; description: string } | undefined => {
  switch (reportMap) {
    case ReportType.ASN_ROUTING:
      return {
        label: label.dailyASN,
        description: label.dailyASNDescription
      };
    case ReportType.PEER_STATS:
      return {
        label: label.allPeerReport,
        description: label.allPeerReportDescription
      };
    case ReportType.INVALID_REPORT:
      return undefined;
    default:
      unreachable(reportMap);
      return undefined;
  }
};

export const endpointRulesLabelDescription = (
  endpointMap: EndpointTypeMap[keyof EndpointTypeMap]
): { label: string; description: string } | undefined => {
  switch (endpointMap) {
    case EndpointType.EMAIL:
      return {
        label: label.enums.endpoints.email,
        description: label.emailDescription
      };
    case EndpointType.S3:
      return {
        label: label.enums.endpoints.s3,
        description: label.s3Description
      };
    case EndpointType.GOOGLE_STORAGE:
      return {
        label: label.enums.endpoints.google,
        description: label.gsDescription
      };
    case EndpointType.SLACK:
      return {
        label: label.enums.endpoints.slack,
        description: label.slackDescription
      };
    case EndpointType.SMS:
      return {
        label: label.enums.endpoints.sms,
        description: label.smsDescription
      };
    case EndpointType.WEBEX:
      return {
        label: label.enums.endpoints.teams,
        description: label.webexDescription
      };
    case EndpointType.TEAMS:
      return {
        label: label.enums.endpoints.microsoft,
        description: label.teamsDescription
      };
    case EndpointType.PAGER_DUTY:
      return {
        label: label.enums.endpoints.pager,
        description: label.pagerDutyDescription
      };
    case EndpointType.TEST:
      return undefined;
    case EndpointType.INVALID_ENDPOINT:
      return undefined;
    default:
      unreachable(endpointMap);
      return undefined;
  }
};

export const apiRulesLabelDescription = (
  ssoMap: FeatureRuleTypeMap[keyof FeatureRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (ssoMap) {
    case FeatureRuleType.FEATURE_RULE_API_ACCESS:
      return {
        label: label.apiUsage,
        description: label.apiUsageDescription
      };
    case FeatureRuleType.FEATURE_RULE_TECH_SUPPORT:
      return {
        label: label.technicalSupport,
        description: label.technicalSupportDescription
      };
    case FeatureRuleType.INVALID_FEATURE_RULE_TYPE:
      return undefined;
    default:
      unreachable(ssoMap);
      return undefined;
  }
};

export const getCCNICategoriesLabel = (categories: PlanCategories): string => {
  const ccniCategories = categories as typeof ccniPlanCategory[number];
  switch (ccniCategories) {
    case "price":
      return label.permittedPrefixes;
    case "basic":
      return label.basicAlarms;
    case "premium":
      return label.premiumAlarms;
    case "general":
      return label.generalFeatures;
    case "peering":
      return label.peeringFeatures;
    case "lookback":
      return label.dataLookBack;
    case "report":
      return label.reportCreation;
    case "endpoints":
      return label.endpoints;
    case "sso":
      return label.singleSignOn;
    case "api":
      return label.api;
    default:
      unreachable(ccniCategories);
      return label.null;
  }
};
