import {
  ContentRuleType,
  ContentRuleTypeMap,
  SignonRuleType,
  SignonRuleTypeMap,
  SubscriptionTier,
  SubscriptionTierMap,
  SubscriptionPlatformMap,
  SubscriptionPlatform,
  SubscriptionFeature,
  SubscriptionFeatureMap,
  LimitRuleType,
  LimitRuleTypeMap
} from "generated-proto-files/ccsubs_gen_pb";
import { FeatureTierInfo } from "generated-proto-files/tiers_pb";
import { TierDefinition } from "generated-proto-files/datasheets_pb";
import { unreachable } from "../../../utils/switch";
import { TimeRangeOption } from "../../../utils/date";
import { label } from "../../../core/global";
import * as React from "react";
import { Icon, themeName } from "../../../components/icon/icon";
import uuid from "../../../utils/uuid";
import { PageSection } from "../../../components/pageSection/pageSection";
import { Expander } from "../../../components/expander/expander";
import { reverseMapping } from "../../../utils/enum";
import {
  ccniPlanCategory,
  enforceCCNIContentRules,
  getCCNICategoriesLabel,
  isCCNITrial
} from "./ccniTierRules";
import {
  getTrustCategoriesLabel,
  isCCTITrial,
  trustPlanCategory
} from "./cctiTierRules";
import {
  enforceCCTAContentRules,
  getFlowCategoriesLabel,
  isCCTATrial,
  trafficPlanCategory
} from "./cctaTierRules";
import { Features } from "generated-proto-files/glokta_pb";
import { SVGIcon } from "../../../components/icon/SVGIcon";
import CCNIAdvantageTier from "../../../assets/images/color-icon-ccni-advantage-tier.png";
import CCNIEssentialsTier from "../../../assets/images/color-icon-ccni-essentials-tier.png";
import CCNIFreeTier from "../../../assets/images/color-icon-ccni-free-tier.png";
import CCNIPremierTier from "../../../assets/images/color-icon-ccni-premier-tier.png";
import CCTAEssentialsTier from "../../../assets/images/color-icon-ccta-essentials-tier.png";
import CCTAEssentialsFunctionTier from "../../../assets/images/color-icon-ccta-essentials+function-tier.png";
import CCTIEssentialsTier from "../../../assets/images/color-icon-ccti-essentials-tier.png";
import CCTIEmbeddedVoucherTier from "../../../assets/images/color-icon-ccti-embeddedvoucher-tier.png";
import CAvailable from "../../../assets/icons/circle-available.svg";
import CEmpty from "../../../assets/icons/circle-empty.svg";
import { SelectOption } from "../../../components/form/select/select";

export interface TierInfo {
  featureTierInfo?: FeatureTierInfo;
  subscriptionTier?: SubscriptionTierInfo;
  features?: Features;
}

export interface SubscriptionTierInfo {
  isFreeUser: boolean;
  hasActiveSubscription: boolean;
  usageAboveThreshold: boolean;
}

export type PlanCategories =
  | typeof ccniPlanCategory[number]
  | typeof trustPlanCategory[number]
  | typeof trafficPlanCategory[number];

export type Availability =
  | "available"
  | "notavailable"
  | "partial"
  | "additional";
export type TierPlanAvailable = {
  tier: SubscriptionTierMap[keyof SubscriptionTierMap];
  availability: React.ReactNode;
  planValue: Availability | string;
};
export type PlanItem = {
  header: React.ReactNode;
  description: React.ReactNode;
  tierPlanAvailablity: TierPlanAvailable[];
};
export type Plans = {
  category: PlanCategories;
  items: PlanItem[];
  groupHeader: TierPlanAvailable[];
};

export type PlanOffering = {
  header?: string;
  description?: string;
  tier: SubscriptionTierMap[keyof SubscriptionTierMap];
  planValue: Availability | string;
};

export type DaysLookback = "1h" | 1 | 7 | 30 | 90 | "Custom";

export function getLookbackMapper(
  days: DaysLookback
): TimeRangeOption | undefined {
  switch (days) {
    case "1h":
      return TimeRangeOption.lastHour;
    case 1:
      return TimeRangeOption.lastDay;
    case 7:
      return TimeRangeOption.lastWeek;
    case 30:
      return TimeRangeOption.last30Days;
    case 90:
      return TimeRangeOption.last90Days;
    case "Custom":
      return TimeRangeOption.custom;
    default:
      unreachable(days);
      return undefined;
  }
}

export const getCurrentTier = ():
  | SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
  | undefined => {
  const { hash } = window.location;
  if (hash) {
    if (hash.startsWith("/extRoute", 1)) {
      return SubscriptionFeature.FEATURE_CCNI;
    } else if (hash.startsWith("/traffic", 1)) {
      return SubscriptionFeature.FEATURE_CCTA;
    } else if (hash.startsWith("/trust", 1)) {
      return SubscriptionFeature.FEATURE_CCTI;
    }
  }
  return undefined;
};

export const isCurrentTierTrial = (
  featureType: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): boolean => {
  switch (featureType) {
    case SubscriptionFeature.FEATURE_CCNI:
      return isCCNITrial();
    case SubscriptionFeature.FEATURE_CCTI:
      return isCCTITrial();
    case SubscriptionFeature.FEATURE_CCTA:
      return isCCTATrial();
    case SubscriptionFeature.INVALID_FEATURE:
      return false;
    default:
      unreachable(featureType);
      return false;
  }
};

export const tierRestrictedLookbackDays = (
  contentRule: ContentRuleTypeMap[keyof ContentRuleTypeMap] = ContentRuleType.CONTENT_RULE_BGP_ASN_UPDATE_HISTORY,
  tier = getCurrentTier()
): number | undefined => {
  if (!tier) {
    return undefined;
  }
  const contentRules = window.xw.tierInfo?.featureTierInfo
    ?.getCcniRules()
    ?.getContentRulesList();
  const currentRule = contentRules?.filter(
    (rule) => contentRule === rule.getRule()
  );
  const numberofDays =
    (currentRule && currentRule.length && currentRule[0].getDays()) || 0;
  switch (tier) {
    case SubscriptionFeature.FEATURE_CCNI:
      if (numberofDays && enforceCCNIContentRules()) {
        return numberofDays;
      }
      return undefined;
    case SubscriptionFeature.FEATURE_CCTI:
      return undefined;
    case SubscriptionFeature.FEATURE_CCTA: {
      const contentCCTARules = window.xw.tierInfo?.featureTierInfo
        ?.getCctaRules()
        ?.getContentRulesList();
      const currentCCTARule = contentCCTARules?.filter(
        (rule) => contentRule === rule.getRule()
      );
      const numOfDays = currentCCTARule?.[0]?.getDays() || 0;
      if (numOfDays && enforceCCTAContentRules()) {
        return numOfDays;
      }
      return undefined;
    }
    default:
      unreachable(tier);
      return undefined;
  }
};

export const tierRestrictedDefaultLookbackDays = (
  contentRule: ContentRuleTypeMap[keyof ContentRuleTypeMap] = ContentRuleType.CONTENT_RULE_BGP_ASN_UPDATE_HISTORY,
  tier = getCurrentTier()
): number | undefined => {
  const days = tierRestrictedLookbackDays(contentRule, tier);
  if (days && days > 1) {
    return 7; // default should be a week/7 days if not free tier
  }
  return days;
};

export const tierRestrictedLookback = (
  tier = getCurrentTier()
): TimeRangeOption | undefined => {
  if (!tier) {
    return undefined;
  }
  switch (tier) {
    case SubscriptionFeature.FEATURE_CCNI:
      // eslint-disable-next-line no-case-declarations
      const contentRules = window.xw.tierInfo?.featureTierInfo
        ?.getCcniRules()
        ?.getContentRulesList();
      // eslint-disable-next-line no-case-declarations
      const numberofDays =
        (contentRules && contentRules.length && contentRules[0].getDays()) || 0;
      if (numberofDays && enforceCCNIContentRules()) {
        // @ts-ignore
        return getLookbackMapper(numberofDays);
      }
      return undefined;
    case SubscriptionFeature.FEATURE_CCTI:
    case SubscriptionFeature.FEATURE_CCTA:
      return undefined;
    default:
      unreachable(tier);
      return undefined;
  }
};

export const getMaxUsage = (
  tier: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): number | undefined => {
  switch (tier) {
    case SubscriptionFeature.FEATURE_CCNI:
      return (
        window.xw.tierInfo?.featureTierInfo?.getCcniRules()?.getMaxPrefixes() ||
        0
      );
    case SubscriptionFeature.FEATURE_CCTI:
      return (
        window.xw.tierInfo?.featureTierInfo?.getCctiRules()?.getMaxDevices() ||
        0
      );
    case SubscriptionFeature.FEATURE_CCTA:
      return (
        window.xw.tierInfo?.featureTierInfo?.getCctaRules()?.getMaxDevices() ||
        0
      );
    case SubscriptionFeature.INVALID_FEATURE:
      return undefined;
    default:
      unreachable(tier);
      return undefined;
  }
};

export const getFeatureIcon = (
  feature: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): string | undefined => {
  switch (feature) {
    case SubscriptionFeature.FEATURE_CCNI:
      return "menu-external-routing";
    case SubscriptionFeature.FEATURE_CCTI:
      return "menu-trust-insights";
    case SubscriptionFeature.FEATURE_CCTA:
      return "menu-traffic-analysis";
    case SubscriptionFeature.INVALID_FEATURE:
      return undefined;
    default:
      unreachable(feature);
      return undefined;
  }
};

export const getFeatureTextWithLogo = (
  feature: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): React.ReactNode => {
  switch (feature) {
    case SubscriptionFeature.FEATURE_CCNI:
      return (
        <div className="xw-feature-logo-text">
          <Icon name={`${getFeatureIcon(feature)}`} />
          <div className="part1">{label.network}</div>
          <div className="part2">{label.insights}</div>
        </div>
      );
    case SubscriptionFeature.FEATURE_CCTI:
      return (
        <div className="xw-feature-logo-text">
          <Icon name={`${getFeatureIcon(feature)}`} />
          <div className="part1">{label.trust}</div>
          <div className="part2">{label.insights}</div>
        </div>
      );
    case SubscriptionFeature.FEATURE_CCTA:
      return (
        <div className="xw-feature-logo-text">
          <Icon name={`${getFeatureIcon(feature)}`} />
          <div className="part1">{label.traffic}</div>
          <div className="part2">{label.analysis}</div>
        </div>
      );
    case SubscriptionFeature.INVALID_FEATURE:
      return undefined;
    default:
      unreachable(feature);
      return undefined;
  }
};

export const getProductLabel = (
  feature: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): string => {
  switch (feature) {
    case SubscriptionFeature.FEATURE_CCNI:
      return label.networkInsights;
    case SubscriptionFeature.FEATURE_CCTI:
      return label.trustInsights;
    case SubscriptionFeature.FEATURE_CCTA:
      return label.trafficAnalysis;
    case SubscriptionFeature.INVALID_FEATURE:
      return label.null;
    default:
      unreachable(feature);
      return label.null;
  }
};

export const getFeatureLabel = (
  tier: SubscriptionTierMap[keyof SubscriptionTierMap]
): string => {
  switch (tier) {
    case SubscriptionTier.FREE:
      return label.free;
    case SubscriptionTier.ESSENTIALS:
      return label.essentials;
    case SubscriptionTier.ADVANTAGE:
      return label.advantage;
    case SubscriptionTier.PREMIER:
      return label.premier;
    case SubscriptionTier.ADVANCED:
      return label.advanced;
    case SubscriptionTier.EMBEDDED:
      return label.embeddedVoucher;
    case SubscriptionTier.INVALID_TIER:
      return label.null;
    case SubscriptionTier.BASIC:
      return label.null;
    case SubscriptionTier.STANDARD:
      return label.null;
    default:
      unreachable(tier);
      return label.null;
  }
};

export const generateAvailability = (
  planValue: string | Availability
): React.ReactNode => {
  let availability: React.ReactNode = planValue;
  if (planValue === "available") {
    availability = <SVGIcon name={<CAvailable />} classNames="available" />;
  } else if (planValue === "notavailable") {
    availability = <SVGIcon name={<CEmpty />} classNames="na" />;
  } else if (planValue === "partial") {
    availability = (
      <Icon
        name={`color-icon-circle-minus-${themeName()}`}
        classNames="partial"
      />
    );
  } else if (planValue === "additional") {
    availability = (
      <Icon
        name={`color-icon-circle-additional-${themeName()}`}
        classNames="additional"
      />
    );
  }
  return availability;
};

export const generateGroupHeader = (
  planItems: PlanItem[]
): TierPlanAvailable[] => {
  const tierMap = new Map<
    SubscriptionTierMap[keyof SubscriptionTierMap],
    TierPlanAvailable
  >();
  planItems.forEach((item) => {
    const tierPlans = item?.tierPlanAvailablity.sort((a, b) => a.tier - b.tier);
    tierPlans?.forEach((plan) => {
      const tierFromMap = tierMap.get(plan.tier);
      if (!tierFromMap) {
        tierMap.set(plan.tier, plan);
      } else {
        const availability: Availability | string =
          tierFromMap.planValue !== plan.planValue ? "partial" : plan.planValue;
        tierMap.set(plan.tier, {
          ...tierFromMap,
          planValue: availability,
          availability: generateAvailability(availability)
        });
      }
    });
  });
  return [...tierMap.values()];
};

export const renderTierAvailability = (
  tierItems: TierPlanAvailable[]
): React.ReactNode => {
  return (
    <div className="tier-item">
      {tierItems
        .sort((a, b) => a.tier - b.tier)
        .map((item) => {
          switch (item.tier) {
            case SubscriptionTier.FREE:
            case SubscriptionTier.ESSENTIALS:
            case SubscriptionTier.ADVANTAGE:
            case SubscriptionTier.PREMIER:
            case SubscriptionTier.EMBEDDED:
              return (
                <div key={uuid()} className="plan-value">
                  {item.availability}
                </div>
              );
            case SubscriptionTier.INVALID_TIER:
            case SubscriptionTier.BASIC:
            case SubscriptionTier.STANDARD:
            case SubscriptionTier.ADVANCED:
              return "";
            default:
              unreachable(item.tier);
              return "";
          }
        })}
    </div>
  );
};

export const planTierIcon = (fileName: string) => {
  switch (fileName) {
    case "ccni-advantage":
      return CCNIAdvantageTier;
    case "ccni-essentials":
      return CCNIEssentialsTier;
    case "ccni-free":
      return CCNIFreeTier;
    case "ccni-premier":
      return CCNIPremierTier;
    case "ccta-essentials":
      return CCTAEssentialsTier;
    case "ccta-essentials+function":
      return CCTAEssentialsFunctionTier;
    case "ccti-essentials":
      return CCTIEssentialsTier;
    case "ccti-embeddedvoucher":
      return CCTIEmbeddedVoucherTier;
    default:
      return CCNIFreeTier;
  }
};

export const renderTierDefinition = (
  tierRef: React.Ref<HTMLDivElement>,
  featureType: SubscriptionFeatureMap[keyof SubscriptionFeatureMap],
  tierDefinitions?: TierDefinition[],
  currentTier?: SubscriptionTierMap[keyof SubscriptionTierMap]
): React.ReactNode => {
  if (!tierDefinitions || !tierDefinitions.length) {
    return undefined;
  }
  const tiers = tierDefinitions[0]
    .getTiersList()
    .sort((a, b) => a - b)
    .map((tier) => {
      const feature = reverseMapping(SubscriptionFeature)
        [featureType].replace("FEATURE_", "")
        .toLowerCase();
      const tierLabel = getFeatureLabel(tier);
      const fileName = `${feature}-${tierLabel}`
        .toLowerCase()
        .replace(/\s/g, "");
      if (currentTier === tier) {
        return (
          <div key={tierLabel} ref={tierRef} className="plan-value">
            <img
              src={planTierIcon(fileName)}
              className={`selected`}
              alt={fileName}
            />
            <div className="tier-label">
              {tierLabel}
              {isCurrentTierTrial(featureType) ? `(${label.trial})` : ""}
            </div>
          </div>
        );
      } else {
        return (
          <div key={tierLabel} className="plan-value">
            <img
              src={planTierIcon(fileName)}
              className={fileName}
              alt={fileName}
            />
            <div className="tier-label">{tierLabel}</div>
          </div>
        );
      }
    });
  return <div className="tier-item">{tiers}</div>;
};

export const renderCurrentTier = (
  ref: React.Ref<HTMLDivElement>,
  featureType: SubscriptionFeatureMap[keyof SubscriptionFeatureMap],
  tierDefinition?: TierDefinition[],
  currentTier?: SubscriptionTierMap[keyof SubscriptionTierMap]
): React.ReactNode => {
  const currentPlan = currentTier ? getFeatureLabel(currentTier) : label.null;
  return (
    <div className="current-tier">
      <PageSection
        title={label.currentServiceTier}
        classNames="header"
        showSeparator={true}
      >
        {currentPlan}{" "}
        {isCurrentTierTrial(featureType) ? ` - ${label.trial}` : ""}
      </PageSection>
      {renderTierDefinition(ref, featureType, tierDefinition, currentTier)}
    </div>
  );
};

export const renderPlans = (planItem: PlanItem): React.ReactNode => {
  return (
    <div className="plan-item" key={planItem.header?.toString()}>
      <PageSection title={planItem.header} showSeparator={true}>
        {planItem.description}
      </PageSection>
      <div className="tier-list">
        {renderTierAvailability(planItem.tierPlanAvailablity)}
      </div>
    </div>
  );
};

const getCategoriesLabel = (
  featureType: SubscriptionFeatureMap[keyof SubscriptionFeatureMap],
  category: PlanCategories
): React.ReactNode => {
  switch (featureType) {
    case SubscriptionFeature.FEATURE_CCNI:
      return getCCNICategoriesLabel(category);
    case SubscriptionFeature.FEATURE_CCTI:
      return getTrustCategoriesLabel(category);
    case SubscriptionFeature.FEATURE_CCTA:
      return getFlowCategoriesLabel(category);
    case SubscriptionFeature.INVALID_FEATURE:
      return label.null;
    default:
      unreachable(featureType);
      return label.null;
  }
};

export const renderSections = (
  featureType: SubscriptionFeatureMap[keyof SubscriptionFeatureMap],
  plans: Plans[],
  planCategory: PlanCategories[]
): React.ReactNode => {
  return planCategory
    .filter(
      (category) =>
        !(
          window.xw.environmentConfig?.trust_alarms_enabled !== "true" &&
          category === "alarms"
        )
    )
    .map((category) => {
      const categoriesSection = plans?.filter(
        (plan) => plan.category === category
      );
      return (
        <div key={category} className="xw-plan-sections">
          <Expander
            header={getCategoriesLabel(featureType, category)}
            name={category}
            subHeader={
              categoriesSection && categoriesSection.length
                ? renderTierAvailability(categoriesSection[0].groupHeader)
                : ""
            }
          >
            {categoriesSection && categoriesSection.length
              ? categoriesSection[0].items.map((planItem): React.ReactNode => {
                  return renderPlans(planItem);
                })
              : ""}
          </Expander>
        </div>
      );
    });
};

export const limitRuleLabelDescription = (
  limitRuleMap: LimitRuleTypeMap[keyof LimitRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (limitRuleMap) {
    case LimitRuleType.LIMIT_RULE_PREFIXES:
      return {
        label: label.numberOfPrefixes,
        description: label.costBasedOnPrefixes
      };
    case LimitRuleType.LIMIT_RULE_DEVICES:
      return {
        label: label.numberOfDevices,
        description: label.costBasedOnDevices
      };
    case LimitRuleType.INVALID_LIMIT_RULE_TYPE:
      return undefined;
    default:
      return undefined;
  }
};

export const ssoRulesLabelDescription = (
  ssoMap: SignonRuleTypeMap[keyof SignonRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (ssoMap) {
    case SignonRuleType.SIGNON_SUPPORT:
      return {
        label: label.signOnSupport,
        description: label.signOnSupportDescription
      };
    case SignonRuleType.SIGNON_CISCO_ACCOUNTS:
      return {
        label: label.ciscoUserAccounts,
        description: label.ciscoUserAccountsDescription
      };
    case SignonRuleType.SIGNON_UNLIMITED_USERS:
      return {
        label: label.unlimitedUsers,
        description: label.unlimitedUsersDescription
      };
    case SignonRuleType.INVALID_SIGNON_RULE_TYPE:
      return undefined;
    default:
      unreachable(ssoMap);
      return undefined;
  }
};

// Utility method to check for regex path match and if enum value matches path
export function enumPathMatch<T>(t: Map<T, string>, path?: unknown): unknown {
  return [...t.entries()]
    .filter((val) => val[1] === path)
    .map((key) => key[0])[0] as unknown;
}

export const getProductDescription = (
  feature: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): string | undefined => {
  switch (feature) {
    case SubscriptionFeature.FEATURE_CCNI:
      return label.externalRoutingDecription;
    case SubscriptionFeature.FEATURE_CCTI:
      return label.trustDecription;
    case SubscriptionFeature.FEATURE_CCTA:
      return label.trafficDecription;
    case SubscriptionFeature.INVALID_FEATURE:
      return undefined;
    default:
      unreachable(feature);
      return undefined;
  }
};

export const getProductUsageText = (
  feature: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): string => {
  switch (feature) {
    case SubscriptionFeature.FEATURE_CCNI:
      return label.prefixes;
    case SubscriptionFeature.FEATURE_CCTI:
    case SubscriptionFeature.FEATURE_CCTA:
      return label.devices;
    case SubscriptionFeature.INVALID_FEATURE:
      return label.null;
    default:
      unreachable(feature);
      return label.null;
  }
};

export const getBilledPlatformText = (
  platform: SubscriptionPlatformMap[keyof SubscriptionPlatformMap]
): string => {
  switch (platform) {
    case SubscriptionPlatform.PLATFORM_AWS:
      return label.aws;
    case SubscriptionPlatform.PLATFORM_CROSSWORK:
    case SubscriptionPlatform.PLATFORM_SBP:
      return label.cisco;
    case SubscriptionPlatform.INVALID_PLATFORM:
    case SubscriptionPlatform.PLATFORM_STORE_FRONT:
      return label.null;
    default:
      unreachable(platform);
      return label.null;
  }
};

export const getAvailableApplications = (): SelectOption[] => {
  const tierInfo = window.xw.tierInfo?.features;
  const applications: SelectOption[] = [];
  if (window.xw.tierInfo?.featureTierInfo?.getCcniRules()) {
    applications.push({
      label: getProductLabel(SubscriptionFeature.FEATURE_CCNI),
      value: SubscriptionFeature.FEATURE_CCNI.toString()
    });
  }
  if (tierInfo?.getTrustInsights()) {
    applications.push({
      label: getProductLabel(SubscriptionFeature.FEATURE_CCTI),
      value: SubscriptionFeature.FEATURE_CCTI.toString()
    });
  }
  if (tierInfo?.getTrafficAnalysis()) {
    applications.push({
      label: getProductLabel(SubscriptionFeature.FEATURE_CCTA),
      value: SubscriptionFeature.FEATURE_CCTA.toString()
    });
  }
  return applications;
};
