export default {
  "en-US": {
    aborted: "Aborted",
    accept: "Accept",
    acceptAgreement: "Please accept agreement to continue",
    acceptPendingEnrollment: "Accept Pending Enrollment",
    acceptPendingEnrollmentStepMsg:
      "After a gateway is created with a valid enrollment token, it will appear here in pending state. To continue, click Allow under the Action column for the gateway you wish to add.",
    acceptSecurityCertificate: "Accept Security Certificate",
    acceptSSHHostKey: "Accept SSH Host Key",
    acceptTerms: "terms and conditions",
    accessKeyId: "Access Key ID",
    accessKeySecret: "Access Key Secret",
    accountSettings: "Account Settings",
    ackFailure: "Failed to acknowledge Alarm(s)",
    acknowledged: "Acknowledged",
    ackSuccess: "Alarm(s) Acknowledged",
    action: "Action",
    advancedSettings: "Advanced Settings",
    allow: "ALLOW",
    revoke: "REVOKE",
    actions: {
      accept: "Accept",
      acknowledge: "Acknowledge",
      actions: "Actions",
      activate: "Activate",
      activated: "Activated",
      add: "Add",
      addDevice: "Add Device",
      addFilterResults: "Add Filter Results",
      addPeer: "Add Peer",
      apply: "Apply",
      applyFilters: "Apply Filters",
      applyToSelection: "Apply to Selection",
      back: "Back",
      browse: "Browse",
      cancel: "Cancel",
      clearFilter: "Clear Filter",
      clickHere: "click here",
      clickNameToToggle: "Click name to toggle",
      clone: "Duplicate",
      close: "Close",
      configure: "Configure",
      confirm: "Confirm",
      confirmTitle: "Are You Sure?",
      confirmUnpausePrefixesContent: "Are you sure?",
      continue: "Continue",
      continueEditing: "Continue Editing",
      copy: "Copy",
      create: "Create",
      deactivate: "Deactivate",
      deactivated: "Deactivated",
      delete: "Delete",
      deleteConfirmDescFmt: "{0} will be permanently removed. ",
      deleteConfirmTitle: "Are You Sure?",
      disable: "Disable",
      disablePeerMsg: "will be disabled.",
      discardChanges: "Discard Changes",
      edit: "Edit",
      editPeer: "Edit Peer",
      editPolicy: "Edit Policy",
      enable: "Enable",
      enroll: "Enroll",
      expand: "Expand",
      export: "Export",
      exportConfiguration: "Export Configurations",
      fullscreen: "View in Fullscreen",
      fullscreenExit: "Exit Fullscreen",
      getHelp: "Get Help",
      hide: "Hide",
      import: "Import",
      link: "Link",
      linkSelected: "Link Selected",
      loadMore: "Load more",
      merge: "Merge",
      monitor: "Monitor",
      month: "Month",
      next: "Next",
      ok: "OK",
      pause: "Pause",
      peermonImport: "Peermon Import",
      preview: "Preview",
      previous: "Previous",
      remove: "Remove",
      replace: "Replace",
      reset: "Reset",
      restartImport: "Restart Import",
      revoke: "Revoke",
      save: "Save",
      show: "Show",
      snooze: "Snooze",
      snoozeOnce: "Snooze Once",
      snoozeUntil: "Snooze Until",
      submit: "Submit",
      subscribe: "Subscribe",
      test: "Test",
      toggleAll: "Toggle All",
      tryAgain: "Try Again",
      unacknowledge: "Unacknowledge",
      unchanged: "Unchanged",
      unenroll: "Unenroll",
      unlink: "Unlink",
      unmonitor: "Unmonitor",
      unpause: "Resume",
      unsnooze: "Unsnooze",
      unsubscribe: "Unsubscribe",
      update: "Update",
      updatePolicy: "Update Policy",
      viewPeers: "View Peers"
    },
    actionsPolicySubheader: "Actions taken after a rule is triggered",
    activate: "Activate",
    activateAccount:
      "have a Cisco Connection Online (CCO) account prior to signing up for a Crosswork Cloud account.",
    activateAWSSubscriptionHeader:
      "To activate your AWS subscription, please provide the requested information, accept terms and Confirm",
    activated: "Activated",
    activatePeerThreshold: "Activate Peer Threshold",
    activateTrial:
      "To activate your trial subscription, accept terms and submit your request.",
    activateTrialSubscription:
      "have a Cisco Connection Online (CCO) account prior to activating your trial subscribtion.",
    activateYourTrial: "Activate your trial subscription",
    activationMessage1:
      " To activate your 90 day trial subscription, accept terms and submit request.",
    activationMessage2: "Confirmation will be sent to your {email} address.",
    active: "Active",
    activeAlarmCount: "Active Alarm Count",
    activeAlarms: "Active Alarms",
    activeChanged: "Active Changed",
    activeCommitted: "Active - Committed",
    activeNetflowDataGateway:
      "Active Crosswork Data Gateway configured for Netflow",
    activePeerings: "Active Peerings",
    activeROA: "Active ROA",
    activeROARecords: "Active ROA Records",
    activeSessions: "Active Sessions",
    activeTrials: "Active Trials",
    activeUncommitted: "Active - Uncommitted",
    activityLog: "Activity Log",
    add: "Add",
    addAPIKey: "Add API Key",
    addCDGSuccess:
      "Crosswork Data Gateway was added successfully. Next, you need to add and link devices to this gateway.",
    addCredential: "Add Credential",
    addCrossworkDataGateway: "Add Cisco Crosswork Data Gateway",
    addDataGateway: "Add Data Gateway",
    addDataGatewayReplication: "Add Data Gateway Replication",
    addDate: "Add Date",
    addDevice: "Add Device",
    addDeviceGroup: "Add Device Group",
    addDeviceReplication: "Add Device Replication",
    addDevices: "Add Devices",
    addDeviceSuccess: "Device added successfully.",
    added: "Added",
    addedAt: "Added At",
    addedBy: "Added By",
    addedKGF: "Known Good Files added successfully",
    addedOriginAsnPolicy: "Added Origin ASN to policy - {policy}",
    addEndpoint: "Add Endpoint",
    addEndpointToAllRules: "Add Endpoint to All Rules",
    addEndpointToAllRulesDesc:
      "Add the below endpoint to all existing rules on this policy",
    addEntitlements: "Add Entitlements",
    addEntitlementSubHeader: "Enter your subscription IDs.",
    addFiles: "Add Files",
    addFilter: "Add Filter",
    addNewCredential: "Add New Credential...",
    addNewDeviceGroup: "Add new device group...",
    addNewPeerDevices: "Add New Peer Devices",
    addNewPeerDevicesDescription: "Configure and add new device peers.",
    addNotification: "Add Notification",
    addObservedFiles: "Add Observed Files",
    addOn: "Add-On",
    addOriginsToPolicy: "Add Origins to Policy",
    addOrRemove: "+/-",
    addPeersToUseFilter: "Add peers to use this filter",
    addPolicy: "Add Policy",
    addPolicyPrefixRules: "Add Policy Prefix Rules",
    addPolicyPrefixRulesSubheader: "Select rules to activate for this policy",
    addPolicyRulesDescription: "Select rules to activate for this policy",
    addPolicyTypeRules: "Add Policy {type} Rules",
    addQueue: "Add Queue",
    address: "Address",
    addressFamilyBgpStatus: "Address family {af}",
    addRule: "Add Rule",
    addRuleNavigation: "Add Rule Navigation",
    addRules: "Add Rules",
    addSource: "Add Source",
    addSubscription: "Add Subscription",
    addThingToComparisonFmt: "Add {thing} to Comparison",
    addToComparison: "Add to Comparison",
    addToComparisonDesc: "Select rows you would like to add to the comparison",
    addToKnownGoodFiles: "Add Files to Known Good Files List",
    addToken: "Add Token",
    addTrafficDevice: "Add Traffic Device",
    addTrustDevice: "Add Trust Device",
    addUpstreamsToPolicy: "Add Upstreams to Policy",
    addUser: "Add User",
    addUsersTextareaHint: "Use a comma , or space or ; to add multiple users",
    adjust: "Adjust",
    adminLocation: "Admin Location",
    adminState: "Admin State",
    advanced: "Advanced",
    advancedAlaCarte: "Advanced",
    advancedAsPathHint: "Must be a number[][] in JSON format",
    advancedLicenses: "Advanced Licenses",
    advancedTrial: "Advanced Trial",
    advancedTrialSubHeader:
      "Updating your organization to Advanced can only be reversed by contacting Cisco support. To start the trial and upgrade to Advanced features claim the subscription",
    advantage: "Advantage",
    advertised: "Advertised",
    advertisedPrefixCountDescription:
      "Detects when the number of prefixes your peer advertises to the Cisco peer is outside of the range you specified.",
    advertisedPrefixes: "Advertised Prefixes",
    advertisements: "Advertisements",
    AES128: "AES128",
    AES192: "AES192",
    AES192C: "AES192C",
    AES256: "AES256",
    AES256C: "AES256C",
    afterUpgrade: "After Upgrade",
    ago: " ago",
    alarm: "Alarm",
    alarmBGPData: "Alarm Relevant BGP Update ",
    alarmBGPUpdateHistory: "Alarm BGP Update History",
    alarmBGPUpdateHistoryDescription: "",
    alarmCondition: "Alarm Condition",
    alarmConfigs: "Alarm Configs",
    alarmDetailDescription:
      "View Alarm details such as severity, activity timestamps, relevant BGP updates, associated prefix, history, and so on.",
    alarmDetails: "Alarm Details",
    alarmDetectionAt: "Alarm Detection At",
    alarmGroup: "Policy",
    alarmHistory: "Alarm History",
    alarmHistoryDescription:
      "View and export historic alarms within a specified timeframe.",
    alarmNotesFmt: "{type} Alarm Notes",
    alarms: "Alarms",
    alarmsDonut: "Active Alarms By Rule",
    alarmType: "Alarm Type",
    alerts: "Alerts",
    all: "All",
    allAlarms: "All Alarms",
    allDevices: "All Devices",
    allEssentialFeatures:
      "which includes all Essential features plus additional benefits such as:",
    allowOrRevokeGateway: "Allow or revoke a gateway",
    allPeerReport: "All Peer Stats",
    allPeerReportDescription:
      "BGP session state per organization and the remote peer IP",
    allPeers: "All Peers",
    allRules: "All Rules",
    analysis: "Analysis",
    annual: "Annual",
    anySub: "Any Subscription",
    anyTrigger: "Any Trigger",
    api: "API",
    apiCertificationDesc: "Authorization key for Crosswork Cloud API",
    apiDocumentation: "API Documentation",
    apiKeys: "API Keys",
    apiKeyTokens: "API Key/Tokens",
    apis: "APIs",
    apiUsage: "API Usage",
    apiUsageDescription: "Ability to use the Crosswork Cloud APIs.",
    appDownloaded: "App Downloaded",
    application: "Application",
    applications: "Applications",
    appSelection: "App Selection",
    architecture: "Architecture",
    as: "AS",
    asCentric: "AS Centric",
    ascending: "Ascending",
    asNewPrefixAlarmDetailsOverviewDescription1: "There are currently ",
    asNewPrefixAlarmDetailsOverviewDescription2:
      " Prefixes being announced seen by your peers",
    asn: "ASN",
    asnRxTraffic: "ASN Rx Traffic",
    asnTxTraffic: "ASN Tx Traffic",
    asnBGPData: "ASN BGP Updates ",
    asnBGPUpdateHistory: "ASN BGP Update History",
    asnBGPUpdateHistoryDescription:
      "View relevant ASN BGP update details such as peers, peer AS, prefixes, associated AS routing path, communities path, BGP update type, and so on in a specified timeframe.",
    asnDetails: "ASN Details",
    asnDetailsDescription:
      "View ASN details such as relevant BGP updates, associated alarms, ROAs, RPSL, history, and so on.",
    asnEditSuccess: "ASN updated successfully",
    asnInputHint:
      "Enter the ASN for which you want to search for associated prefixes.",
    asnLookingGlass: "ASN Looking Glass",
    asnLookingGlassDescription:
      "View ASN peers, reporting peers, validity status, prefix subscription, and RPSL validity.",
    asnLookup: "ASN Lookup",
    asnNotMonitored:
      "In order to create this report you need to monitor the ASN. Would you like to monitor the ASN and schedule the report?",
    asnPolicy: "ASN Policy",
    asnPolicyDeleted: "ASN Policy Removed",
    asnPolicyIdentifier: "Provide a name that identifies this policy",
    asnReviewMessage:
      "Modifying fields below will change those values for all ASNs that you select. Once you submit the action cannot be easily undone.",
    asnRIR: " - ({rir})",
    asnRouting: "ASN Routing",
    asnRoutingReportHeader: "{asn} {frequency} Report",
    asns: "ASNs",
    asnsInputHint: "Enter a comma (,) as you type an ASN to commit it",
    asnsToSubscribe: "ASNs to Subscribe ",
    asnsWithCount: "ASNs ({count})",
    asnTag: "ASN Tag",
    asnTrafficAnalysis: "ASN Traffic Analysis",
    asnTrafficAnalysisDescription: "",
    asnTrafficGroupAnalysis: "ASN Traffic Group Analysis",
    asnTrafficGroupAnalysisDescription: "",
    asnUnlinkSuccess: "ASN(s) unlinked from Policy",
    asOriginViolationDescription:
      "Detects when an origin ASN does not match one listed in the user specified Origin ASNs policy list.",
    asOriginViolationMyPeersDescription:
      "Detects when my peers report an origin ASN that does not match one listed in the user specified Origin ASNs policy list.",
    asPath: "AS Path",
    asPathFilterErrorMsg:
      "Use numeric value for ASNs, separated by space ( ) for a path",
    asPathFilterHint: "Use a space ( ) to separate each ASN in AS Path.",
    asPathLengthDescription:
      "Detects when an AS path length is not within the user specified path length range.",
    asPathLengthDescriptionMyPeers:
      "Detects when an AS path length is not within the user specified path length range for my peers.",
    asPathPattern: "Valid AS Path Pattern",
    asPathPatternHint:
      "Only used for the Valid AS Path Violation rule. Example: 3462 [2](345 | 109) 107",
    asPathPatternTester: "AS Path Pattern Configuration",
    asPathPatternTesterDesc:
      "Enter your test pattern, then enter the AS path you wish to test it against",
    asPatternAlowed: "Allowed characters: [ ] * - + | ( ) 0-9",
    asPatternRequired:
      "An AS Path Pattern is required because a 'Valid AS Path Violation' rule is included on this policy",
    asPeeringChanges: "AS Peering Changes",
    asPeerings: "AS Peerings",
    asPeeringTypes: "AS Peering Types",
    asPeers: "AS Peers",
    asPrefixByRoa: "AS Prefixes By ROA Status",
    at: "At",
    atLeastIPv46UnicastMinMax:
      "One of entry in IPv4/IPv6 Unicast Min/Max required",
    atLeastLength: "Must be at least {min}",
    atLeastOneDevice: "Device added",
    atLeastOneFilter: "At least one filter is required",
    authenticationProtocol: "Authentication Protocol",
    authenticationPwd: "Authentication Password",
    authNoPriv: "authNoPriv",
    authPriv: "authPriv",
    authPwdMismatch: "Authentication password mismatch",
    auto: "Auto",
    autoClaimSubscription: "Your subscription has been claimed",
    available: "Available",
    availableInterfaces: "Available Interfaces :",
    availableTrials: "Available Trials",
    average: "Average",
    avg: "Avg",
    avgFPS: "Avg FPS",
    awaitingConfm: "Waiting on confirmation",
    aws: "AWS",
    awsHeaderDescription:
      "Provide the following information to activate your entitlement.",
    awsLoading:
      "Please wait while we fetch the information from AWS Marketplace.",
    awsMarketplace: "AWS Marketplace",
    awsPurchase: "AWS Purchase",
    awsPurchaseClaimBanner:
      "If you have purchased a subscription from AWS, please wait for your claim subscription email. This may take some time. ",
    awsPurchaserId: "AWS Purchaser ID",
    awsRetryUrl: "Please retry setting up AWS ",
    balanced: "Balanced",
    balancingRecommendationsDesc:
      "Utilization projections are estimates based on original traffic flow",
    baseline: "Baseline",
    baselineDevice: "Baseline Device",
    baselineSelect: "Baseline Select",
    baselineTimestamp: "Baseline Timestamp",
    basicAlarms: "Basic Alarms",
    bearerScopeAdmin: "Admin",
    bearerScopeReadOnly: "Read Only",
    bearerScopeReadWrite: "Read Write",
    bearerToken: "Bearer Tokens",
    bearerTokenDesc: "Used for authorizing the use of the Crosswork Cloud API",
    bgp: "BGP",
    bgpAndSnmp: "BGP & SNMP",
    bgpCredential: "bgpCredential",
    bgpDescription: "",
    bgpLastSeen: "BGP Last Seen",
    bgpmonAccountEmail: "BGPMon Account Email",
    bgpMonButton: "Legacy BGPMon",
    bgpMonCreateCCNAccountHint:
      "To continue to receive alerts and information about the prefixes you find interesting, please create a Crosswork Cloud Network Insights account.",
    bgpMonDescription:
      "BGPMon is no longer available and has been replaced with Network Insights. Please create a new account under Crosswork Cloud.",
    bgpMonEOLHint:
      "BGPMon has been replaced with Crosswork Cloud Network Insights.",
    bgpMonReplacementHint:
      "Thank you for your support of BGPMon. We have been hard at work redesigning the features you rely on in BGPMon and added a few more.",
    bgpPassword: "BGP Password",
    bgpRouterIpAddress: "BGP Router ID IP Address",
    bgpSendReceive: "BGP Send Receive",
    bgpSession: "BGP Session",
    bgpStatus: "BGP Status",
    bgpUpdateHistory: "BGP Update History",
    bgpUpdateHistoryDescription:
      "View relevant BGP update details such as peers, peer AS, prefixes, associated AS routing path, communities path, BGP update type, and the last BGP update in a specified timeframe.",
    bgpUpdates: "BGP Updates",
    bgpUpdatesAsPathFilterDisabledTooltip:
      "This filter will not be applied until the time range is reduced to seven days or less",
    billedBy: "Billed By",
    bits: "bits",
    bogons: "Bogons",
    bogonsSubtext: "RFC1918, RFC5735, RFC6598",
    bogonStatus: "Bogon Status",
    boolean: "boolean",
    boot: "Boot",
    bootIntegrityVerificationDetails:
      "Hardware support for secure measurement and reporting of systems integrity at boot-time. Requires specific support from advanced versions of Trust Anchor Modules (TAm) , as well as BIOS support for secure measurement.",
    both: "Both",
    breakdown: "Breakdown",
    browserError:
      "You must use a supported version of Google Chrome or Mozilla Firefox. See the",
    browserError1: " for the list of supported browsers.",
    bucket: "Bucket Name",
    build: "Build",
    buildVersion: "Build Version",
    builtOn: "Built On",
    builtTime: "Built Time",
    bulkEdit: "Bulk Edit",
    bulkEditPeerWarning:
      "Modifying fields below will change those values for all peers that you have selected. This action cannot be undone. If there are alarms on existing policies and you overwrite that policy those alarms will be deleted from the system and are not recoverable.",
    bulkEditSuccess: "Prefix(es) updated successfully.",
    bulkEditUsersStep1Caption:
      "All entered values will overwrite existing values on the selected users.",
    bulkEditUsersStep2Caption:
      "These users will have the following values applied.  All values in these colums will be overwritten as shown.",
    bulkEditUsersTitle: "Editing {count} users",
    bulkEditWarning:
      "Modifying fields below will change those values for all prefixes that you have selected. This action cannot be undone. If there are alarms on existing policies and you overwrite that policy those alarms will be deleted from the system and are not recoverable.",
    bulkPeerEditSuccess: "Peer(s) updated successfully.",
    businessEmail: "Business Email",
    bytes: "bytes",
    callNumber: "1-800-553-6387",
    cannotConnect: "Cannot Connect",
    cannotFindPrefixMatch: "Cannot find matching prefix",
    cantBeFmt: 'Can\'t be "{value}"',
    cantUnlinkObjNotFoundFmt:
      "Unable to unlink {obj}. Try refreshing the page and trying again.",
    capabilities: "Capabilities",
    capabilitiesLegendActive: "= Unavailable",
    capabilitiesLegendActiveInstalled: "= Installed",
    capabilitiesLegendAfterUpgrade: "= Active With Upgrade",
    capabilitiesLegendBeforeUpgrade: "= Available with Upgrade",
    capacity: "Capacity",
    capacityAlarmInfo: "The capacity for this interface is set at",
    category: "Category",
    caution: "Caution",
    cautionAboutUploadConfig:
      "Importing a configuration into the system overwrites existing data of the same type. If your file contains empty prefixes, {reports} groups or endpoints, all existing data of that same type will be deleted. Use caution because you cannot undo this operation.",
    cccTeam: "Cisco Crosswork Cloud Team",
    ccniPromoEssentialsDesc:
      "['BGP: Realtime updates, Looking Glass, and Alarms', 'Peer and DNS monitoring', '1 day history', 'Notifications: Email and SMS']",
    ccniPromoPremierDesc:
      "['BGP: My Peers alarms (local)', 'RPKI and Path Topology', '90-day history', 'Notifications:  Webex, Slack, PagerDuty, Teams, GCP, and AWS S3']",
    ccniPromoLandingDescText:
      "A powerful addition to any network operations SaaS-based services to help improve BGP routing health, increase network traffic visibility, and maintain trustworthy infrastructure.",
    cctaDataRetentionLookbackDescription: "",
    cctaSubscriptionTermDescription: "",
    cctiSubscriptionTermDescription:
      "Essentials Voucher requires activation of paid Essentials Licenses.",
    cdg: "Crosswork Data Gateway",
    cdgAsnHint: "Defaults to 65000 if not provided",
    cdgCertMismatch: "Certificate Mismatch",
    cdgDeleteSuccess: "Data Gateway removed successfully.",
    cdgDisableSuccess: "Data Gateway disabled successfully.",
    cdgEditsuccess: "Crosswork Data Gateway updated successfully.",
    cdgEnableSuccess: "Data Gateway enabled successfully.",
    cdgFileUploadHint: "Select a JSON or TXT (Base64 encoded) file to import.",
    cdgFileUploadMsg:
      "Please upload the virtual machine Crosswork Data Gateway configuration bundle to add a data gateway to the system.",
    cdgGroupTagsMsg:
      "Group tags provide means to group and identify a data gateway with similar traits.",
    cdgName: "Data Gateway",
    cdgs: "Data Gateways",
    cdgDeleteWarning: {
      singular: "gateway",
      plural: "gateways",
      willAffect:
        "will affect all associated devices and prevent further dossier or traffic collections.",
      pleaseReviewTheImpact:
        "Please review the impact on the system before removing."
    },
    cdgSecurityCertificateMsg:
      "The CA certificate for this device has changed and needs to be accepted to permit the device on the network",
    cdgTypeDisabledHasMapping:
      "To change the Crosswork Data Gateway application, you must first unlink all devices.",
    cdgTypeDisabledNoSubscription:
      "Your subscription restricts the Crosswork Data Gateway to one application only.",
    cdgTypeSelectInfo:
      "Select the application for which this Crosswork Data Gateway is used.",
    certificateApprovalPending: "Certificate approval pending",
    certificateExpired: "Enrollment certificate has expired",
    certificateInvalid: "Enrollment certificate is not a valid certificate",
    change: "Change",
    changeAuthenticationPwd: "Change Authentication Password",
    changeCount: "Change Count",
    changed: "Changed",
    changeOrgName: "Change Name",
    changeOrgNameTitle: "Edit Organization Name",
    changePassword: "Change Password",
    changePrivacyPwd: "Change Privacy Password",
    changes: "Changes",
    changesAppliesToAllAsns:
      "Changes to this field are applied to all selected ASNs.",
    changesAppliesToAllPrefixes:
      "Changes to this field are applied to all selected prefixes.",
    changesDetected: "Changes detected",
    changesFromDate: "Changes from {date}",
    chipHardwareRevision: "Chip Hardware Revision",
    chooseCollection: "Choose a Collection",
    cirInfo1: "Set the maximum capacity for this interface, currently",
    cirInfo2:
      "if the actual committed rate is lower than the physical maximum.",
    cirInterfaceCapTip:
      "This interface rate has been set to reflect committed rate rather than the physical limit.",
    cirLabel: "Interface Capacity Override",
    cirValHint: "The minimum rate must be higher than 10Mbps",
    chooseYourMethodOfCreationInfo:
      "Crosswork Data Gateway(s) collect information from managed devices and send it to Cisco Crosswork Cloud. You must first install a Crosswork Data Gateway before you can use Traffic Analysis or Trust Insights. Each Crosswork Data Gateway is deployed on an individual Virtual Machine (VM).",
    chooseYourMethodOfCreationGenerateToken: "Generate token",
    chooseYourMethodOfCreationCreate: "Create CDG with Token",
    chooseYourMethodOfCreationLaunch: "Launch CDG",
    chooseYourMethodOfCreationAuthorize: "Authorize CDG Access",
    chooseYourMethodOfCreationUse: "Use Enrollment Token",
    chooseYourMethodOfCreationEnter: "Enter Details",
    chooseYourMethodOfCreationImage: "Create CDG Image",
    chooseYourMethodOfCreationUpload: "Upload Registration File",
    chooseYourMethodOfCreation6Title: "Enroll Crosswork Data Gateway 6.0+",
    chooseYourMethodOfCreation5Title:
      "Enroll Earlier Crosswork Data Gateway Releases",
    chooseYourMethodOfCreationDownloadImage: "Download Crosswork Data Gateway",
    chooseYourMethodOfCreationDownloadText: "Download CDG Image",
    chooseYourMethodOfCreationDownload:
      "If need to create a Crosswork Data Gateway, click Launch Download Site.",
    chooseYourMethodOfCreationVM:
      "If you have an existing Crosswork Data Gateway VM that you want to add, click Upload Registration File.",
    chooseYourMethodOfCreationAuto:
      "An enrollment token can be created and embedded during VM installation. This method automatically enrolls a Crosswork Data Gateway in Crosswork Cloud.",
    cisco: "Cisco",
    ciscoAsn: "Cisco ASN",
    ciscoChipValidationDetails:
      "Hardware integrity measurement and control for CPU and data plane ASIC components. Provides advanced protection against tampering with hardware forwarding components on newer Cisco routing platforms. Requires hardware support from advanced Trust Anchor Module(s).",
    ciscoPartner: "Cisco Partner",
    ciscoRouteServer: "Route Server",
    ciscoUserAccounts: "Cisco.com User Accounts",
    ciscoUserAccountsDescription:
      "Allows an all Cisco.com users to access Crosswork Cloud applications with one set of login credentials.",
    city: "City",
    claim: "Claim",
    claimDate: "Claim Date",
    claimedBy: "Claimed By",
    claimSubscription: "Claim Subscription",
    claimSubscriptionSuccess: "Subscription(s) claimed successfully.",
    classification: "Classification",
    clearAllFilters: "Clear All Filters",
    clearChartSelection: "Clear Chart Selection",
    clearSelections: "Clear Selections",
    click: "Click",
    clickForOptions: "Click for Options",
    clickHere: "Click here",
    clickNameToDrilldown: "Click name to drilldown",
    clock: "Clock",
    clockSkew: "Clock Skew Detected",
    closeFilter: "Close Filter",
    closeNotification: "Close Notification",
    cloud: "Cloud",
    cloudConsumer: "Cloud Consumer",
    cniArticle:
      "The Crosswork Network Insights BGP Analytics Engine enriches routing data with additional data sources such as RPKI, WHOIS, and IP Geolocation, reducing the time it takes to identify malicious activity or repair routing errors. Network Insights further leverages these data to provide rich analysis, visualization, and alerting on actionable network events, saving network operators time and increasing service availability.",
    cniOnlineDocumentation: "Crosswork Network Insights Documentation",
    collectConfigurationHash: "Collect Configuration Hash",
    collectConfigurationHashInfo1:
      "In order to monitor changes in configuration, we need to collect a fingerprint of the current configuration in the form of a hash. No information about your configuration will be stored in the cloud. If the configuration changes then the reported hash will change indicating a change.",
    collectConfigurationHashInfo2:
      "Do you allow Cisco to collect and store a hash of your configuration by enrolling this device in hash collection?",
    collectDossier: "Collect Dossier",
    collected: "Collected",
    collectedAt: "Collected At",
    collectedFmt: "Collected {time} ago",
    collectingDossier: "Collecting Dossier",
    collection: "Collection",
    collectionAccessDenied: "Dossier collection access denied",
    collectionFrequency: "Collection Frequency",
    collectionGateway: "Collection Gateway",
    comingSoon: "Coming Soon",
    commandCrash: "Command crash",
    commaSeparatedFmt: "Use a comma (,) to separate each {obj}",
    committedButNotActivated: "Committed But Not Activated",
    commitedCapacity: "Committed Capacity",
    communities: "Communities",
    community: "Community",
    communityForum: "Community Forum",
    companyName: "Company Name",
    compare: "Compare",
    comparedReport: "Compared Report",
    comparisonTimestamp: "Comparison Timestamp",
    component: {
      am: "AM",
      children: "Children",
      code: "Code",
      custom: "Custom",
      displaying: "Displaying",
      dossierDifferences: "Dossier Differences",
      editColumn: "Edit Columns",
      error: "Error",
      exclude: "Exclude",
      excludeTooltip: "Exclude the below 'Filter Value' query ",
      exportCSV: "Export CSV",
      filter: "Filter",
      filterFieldTooltip: "Select the field you wish to filter on",
      filterHeader: "{action} Filter",
      filterValue: "Value",
      filterValueTooltip: "Regex search string",
      hideColumn: " Hide Column",
      high: "High",
      include: "Include",
      includeExcludeTooltip:
        "Include or exclude the below 'Filter Value' query ",
      includeTooltip: "Include the below 'Filter Value' query ",
      info: "Informational",
      inputOptions: "Input Options",
      insufficientPrivileges: "Insufficient Privileges",
      label: "Label",
      last15Minutes: "Last 15 Minutes",
      last30d: "Last 30 Days",
      last3d: "Last 3 Days",
      last3Hour: "Last 3 Hours",
      last60d: "Last 60 Days",
      last90d: "Last 90 Days",
      lastDay: "Last Day",
      lastHour: "Last Hour",
      lastWeek: "Last Week",
      lastYear: "Last Year",
      loading: "Loading...",
      loadingSubText:
        "We are currently processing your request, thank you for your patience",
      low: "Low",
      med: "Med",
      newItem: "New Item",
      ninetyDays: "90 Days",
      noData: "No Data",
      noDataChartSubText: "No data available at this time",
      noDataSubText: "Try adjusting filters or timeframe",
      off: "Off",
      on: "On",
      oneDay: "1 Day",
      oneHour: "1 Hour",
      pm: "PM",
      resetTable: "Reset Table",
      sevenDays: "7 Days",
      severity: "Severity",
      sixHours: "6 Hours",
      sortAscending: "Sort Ascending",
      sortDescending: "Sort Descending",
      success: "Success",
      tableConfig: "Table Config",
      tableHeaderWedgeConfig: "Column Wedge Settings",
      tenMinutes: "10 Minutes",
      thirtyDays: "30 Days",
      thirtyMinutes: "30 Minutes",
      threeDays: "3 Days",
      threeHours: "3 Hours",
      threeHundredSixtyFive: "365 Days",
      timeRange: "Time Range",
      twelveHours: "12 Hours",
      twentyFourHours: "24 Hours",
      visual: "Visual",
      warning: "Warning"
    },
    confHash: "Conf Hash",
    configCloud: "Config",
    configHashDisabledMessage:
      "This device is no longer set up to collect a configuration hash",
    configImportAndExport: "Configuration Import / Export",
    configUploadSuccess: "Your configuration has been successfully imported.",
    configuration: "Configuration",
    configurationApiDocumentation: "Configuration API Documentation",
    configurationHashChange: "Configuration Hash Change",
    configurationHashChangeAccepted:
      "Configuration hash change accepted successfully",
    configurationHashChangeMessage:
      "The hash key for this device is different indicating a change to the device configuration. If this change was expected, accept the new hash to clear this alarm. Any further changes to the device configuration after accepting this hash will trigger a new alarm.",
    configurationHashCollectionFrequency:
      "Configuration Hash Collection Frequency",
    configurationHashCollectionRuleSubtitle:
      "Choose the severity level of the alarm when a change is detected to your configuration.",
    configurationChanges: "Configuration Changes",
    configure: "Configure",
    configureDataGateway: "Configure Crosswork Data Gateway",
    configuredState: "Configured State",
    configureFlowReplicationSubTitle:
      "Note: The most granular configuration is prioritized. For example, configured devices are prioritized higher than Data Gateways.",
    confirmAsnReportDeleteContent:
      "Are you sure you want to delete the selected ASN Report - {name}?",
    confirmAsnUnlinkContent:
      "Are you sure you want to unlink the selected ASN(s)?",
    confirmAsnUnmonitorContent:
      "Are you sure you want to unmonitor selected ASN(s).",
    confirmDelete: "Confirm Delete",
    confirmDeviceRemoval: "Confirm Device Removal",
    confirmDevicesUnlinkContent:
      "Are you sure you want to unlink the selected Device(s)?",
    confirmEndpointDeleteDescription1: "Removing ",
    confirmEndpointDeleteDescription2:
      "endpoint(s) will affect all policies in all applications where it is use.",
    confirmEndpointDeleteDescription3:
      "Please review the impact on the system before removing.",
    confirmEndpointDeletion: "Confirm Endpoint Removal",
    confirmEndpointSummary1: " application(s),",
    confirmEndpointSummary2: " policies",
    confirmEndpointSummary3: " and ",
    confirmEndpointSummary4: " rule(s).",
    confirmPeerDeleteContent:
      "Are you sure you want to remove from selected Peer(s)?",
    confirmPeersUnlinkContent:
      "Are you sure you want to unlink the selected Peer(s).",
    confirmPrefixesPauseContent:
      "Select how long a duration to pause this prefix.",
    confirmPrefixesSnoozeContent:
      "Select how long a duration to snooze this prefix.",
    confirmPrefixesUnlinkContent:
      "Are you sure you want to unlink the selected Prefix(es) from their Policy?",
    confirmPrefixesUnsubscribeContent:
      "Are you sure you want to unsubscribe from selected Prefix(es)?",
    confirmUnlinkHeading: "Confirm Unlink",
    confirmUnlinkPolicyDescription: ", unlink the listed policies",
    confirmUnlinkPolicyRuleDescription: " and policy rule(s)",
    confirmUnlinkRuleDescription: " and unlink its policy rule(s)",
    confirmUnmonitorHeading: "Confirm Unmonitor",
    confirmUnpausePrefixesContent: "Are you sure?",
    confirmUnsubscribeHeading: "Confirm Unsubscribe",
    congratulations: "Congratulations",
    connected: "Connected",
    connectedDevicesDescription: "List of devices this policy monitors",
    connectedDevicesDescriptionEditMode:
      "Update list of devices this policy monitors.",
    connectedGatewayDescription: "List of gateways this policy monitors.",
    connectedGatewayDescriptionEditMode:
      "Update the list of gateways this policy monitors.",
    connection: "Connection",
    connectionType: "Connection Type",
    connectionTypeValue: "SNMP + BGP",
    connectivity: "Connectivity",
    contactDetail:
      "Enter an email address we can contact in case any issues arise with this peer.",
    contactEmail: "Contact Email",
    contactPurchasing: "Contact Purchasing",
    contacts: "Contacts",
    contactSalesTeam: "Contact Sales Team",
    contactUs: "contact us",
    containerImage: "Container Image",
    cookies: "Cookies",
    copied: "Copied to clipboard..",
    copy: "Copy",
    copyEnrollmentToken: "Copy this token for use in the CDG creation",
    copyEntitlement: "Copy Entitlement",
    costBasedOnPrefixes: "Cost based on number of prefixes purchased",
    costBasedOnDevices: "Cost based on number of devices purchased",
    count: "Count",
    country: "Country",
    countryCode: "Country Code",
    cpu: "CPU",
    cpuInPercent: "CPU in %",
    cpuUsage: "CPU Usage",
    createAccount: "Create Account",
    createAccountRequest: "Create Account Request",
    createAttachEndpoint: "Add and attach a new  Notification Endpoint",
    createBearerToken: "Add Bearer Token",
    createCCOAccount: "Create CCO Account",
    createCCOAccountDescription:
      "If you have an existing CCO account account click",
    createCCOAccountDescription1: " and return to this page.",
    createCrossworkCloudAccount: "Create Crosswork Cloud Account",
    createEndpoint: "Add Endpoint...",
    createEnrollmentToken: "Create Enrollment Token",
    createLinkPolicy: "Add or Link a Policy To Your Tracked Items",
    createNewEndpoint: "Add a new endpoint",
    createNewPolicy: "Create New Policy",
    createNewPolicySubTitle: "Enter the name of the new policy",
    createOrganization: "Create Organization",
    createOrSelectToken: "Create or select an enrollment token",
    createPolicy: "Add Policy",
    createPolicyForPeers: "Add Policy For Peers",
    createRequestDescription:
      "To activate your subscription, please provide the requested information, accept terms, and click Next",
    createTypeEndpoint: "Add {type} Endpoint",
    credential: "Credential",
    credentialAddSubtitle:
      "Credentials allow you to configure a single set of credentials that can be used across devices.",
    credentialAddSuccess: "Credential added successfully.",
    credentialDeleteSuccess: "Credential(s) removed successfully.",
    credentialLinkSuccess: "Credential linked successfully.",
    credentials: "Credentials",
    credentialTypeSubtitle: "Type: {credType}",
    credentialUpdateSuccess: "Credential updated successfully.",
    critical: "Critical",
    criticalDescription:
      "One or more device interfaces are above 80% utilization",
    crosswork: "Crosswork",
    crossworkBannerText:
      "A SaaS solution that provides operational insight and validation of network health and security with the agility and scale of the cloud.",
    crossworkCloud: "Crosswork Cloud",
    crossworkCloudIntegrations: "Crosswork Cloud Integrations",
    csvFile: "Upload CSV File",
    csvFileUploadHint: "Select CSV file you wish to import.",
    csvImport: "CSV Import",
    csvOverwrite: "CSV Overwrite",
    csvUploadFileCaution:
      "Uploading a CSV file overwrites existing prefixes. After you click Review & Submit, any prefixes not contained in the CSV file are deleted, including associated history and tracking information. Use caution because you cannot undo this operation.",
    current: "Current",
    currentDeviceStatus: "Current Device Status",
    currentFrequency: "Current Frequency",
    currentLocation: "Current Location",
    currentPolicy: "Current Policy",
    currentPrefixes: "Current # of Prefixes",
    currentServiceTier: "Current Service Tier",
    currentState: "Current State",
    currentStatus: "Current Status",
    currentTags: "Current Tags",
    currentUsage: "Current Usage",
    customizeColumns: "Customize Columns",
    customTimeframe: "Custom Timeframe",
    daily: "Daily",
    dailyASN: "Daily ASN",
    dailyASNDescription:
      "A daily report that contains information about the changes in route announcements and peering relationships for your Autonomous System.",
    dailyReport: " Daily Report",
    dashboard: "Dashboard",
    dashboardExpressDescription:
      "The easiest way to start using Crosswork External Routing Analysis is to configure policies using the Express Setup wizard.",
    dashboardHelpDescription:
      "To get additional help, please click one of the following options:",
    dashboardTrialDescription:
      "You can also explore other Cisco Crosswork Cloud applications to help monitor your network.",
    dashboardWidgetAlarmsTableTitle: "Violation Peers",
    dashboardWidgetJump: "Quick Jump",
    dashboardWidgetJumpBtn: "View",
    dashboardWidgetJumpInputHelp:
      "Enter an ASN, prefix or IP to view associated details.",
    dashboardWidgetJumpInputLabel: "ASN, Prefix or IP",
    dashboardWidgetLinksEndpoints: "Edit Notification Endpoints",
    dashboardWidgetLinksEndpointsRO: "View Notification Endpoints",
    dashboardWidgetLinksExpress: "Express Setup",
    dashboardWidgetLinksSubtitle:
      "Helpful links to specific sections of the application",
    dashboardWidgetLinksTitle: "Application Shortcuts",
    dashboardWidgetLinksUsers: "Manage Users",
    data: "Data",
    dataGateway: "Data Gateway",
    dataGatewayAdded: "Data Gateway Added",
    trustDataGatewayConnectivityAlarmDescription:
      "Detects when the on-premises Crosswork Data Gateway used for dossier collection is not reachable and dossier collection may be interrupted.",
    dataGateways: "Data Gateways",
    dataGatewayStatus: "Data Gateway status",
    dataGatewayTrafficAnalysis: "Data Gateway: Traffic Analysis",
    dataGatewayTrustInsights: "Data Gateway: Trust Insights",
    dataGatewayVmId: "Data gateway virtual machine identification",
    dataLookBack: "Historical Data",
    dataRetentionLookback: "Data Retention and Lookback",
    dataRetentionLookbackDescription:
      "Trust Insights maintains a trusted audit trail of device inventory and history of managed devices.",
    dataTemplates: "Data Templates",
    dataTypes: "Data Types",
    dataTypeToView: "Data type you wish to view",
    date: "Date",
    dateAdded: "Date Added",
    dateClaimed: "Date Claimed",
    dateCreated: "Date Created",
    dateGreaterThanToday: "Date should be greater than today",
    dateLessthanTodayWarning: "Date can't be a previous date",
    dateTime: "Date / Time",
    day: "Day",
    dayorDays: "Day(s)",
    days: "Days",
    days30: "30 Days",
    daysRemaining: "days remaining",
    daysToTriggerBeforeExpiration: "Days to Trigger Before Expiration",
    deactivate: "Deactivate",
    deactivateAlarm: "Deactivate Alarm",
    deactivated: "Deactivated",
    deadSessions: "Dead Sessions",
    default: "Default",
    defaultDeviceGroup: "Unassigned",
    defaultDeviceGroupNoEdit: "The default device group can not be edited",
    defaultDeviceGroupOrNone: "None/Unassigned",
    defaultPolicy: "Default Policy",
    defaultPolicyTypeHeader: "{type} Connection",
    defaultPolicyTypeIgnored: "{type} Ignored",
    defaultPolicyTypeLastActiveAlarm: "Last Active Alarm {type}",
    defaultPolicyTypeMonitored: "{type} Monitored",
    defaultPolicyTypeSubHeader: "{type} connectivity to {description}",
    defaultToNetflowAddress: "Defaults to Netflow Address if not provided",
    deleteBearerTokenConfirm:
      "Are you sure you want to remove {count} selected Bearer Token(s)?",
    deleteCdgMsg:
      "This will remove this data gateway from your system. In order to use this device in the future you will have to add the gateway back into the system.",
    deleteCredentialMsg: "This will permanently remove the credential(s).",
    deleted: "Deleted",
    deleteDataGateways: "Delete Data Gateways",
    deleteDataGatewaysMsg:
      "Selected Data Gateway(s) will be removed permanently.",
    deletedBy: "Removed By",
    deleteDevicesMsg: "Selected Device(s) will be removed permanently.",
    deleteKeyConfirm:
      "Are you sure you want to remove {count} selected key(s)?",
    deleteUsersMsg:
      "Are you sure you want to remove {count} users?  You cannot undo this action once it is taken.",
    demo: "Demo",
    deprecated: "Deprecated",
    derivedFrom: "Derived from: ",
    derivedFromBelowSources: "Derived from below sources",
    derivedFromBGPUpdates: "Derived from BGP Updates",
    derivedFromLookingGlass: "Derived from Looking Glass",
    derivedFromRIRData: "Derived from RIR Data",
    derivedFromWhoisAndGeoData: "Derived from RIR and Geolocation data",
    DES: "DES",
    descending: "Descending",
    description: "Description",
    descriptionHint: "Please be as descriptive as possible.",
    deselectAll: "Deselect All",
    destinationAsns: "Destination ASN(s)",
    destinationPort: "Destination Port",
    details: "Details",
    detectedLocation: "Detected Location",
    deviantDevices: "Deviant Devices",
    deviation: "Deviations",
    deviation1: "Platform is different",
    deviation2: "Base OS version is different",
    deviation3: "Platform and Base OS version are different",
    device: "Device",
    deviceAddSuccess: "Device added successfully.",
    deviceAdminState: "Device Admin State",
    deviceAlreadyUptoDate: "Current device functionality is up to date.",
    deviceBaseOs: "Device: Base OS",
    deviceCDGLinked: "Device linked to Crosswork Data Gateway",
    deviceCentric: "Device Centric",
    deviceChanges: "Device Changes",
    deviceChangesForTs: "Device Changes for {userSelectedChangesTs}",
    deviceComparison: "Device Comparison",
    deviceConfigurationHashExpectationMessage1:
      "The stored device configuration hash is expected to",
    deviceConfigurationHashExpectationMessage2:
      "the hash reported by the system",
    deviceConfigurationHashExpectationMessage3: "The stored XR commit ID is ",
    deviceConnectivity: "Device Connectivity",
    deviceConnectivityAlarmDescription:
      "Monitor device connectivity to gateway",
    deviceConnectivityChangeAlarmExpectedMessage:
      " is configured to connect to ",
    deviceConnectivityChangeAlarmObservedMessage:
      "This device is having connectivity problems with ",
    deviceConnectivityChangeAlarmObservedTimeMessage: "Lost connection at ",
    deviceConnectivityChangeAlarmObservedTimeLossMessage: "Loss of ",
    deviceConnectivityChangeAlarmObservedTimeOccuredMessage:
      " connection last occurred at ",
    deviceConnectivityIssue: "Device connectivity issue",
    trustDeviceConnectivityAlarmDescription:
      "Detects when Crosswork Cloud cannot reach a monitored Cisco IOS XR device for dossier collection.",
    deviceCredentials: "Device Credentials",
    deviceCSVImportCaptionLine1:
      "Download and edit the CSV file template to add up to {deviceLimit} to Crosswork Cloud with each import.",
    deviceCSVImportCaptionLine2:
      "The following must be configured before importing the CSV file, or the import will fail:",
    deviceCSVImportReq1: "At least one Data Gateway in Crosswork Cloud.",
    deviceCSVImportReq2:
      "Any credentials, device groups, or Data Gateways entered in the CSV file.",
    deviceDeactivated: "Device deactivated.",
    deviceDeleteSuccess: "Device removed successfully.",
    devicesDeleteSuccess: "Devices removed successfully",
    deviceDescription: "Device Description",
    deviceDisabled: "Device is disabled",
    deviceDisableSuccess: "Device disabled successfully.",
    deviceEditSuccess: "Device updated successfully.",
    deviceEnableSuccess: "Device enabled successfully.",
    deviceEnrollmentCertExpirationDescription:
      "Detects when the certificate used to enroll and validate a Cisco IOS XR device has expired.",
    deviceEnrollmentCertExpirationImminentSubtitle:
      "Choose the expiration status required to trigger a notification and the severity level.",
    deviceEnrollmentCertExpirationSubtitle:
      "Choose the severity level of the alarm when the device certificate expires.",
    deviceEnrollmentCertificateImminentExpiryDescription:
      "Monitors pending expiration status and is triggered when the number of days prior to expiration reaches the time you specify (7, 30, 60, or 90 days).",
    deviceEnrollmentCertificateViolationDescription:
      "Detects when the certificate used to enroll and validate a Cisco IOS XR device has failed validation.",
    deviceFilterType: "Device Filter Type",
    deviceGroup: "Device Group",
    deviceGroupAddSuccess: "Device group added successfully.",
    deviceGroupDeleteMsg: "This will permanently delete the device group(s).",
    deviceGroupDeleteSuccess: "Device group removed successfully. ",
    deviceGroupEditSuccess: "Device group updated successfully.",
    deviceGroups: "Device Groups",
    deviceGroupsDeleteSuccess: "Device group(s) removed successfully. ",
    deviceGroupSubtitle:
      "Grouping devices allows you to perform actions on a group of similar device types.",
    deviceHardwareComparison: "Device Hardware Comparison",
    deviceHardwareComparisonDescription:
      "Trust Insights will detect and report on hardware changes, including addition or removal of hardware, or changes in serial or part numbers or other device identifiers.",
    deviceHashCollectionSuccessfullyDisabled:
      "Device Hash Collection Successfully disabled",
    deviceHashCollectionSuccessfullyEnabled:
      "Device Hash Collection Successfully enabled",
    deviceImportLimitErrMsg:
      "You can only import 1,000 devices in one operation.",
    deviceImportSuccess: "Device(s) imported successfully.",
    deviceInformation: "Device Information",
    deviceIP: "Device IP",
    deviceLinkSuccess: "Device(s) linked successfully.",
    deviceModel: "Device Model",
    deviceModelFilter: "Device: Model",
    deviceName: "Device Name",
    deviceNameStartsWith: "Device Name starting with",
    deviceNetflowGateway: "Configure Devices",
    deviceNotDeactivated: "Device not deactivated.",
    deviceOverview: "Device Overview",
    devicePolicyLinkRemoved: "Removed devices linked with Policy",
    devicePolicySuccess: "Device(s) linked successfully",
    deviceRemoveMsg: "{deviceName} will be removed.",
    deviceDeleteWarning: {
      singular: "device",
      plural: "devices",
      willAffect:
        "will affect all policies in all applications where it is in use.",
      pleaseReviewTheImpact:
        "Please review the impact on the system before removing."
    },
    deviceRestarts: "Device Restarts",
    deviceRightToUse: "Device Right To Use",
    deviceRTU: "Device RTU",
    devices: "Devices",
    devicesPurchased: "Devices-Purchased",
    devicesRxTraffic: "Device Rx Traffic",
    devicesTxTraffic: "Device Tx Traffic",
    deviceSearch: "Device Search",
    deviceSelectLocation: "Device: Location",
    deviceSelectName: "Device: Name",
    deviceSelectTags: "Device: Tags",
    deviceSerialNumber: "Device Serial Number",
    deviceSize: "Device Size",
    devicesLinkSuccess: "Devices linked successfully",
    deviceSNMPLinked: "SNMP credentials configured on device",
    deviceSoftwareComparison: "Device Software Comparison",
    deviceSoftwareComparisonDescription:
      "Trust Insights will detect and highlight changes in installed software versions including major OS, SMUs, and RPM packages.",
    deviceSoftwareDifferences: "Device Software Differences",
    deviceSshHostKeyViolation: "device ssh host key violation",
    deviceSshHostKeyViolationDescription:
      "Detects when the SSH host key for a monitored Cisco IOS XR device has changed and dossier collection has not been completed.",
    deviceSSHStatus: "SSH: {statusValue}",
    deviceRunningConfigChangeDescription:
      "Device Running Configuration Changes",
    devicesUnlinkedSuccess: "Devices unlinked successfully",
    deviceSupport: "Device Support",
    devicesWithChanges: "Devices With Changes",
    deviceTag: "Device Tag",
    deviceTags: "Device Tags",
    deviceTimezone: "Device Timezone",
    deviceTrafficAnalysis: "Device Traffic Analysis",
    deviceTrafficAnalysisDescription: "",
    deviceTrafficAnalysisRecommendationDescription: "",
    deviceTrafficGroupAnalysis: "Device Traffic Group Analysis",
    deviceTrafficGroupAnalysisDescription: "",
    deviceType: "Device Type",
    deviceUnlinkConfirmMsg: "This will unlink selected device(s).",
    deviceUnlinkSuccess: "Device(s) unlinked successfully.",
    deviceUpgradeMsg:
      "This device can gain added functionality with an update. Use the toggle to see the preview of what this update will provide.",
    diff: "Diff",
    differences: "Differences",
    diffTree: "Diff Tree",
    diffTreeExceededLimit: "Too many results. Displaying a subset of changes.",
    directly: "Directly",
    disable: "Disable",
    disableCdgMsg:
      "This will disable this Crosswork Data Gateway and it will no longer be able to send network data to Crosswork Cloud until enabled.",
    disabled: "Disabled",
    disableDeviceMsg: "This device will be disabled",
    disableDevicesMsg: "Selected device(s) will be disabled",
    disableConfigurationHash: "Disable Configure Hash",
    disableConfigurationHashInfo1:
      "Continuing will disable any future alarms and acknowledge any active alarms monitoring device configuration for this device.",
    disableConfigurationHashInfo2:
      "Are you sure you want to no longer monitor this device for configuration changes and clear all relevant active alarms?",
    discardedData: "Discarded Data",
    discover: "Discover",
    disk: "Disk",
    displayingDeviceCaption:
      "Displaying device {current} of {total} to be imported.",
    dnsError: "DNS error",
    dnsRootPrefixWithdrawalDescription:
      "Public Root DNS servers, which includes those assigned by IANA as well as those by OpenDNS and Google, are necessary for normal operation of a router participating in public internet routing. This alarm allows a user to monitor a set of prefixes (netblocks) to which the dns server address belongs and alerts the user if any prefix in the set is withdrawn. This alarm differs from the Prefix Withdrawal alarm because these prefixes do not add to the total amount of prefixes consumed by the user in their subscription, and the withdrawal is from a peer linked to the alarm rule.",
    documentation: "Documentation",
    documentationDesc:
      "Click on any of the production documentation links below for detailed user instructions. To access contextual help, click the help link in the page header of any screen.",
    domain: "Domain",
    doNotShowAgain: "Do not show this again",
    dormant: "Dormant",
    dossierCollection: "Dossier Collection",
    dossiersCollected: "Dossiers Collected",
    dossierCollectionErrors: "Dossier Collection Errors",
    dossierCollectionFailure: "Dossier Collection Failure",
    dossierCollectionFailureDescription:
      "Detects when Crosswork Cloud has failed to collect a dossier from a monitored Cisco IOS XR device.",
    dossierCollectionFailureRuleSubtitle:
      "Choose the severity level of the alarm when a device dossier collection fails.",
    dossierCollectionWarning:
      "Dossier collection may take a few minutes to complete.",
    dossierError: "Dossier Error",
    dossierErrors: "Dossier Errors",
    dossierInfoSync: "Dossier Information Synchronization",
    dossierRequestIssued: "Dossier collection request issued",
    dossierSettings: "Dossier Settings",
    dossierSettingsSuccess: "Dossier settings updated successfully.",
    down: "Down",
    downloadConfigurations: "Export Configuration",
    downloadFileTemplate: "Download Sample JSON Configuration File",
    downloadSampleCSV: "Download Sample CSV File",
    downStream: "Downstream",
    downstreamAs: "Downstream AS",
    downstreamAsCount: "Downstream AS Count",
    duplicateDevice: "Duplicate Device",
    duplicateGateway: "Duplicate Gateway",
    duration: "Duration",
    dynamic: "Dynamic",
    editCredential: "Edit Credential",
    editDate: "Edit Date",
    editedBy: "Edited By",
    editFilter: "Edit Filter",
    editingDevices: "Editing {deviceCount} Device(s)",
    editingTrafficDevices: "Editing {deviceCount} Traffic Analysis Device(s)",
    editingTrustDevices: "Editing {deviceCount} Trust Insights Device(s)",
    editInterface: "Edit Interface",
    editPermissionDenied: "You are not allowed to modify this user",
    editTags: "Edit Tags",
    editTagsDesc:
      "Your changes will overwrite tags associated with the selected items",
    editValue: "Edit Value",
    egressInterface: "Egress Interface",
    email: "Email",
    emailAddress: "Email Address",
    emailConfirmation:
      "Please check your email for your trial confirmation and follow any instructions listed.",
    emailDescription:
      "Configure a notification, such as an alarm or report, to be sent to an email address.",
    emailValidationHint: "Please enter a valid and complete email address",
    embedded: "Embedded",
    embeddedVoucher: "Embedded Voucher",
    emsCertificate: "EMS Certificate",
    enable: "Enable",
    enabled: "Enabled",
    end: "End",
    endBeforeStartTimeError: "The end time is before the start time",
    endDate: "End Date",
    endpoint: "Endpoint",
    enjoy: "Enjoy",
    endpointAddedToRules: "Endpoint added to rules",
    endpointAlreadyOnPolicy: "Endpoint already exists on this Policy",
    endpointCreation: "Add Endpoint",
    endpointLinked: "Endpoint Linked to Rule",
    endpointRuleUnlinked: "Endpoint Unlinked from Rules",
    endpoints: "Endpoints",
    endpointSaved: "Endpoint Saved",
    endpointsDeleted: "Endpoints Removed",
    endpointsDisabled: "Endpoints Disabled",
    endpointsEnabled: "Endpoints Enabled",
    endpointsToLink: "Endpoints to Link ",
    endpointsWithCount: "Endpoints ({count})",
    endPointType: "Endpoint Type",
    endpointVerifyDesc:
      "We have sent a verification code to the {name} endpoint.  Please enter the code that was sent in the field below.  This is to verify that you have ownership over this endpoint.",
    endTime: "End Time",
    endUserEmail: "End User Email",
    endUserHeader:
      "If you are not the end user, enter the product administrator email address.",
    enrollmentCertViolationRuleSubtitle:
      "Choose the severity level of the alarm when the dossier signature does not match the device enrollment certificate.",
    enrollmentState: "Enrollment State",
    enrollmentToken: "Enrollment Token",
    enrollmentTokenCreatedSuccess: "Enrollment token created successfully.",
    enrollmentTokenDeleteSuccess: "Enrollment token removed successfully.",
    enrollmentTokenName: "Token Name",
    enrollmentTokenStepMsg:
      "Create a new or select an existing token. Copy and paste this token into the CDG creation flow to provide an Auto Enrollment feature. Once the CDG is created and booted up it will request admittance to your organization in Crosswork Cloud.",
    enterASN: "Enter ASN",
    enterPrefix: "Enter Prefix",
    enterPrefixes: "Enter Prefixes",
    enterROAASN: "Enter ROA ASN",
    enterprise: "Enterprise",
    enterSubscriptionIDs:
      "Enter your subscription IDs. To add multiple IDs, separate each ID with a comma or enter each ID on a new line.",
    entitlement: "Entitlement",
    entitlementConfirmation: "Entitlement Confirmation",
    entitlementConfirmationDescription:
      "Thank you for purchasing Cisco Crosswork Cloud. Make sure to copy and store the following ID in case you need it to activate your Crosswork Cloud entitlement at a later time.",
    entitlementConfirmationDescriptionFooter1:
      "To activate your entitlement, do one of the following:",
    entitlementConfirmationDescriptionFooter2:
      "if you have an existing Crosswork Cloud account.",
    entitlementConfirmationDescriptionFooter3: " to create a new account.",
    entitlementConfirmationDescriptionFooter4:
      "Note: You must have a Cisco Connection Online (CCO) account prior to creating a Crosswork Cloud account. You will have the opportunity to create one in the following screens.",
    entitlementId: "Entitlement ID",
    entitlementSuccessful: "Your entitlements have been successfully imported",
    enums: {
      alarmCondition: {
        active: "Active",
        cleared: "Cleared",
        configured: "Configured",
        invalid: "Invalid",
        unconfigured: "Unconfigured"
      },
      alarmState: {
        acknowledged: "Acknowledged",
        active: "Active",
        activeSnoozed: "Active (Snoozed)",
        activeSnoozedOnce: "Active (Snoozed Once)",
        cleared: "Cleared",
        clearedSnoozed: "Cleared (Snoozed)",
        configured: "Configured",
        snoozed: "Snoozed",
        snoozedIndefinitely: "Snoozed Indefinitely",
        snoozedOnce: "Snoozed Once",
        snoozedUntil: "Snoozed Until",
        unconfigured: "Unconfigured"
      },
      bgpAfi: {
        ipv4: "IPv4",
        ipv6: "IPv6",
        ipv6Mcast: "IPv6 Multicast"
      },
      bgpState: {
        active: "Active",
        connect: "Connect",
        established: "Established",
        idle: "Idle",
        openConfirm: "Open Confirm",
        openSent: "Open Sent"
      },
      endpoints: {
        email: "Email",
        google: "Google Storage",
        microsoft: "Microsoft Teams",
        pager: "PagerDuty",
        s3: "Amazon S3",
        slack: "Slack",
        sms: "SMS",
        teams: "Webex"
      },
      monitor: {
        alreadyMonitored: "Already Monitored",
        monitored: "Monitored",
        unmonitored: "Unmonitored"
      },
      productId: {
        L_SPAUTO_NI_IB: "External Route Analysis Basic Tier",
        L_SPAUTO_NI_IE: "External Route Analysis Essentials Tier",
        L_SPAUTO_NI_IS: "External Route Analysis Standard Tier"
      },
      rules: {
        aggregate: "Parent Aggregate Change",
        aggregateMyPeers: "Parent Aggregate Change - My Peers",
        asNewPrefix: "Unexpected AS Prefix",
        asPathLength: "AS Path Length Violation",
        asPathLengthMyPeers: "AS Path Length Violation - My Peers",
        dataGatewayConnectivity: "Data Gateway Connectivity",
        deviceConnectivity: "Device Connectivity",
        deviceEnrollmentCertExpiration: "Expired Device Certificate",
        deviceEnrollmentCertImminentExpiry: "Device Certificate Expiring",
        deviceEnrollmentCertViolation: "Device Certificate Violation",
        deviceSshHostKeyViolation: "Device SSH Host Key Violation",
        deviceRunningConfigChange: "Device Running Configuration Change",
        dossierCollectionFailure: "Dossier Collection Failure",
        hardwareIntegrityValidation: "Hardware Integrity Validation",
        interfaceRxUtilization: "Interface RX Utilization",
        interfaceTxUtilization: "Interface TX Utilization",
        leftAsn: "Upstream AS Change",
        leftAsnMyPeers: "Upstream AS Change - My Peers",
        lossOfConnection: "Loss of Connection",
        lossOfDeviceConnection: "Lost Device Connection",
        lossOfGatewayConnection: "Lost Gateway Connection",
        mismatchedFiles: "Mismatched Files",
        newASPathEdge: "New AS Path Edge",
        originAs: "AS Origin Violation",
        originAsMyPeers: "AS Origin Violation - My Peers",
        packageValidation: "Package Validation",
        peerAdvertisedPrefixCount: "Advertised Prefix Count",
        peerDnsRootServerWithdrawal: "DNS Root Server Withdrawal",
        peerDown: "Peer Down",
        prefixAdvertisement: "Prefix Advertisement",
        prefixAdvertisementMyPeers: "Prefix Advertisement - My Peers",
        prefixUtilization: "Prefix Utilization",
        prefixWithdrawn: "Prefix Withdrawal",
        prefixWithdrawnMyPeers: "Prefix Withdrawal - My Peers",
        prohibitedIpPrefix: "Prohibited IP Prefix",
        roa: "ROA Failure",
        roaExpiry: "ROA Expiry",
        roaNotFound: "ROA Not Found",
        subprefixAdvertisement: "Subprefix Advertisement",
        unknownFile: "Unknown Files",
        unknownRuleType: "Unknown Rule Type",
        validAsPath: "Valid AS Path Violation",
        validAsPathMyPeers: "Valid AS Path Violation - My Peers"
      },
      severity: {
        gray: "Low",
        red: "High",
        unknown: "Unknown",
        yellow: "Medium"
      },
      subscribe: {
        alreadySubscribed: "Already Subscribed",
        no: "No",
        yes: "Yes"
      }
    },
    enumsText: "Enums",
    error: "Error",
    errorCode: "Error code {error}",
    errorCollected: "Errored",
    errorCollectedFmt: "Errored {time} ago",
    collectingConfigurationHash: "Collecting Configuration Hash",
    errorFetchingUserDetails: "Error Fetching User Details",
    errorTrustLicense: "Error Adding Embedded Trust License",
    errorViewDetails: "Error - View Details",
    essential: "Essential",
    essentialLicenses: "Essential Licenses",
    essentials: "Essentials",
    essentialsAlaCarte: "Essentials a-la-carte",
    essentialsEmbedded: "Essentials Embedded",
    essentialsFunction: "Essentials + Function",
    estimatedGeolocation: "Estimated Geolocation",
    every20Min: "Every 20 min",
    eventAt: "Event At",
    events: "Events",
    eventType: "Event Type",
    exactMatch: "Exact Match",
    example: "Example",
    examples: "Examples",
    excludedIpv4Masks: "Exclude IPv4 Masks",
    excludedIpv6Masks: "Exclude IPv6 Masks",
    existingPolicy: "Existing Policy",
    existingPurchases: "Existing Purchases",
    expected: "Expected",
    expectedAsPathEditor: "Expected AS Path Editor",
    expectedAsPathJson: "Expected AS Path JSON",
    expectedHash: "Expected Hash",
    expectedOriginASNs: "Expected Origin ASNs",
    expectedProhibitedIpPfxBogonMsg:
      "The peer is not expected to advertise unassigned and prohibited (bogon) prefixes.",
    expectedProhibitedIpPfxFullBogonMsg:
      "The peer is not expected to advertise IP address space that is prohibited (bogons) or that has been allocated to a RIR but not assigned by that RIR to an actual ISP or other end-user.",
    expectedUpstreamASNs: "Expected Upstream ASNs",
    expired: "Expired",
    expiredSubscription:
      "Your subscription has expired. To avoid service disruption, extend or change your subscription. For more information about purchasing please go  ",
    expires: "Expires",
    expiresToday: "Expires Today",
    expiresWithin: "Expires Within",
    expiring: "Expiring",
    expiringROA: "Expiring ROA",
    expiringROACount: "Expiring ROA Count",
    expiringROARecords: "Expiring ROA Records",
    expiringSoon: "Expiring Soon",
    expiryDate: "Expiry Date",
    exportCsvDesc:
      "The current number of records loaded in memory is displayed below. If you want to export more records than currently loaded, please enter the desired number (up to one thousand) and select Export.",
    exportCsvInputHint: "Maximum amount is 1,000 records",
    exportCsvInputLabel: "Records to be Exported",
    exportFullDiffTreeJSON: "Export Full JSON of Changes",
    exportImage: "Export Image",
    exportNRowsFmt: "Export {rows} Rows",
    exportRowsToCsv: "Export Rows to CSV",
    exportStatusReport: "Export Status Report",
    expressErrorAsnHasPolicyAction: "Unlink Policy",
    expressErrorAsnHasPolicyDescription:
      "The selected ASN has {policyName} policy associated with it. An ASN can have only one associated policy. To run Express Setup on this ASN, you must first unlink the policy from the ASN.",
    expressErrorAsnHasPolicyHeader: "ASN policy already exists",
    expressErrorAsnPolicyAlreadyExistAction: "Delete Express ASN Policy",
    expressErrorAsnPolicyAlreadyExistDescription:
      "The ASN policy {policyName} already exists. To run Express Setup again, you must first delete this existing policy.",
    expressErrorAsnPolicyAlreadyExistHeader:
      "Express ASN policy already exists",
    expressErrorPrefixPolicyAlreadyExistAction: "Delete Express Prefix Policy",
    expressErrorPrefixPolicyAlreadyExistDescription:
      "The prefix policy {policyName} already exists. To run Express Setup again, you must first delete this existing policy.",
    expressErrorPrefixPolicyAlreadyExistHeader:
      "Express prefix policy already exists",
    expressPrefixLoadError:
      "Unable to load prefix data. Existing tags on selected prefixes may be lost if you proceed.",
    expressSetup: "Express Setup",
    expressSetupSuccess: "Express setup completed successfully",
    expressWizardDesc: "Get started quickly by using these simple setup tools.",
    expressWizards: "Express Wizards",
    extend: "Extend",
    extendTrial: "Extend Trial",
    extendTrialSubscription: "Extend your trial subscription",
    external: "External",
    externalRouteAnalysis: "External Route Analysis",
    externalRouteAnalysisTrial: "External Route Analysis Trial",
    externalRouting: "External Routing",
    externalRoutingAnalysis: "External Routing Analysis",
    externalRoutingDecription:
      "Network and BGP analysis to maintain routing health by monitoring for route leaks and hijacks.",
    extraLarge: "Extra Large",
    extraSmall: "Extra Small",
    failure: "Failure",
    failures: "Failures",
    featureIsDisabled: "Feature is disabled",
    featureRequest: "Feature Request",
    featureType: "Feature Type",
    feedback: "Feedback",
    feedbackSuccess: "Feedback submitted successfully.",
    fieldReplaceUnit: "Field Replaceable Unit",
    fifteenMinutes: "15 Minutes",
    file: "File",
    fileAndPath: "File & Path",
    fileCName: "File: Name",
    fileEmptyError: "File has no content",
    fileHasNoContent: "Selected file has no content",
    fileMismatch: "Files Mismatches",
    fileName: "File Name",
    fileParsingError: "Error parsing file content",
    files: "Files",
    fileSelection: "File Selection",
    fileSignatureAnalysis: "File Signature Analysis",
    fileSignatureAnalysisNameFmt: "File Signature Analysis : {file}",
    fileSignatureAnalysisSubTitle:
      "Historical details and other devices where the file appears.",
    fileSizeExceeds10MB: "File size exceeds 10MB",
    filesObservedRunning: "Files Observed Running",
    filesOnDisk: "Files On Disk",
    filesWithMismatch: "Total Files with Mismatches",
    fileTypeError: "Invalid file type",
    fileUploadHint: "Select JSON configuration file to import.",
    fillInfo: "Please fill in the information",
    filter: "Filter",
    filterBy: "Filter By",
    filterCriteriaTitle:
      "Select the type of filter, the filter value, and whether to include or exclude if available, the value from the results",
    filteredAlarms: "Filtered Alarms",
    filteredBy: "Filtered By",
    filteringSortingForHiddenRemoved:
      "Filtering and sorting for hidden column(s) has been removed",
    filtersChanged: "Filters Have Changed",
    filtersChangedDesc:
      "Changes to filters have been made. Would you like to apply them?",
    filterType: "Filter Type",
    findByDevice: "Find By Device",
    findByFile: "Find By File",
    findDetails: "Find Details",
    findFileHashFilterText: "Enter a full or partial hash value",
    findFileNamePathFilterText: "Enter File name with Path",
    findFiles: "Find Files",
    findFilesNoData:
      "You must create at least one filter in addition to Device Type, which is a required filter.",
    findFilesSubHeader:
      "Locate files by entering a hash, file and path, or search by device.",
    findHardware: "Find Hardware",
    findHardwareSubTitle:
      "Locate specific components by name, model, or serial numbers",
    findHardwareTableFilter:
      "Click Add Filter and enter criteria to search for hardware by name, model, or serial number.",
    fingerPrintRuntimeMissing:
      "With fingerprinting disabled we are not able to fully inspect the integrity of packages. Please enable fingerprinting by following the instructions found ",
    findOutMore: "Find Out More",
    findPackage: "Find Package",
    findReseller: "Find Reseller",
    finish: "Finish",
    firmware: "Firmware",
    firmwareRevision: "Firmware Revision",
    firstHop: "1st Hop",
    firstLast: "First Last Name",
    firstLoginDate: "First Login Date",
    firstName: "First Name",
    firstPage: "First Page",
    firstSeen: "First Seen",
    fiveMinutes: "5 Minutes",
    fixed: "(Fixed)",
    float: "float",
    flowCDGCannotBeSameAsTrustCDG:
      "Traffic Data Gateway cannot be same as Trust Data Gateway",
    flowExtraLargeDeviceDescription: "Extra Large < 1000 Gbps Throughput",
    flowExtraLargeShortDeviceDescription: "Extra Large",
    flowExtraSmallDeviceDescription: "Extra Small < 5 Gbps Throughput",
    flowExtraSmallShortDeviceDescription: "Extra Small",
    flowLargeDeviceDescription: "Large < 350 Gbps Throughput",
    flowLargeShortDeviceDescription: "Large",
    flowMediumDeviceDescription: "Medium < 100 Gbps Throughput",
    flowMediumShortDeviceDescription: "Medium",
    flowReplication: "Flow Replication",
    flowReplicationConfigAddSuccess:
      "Flow replication configuration added successfully.",
    flowReplicationConfigEditSuccess:
      "Flow replication configuration updated successfully.",
    flowReplicationPortHint: "Defaults to 2055 if not provided",
    flowReplicationSubscriptionDescription:
      "Send Netflow Traffic to Multiple Destinations",
    flowSmallDeviceDescription: "Small < 25 Gbps Throughput",
    flowSmallShortDeviceDescription: "Small",
    flowsPerSecond: "Flows Per Second",
    focus: "Focus",
    footer: "© {year} Cisco and/or its affiliates. All rights reserved.",
    forgotPassword: "Forgot Password",
    forward: "Forward",
    fourOctetASN: "Four Octet ASN",
    fqdn: "FQDN",
    free: "Free",
    freeAccess: "Gain free access to a robust set of features for ",
    freeOneYear: "Free for 1 Year",
    freeDisclaimer:
      "Free accounts are subject to Cisco approval and can be cancelled at any time.",
    freeGuide: " Cisco Crosswork Cloud Account Guide.",
    freeMem: "Free Memory",
    freeRequest: "Network Insights Free Account Request",
    freeUserSubscriptionExpired:
      "To avoid service disruption, you must meet one of the free subscription ",
    freeUserSubscriptionExpired1: "requirements ",
    freeUserSubscriptionExpired2: "or purchase a subscription ",
    frequency: "Frequency",
    from: "From",
    fullBogons: "Full bogons",
    fullBogonsSubtext:
      "IP address space that is prohibited (bogons) or that has been allocated to a RIR but not assigned by that RIR to an actual ISP or other end-user.",
    fullFeatureAccess: " full feature access for 90 days, ",
    functionPack: "Function Packs",
    gateway: "Gateway",
    gatewayConnectivity: "Gateway Connectivity",
    gatewayConnectivityAlarmDescription:
      "Monitor gateway connectivity to Crosswork Cloud",
    gatewayConnectivityIssue: "Gateway connectivity issue",
    gateways: "gateways",
    gbps: "GBps",
    gigabitPerSecond: "Gbps",
    general: "General",
    generalFeatures: "General Features",
    generate: "Generate",
    generateReport: "Generate Report",
    generateReportSuccess:
      "Submitted the request for generating report, please check for the generated report after 5 minutes.",
    geoLocationConfidenceTooltip:
      "The estimated geolocation is based on the location of a sampling of IPs in the prefix that matches the address information from the Regional Internet Registry (RIR).",
    geoLocationGaugeTooltip:
      "The metrics gauge indicates the confidence level (Low, Med, or High) of how accurate the location is based on prefix IP sampling.",
    getHelp: "Get Help",
    getStarted: "Get Started",
    gettingStarted: "Getting Started",
    giveFeedback: "Give Feedback",
    global: "Global",
    goBack: "Go Back",
    googleKeyUploadHint:
      "Select and upload the Google Storage key file that you retrieved from your Google account.",
    goToAsn: "Go To ASN",
    goToPrefixOrIp: "Go To prefix or ip",
    goToReports: "Go To Reports",
    goToTopology: "Go to Topology",
    gracefulRestart: "Graceful Restart",
    gracefulRestartFor: "Graceful restart for {af}",
    graphs: "Graphs",
    grid: "Grid",
    group: "Group",
    groupedByThingFmt: "Grouped By {thing}",
    groups: "Groups",
    groupTags: "Group Tags",
    gs: "Google storage",
    gsDescription:
      "Configure a notification, such as an alarm, to be sent to a Google Storage Service account.",
    hardware: "Hardware",
    hardwareAnchoredSecureBootDetails:
      'Device supports Secure Boot controls anchored to hardware Root-of-Trust within embedded Trust Anchor Module(s). Provides additional hardening and protection from "boot-kit" attacks.',
    hardwareanchoredsecurestorageDetails:
      "The IOS XR operating system supports a secure, encrypted storage facility for secrets such as cryptographic keys. Encrypted volume is unlocked with key stored within tamper-resistant hardware Trust Anchor Module(s).",
    hardwareChanges: "Hardware Changes",
    hardwareDetailsUnknown: "There are no hardware details for this device",
    hardwareIntegrityRuleSubtitle:
      "Indicate the number of Cisco IOS XR validation failures required to trigger a notification.",
    hardwareIntegrityValidation: "Hardware Integrity Validation",
    hardwareIntegrityValidationDescription:
      "Detects a hardware integrity validation failure on a Cisco IOS XR device.",
    hardwareIntegrityValidationFailure: "Hardware Integrity Validation Failure",
    hardwareName: "Hardware Name",
    hardwareSearch: "Hardware Search",
    hardwareSearchHint: "You must enter at least 3 characters to search",
    hardwareTrustAnchorModuleTAmDetails:
      "Tamper-resistant hardware Trust Anchor Module (TAm) to enable advanced cryptographic capabilities, and hardware Root-of-Trust.",
    hardwareType: "Hardware Type",
    hardwareValidation: "Hardware Validation",
    hardwareValidationDescription:
      "Trust Insights will validate hardware measurements against known-good-values.  This includes hardware authenticity validation and cryptographic validation of component serial and part numbers.",
    hardwareVersion: "Hardware Revision",
    hash: "Hash",
    hashChangeDetectedAt: "Hash change detected at ",
    hashCollectionHint:
      "No configuration will be stored, only a hash to monitor changes",
    hashCollectionFrequencyHint: "Minimum collection frequency is 20 minutes",
    hashCollectionMismatchMessage1: "The stored hash does",
    hashCollectionMismatchMessage2:
      "the hash reported by the system. This indicates the device configuration has changed.",
    hashCollectionMismatchWarning:
      "WARNING: A device configuration change has been detected. Investigate further or click ",
    hashCollectionMismatchWarning2:
      " to view the old and new commit IDs associated with the old and new configuration hashes reported by the system.",
    hashCommitID: "Hash Commit ID",
    hashesSeen: "Hashes Seen",
    hashMatches: "Hash Matches",
    haState: "HA State",
    haveCCOAccount: "Have a CCO Account",
    haveCCOAccountDescription:
      "if you need to sign up for a new CCO account. Once your CCO account has been activated, please return to this page.",
    health: "Health",
    healthy: "Healthy",
    help: "Help",
    helpDocs: "Application Help",
    helpSupport: "Help & Support",
    helpWithPurchasing: "Help with Purchasing",
    helpWithThisPage: "Help with this page",
    here: "here",
    hide: "HIDE",
    high: "High",
    highDescription:
      "One or more device interfaces are between 50% and 80% utilization",
    highEntropyRNGDetails:
      "Advanced hardware support for high-quality random number generation using hardware based entropy sources. Requires hardware Trust Anchor Module support. ",
    history: "History",
    home: "Home",
    hostKeyMismatchMsg:
      "The host key for this device has changed and needs to be accepted to permit the device to collect a dossier",
    hostname: "Hostname",
    hour: "Hour",
    hour12: "12 Hour",
    hour24: "24 Hour",
    hourDown: "Hour Down",
    hourly: "Hourly",
    hours: "Hours",
    hourUp: "Hour Up",
    hoverChartNodes: "Hover over sections for more details",
    howLong: "How Long?",
    id: "ID",
    ignore: "Ignore",
    ignored: "Ignored",
    ignoredDevices: "Ignored Devices",
    ignoredDevicesDescription: "List of devices this policy ignores.",
    ignoredDevicesDescriptionEditMode:
      "Update list of devices this policy ignores.",
    ignoredDevicesForRuleDescription:
      "The devices listed below are no longer opted in to collect a hash and consequently will not trigger an alarm when the configuration changes.",
    ignoredDevicesForRuleDescriptionEditMode:
      "Update list of devices this rule ignores",
    ignoreDevice: "Ignore Device",
    ignoredGateway: "Ignored Gateways",
    ignoredGatewayDescription: "List of gateways this policy ignores.",
    ignoredGatewayDescriptionEditMode:
      "Update the list of gateways this policy ignores.",
    ignoreGateway: "Ignore Gateway",
    ignorePrefix: "Ignore Prefix",
    immutableEvidenceChain: "Immutable Evidence Chain",
    immutableEvidenceChainDescription:
      "Trust Insights records trusted measurements to enable an effective audit trail.  Integrity and reporting data cannot be modified after collection and validation.",
    importDevicesRequestProcessing:
      "Please wait. Import devices request is in progress",
    importExport: "Import / Export",
    importingDevices: "Importing Devices",
    importInProgress: "Import In Progress",
    importNewDeviceCount: "New ({count})",
    importPeersFromBgpMon: "Import Peers from BGPMon",
    importRequestProcessing: "This import should complete within 30 seconds.",
    importRequestSent: "Import Request Sent",
    importUpdateDeviceCount: "Modified ({count})",
    inactive: "Inactive",
    incompatibleBrowser: "Incompatible Browser",
    incrementSize: "Increment Size",
    indefinitely: "Indefinitely",
    initialSetupASNPrefix: "Initial Setup of ASN and Prefix Tracking",
    injestingTrafficData: "Ingesting Traffic Data",
    inputASN: "Input ASN Number",
    insights: "Insights",
    insightsCompare: "Device Comparison",
    insightsCompareDescription:
      "These tasks allow you to compare devices and provide steps to resolve any differences.",
    insightsCompareHeader: "Compare Devices",
    insightsCompareSelectDescription:
      "Find and select the device you would like to use as the base for comparision.",
    insightsCompareSubtitle: "Compare device software and hardware",
    instanceIdentifier:
      "This name is used to identify your instance of Crosswork Cloud.",
    int32: "int32",
    int64: "int64",
    integerUptoTenChars: "Must be an integer and 10 characters or less",
    intentBasedPolicies: "Intent Based Policies",
    intentBasedPoliciesDescription:
      "View policies created with a metric objective.",
    interestedInProducts: "Interested in {products}\n\n",
    interface: "Interface",
    interfaceRxTraffic: "Interface Rx Traffic",
    interfaceTxTraffic: "Interface Tx Traffic",
    interfaceCapacity: "Interface Capacity",
    interfaceJumboPrefixAlarmDescription:
      "Receive alerts when a prefix is using more bandwidth than an expected user set threshold.",
    interfaceMonitored: "Interface Monitored",
    interfaceName: "Interface Name",
    interfacePolicyAssociationWarning:
      "Monitored interfaces can only be associated with a single policy. If you choose to continue, any existing interface associations with previous policies will be removed.",
    interfacePolicyLinkRemoved: "Removed Interfaces linked with Policy",
    interfacePolicySuccess: "Interfaces linked with Policy successfully ",
    interfaces: "Interfaces",
    interfaceSaved: "Interface Saved",
    interfaceStatus: "Interface Status",
    interfaceTag: "Interface Tag",
    interfaceTags: "Interface Tags",
    interfaceThresholdRxAlarmDescription:
      "Receive alerts when your received traffic crosses either a high or low user set threshold.",
    interfaceThresholdTxAlarmDescription:
      "Receive alerts when your transmission traffic crosses either a high or low user set threshold.",
    interfaceTrafficAnalysis: "Interface Traffic Analysis",
    interfaceTrafficAnalysisDescription: "",
    interfaceTrafficGroupAnalysis: "Interface Traffic Group Analysis",
    interfaceTrafficGroupAnalysisDescription: "",
    interfaceType: "Interface Type",
    interfaceTypeUpdate: "Interface(s) updated successfully.",
    interfaceUtilization: "Interface {type} Utilization",
    interfaceUtilizationDescription:
      "Use the slider to indicate the minimum and maximum percentage of capacity expected to pass through an interface.",
    interfaceWithCount: "Interface {count}",
    intermediateHop: "Intermediate Hop",
    internal: "Internal",
    internalRouteAnalysis: "Internal Route Analysis",
    internalRouteComingSoon:
      "We are working hard to release enhanced functionality and features to help you monitor routing changes in your internal network and alert you to unintentional changes.",
    internalRoutingAnalytics: "Internal Routing Analytics",
    internalRoutingAnalyticsDescription:
      "Free tier of External Routing Analytics included with Essentials license for Traffic Analysis.",
    invalid: "Invalid",
    invalidASN: "Invalid ASN",
    invalidAsnOrPrefixOrIP: "Invalid ASN, Prefix or IP",
    invalidAsns: "Invalid ASN(s) : {errorListValues}",
    invalidASPaths: "Invalid ASPath/s : ",
    invalidCharacters: "Some characters are not valid for this input",
    invalidCharsAtStartOrEnd: "Name must not start or end with: -_+/@.&",
    invalidCirMultipleValue: "The rate must be a multiple of 1000,000",
    invalidCirValue:
      "The rate must be higher or equal to 10 Mbps and lower or equal to the physical maximum",
    invalidConfirmation: "Invalid Confirmation Link",
    invalidCountryCode: "Must be a valid calling code (1-999)",
    invalidData: "Invalid data",
    invalidDateRange: "Invalid Date Range",
    invalidEmail: "Invalid email address",
    invalidExternalAsn: "Invalid External ASN",
    invalidExternalAsnDesc:
      "This ASN is not valid for external routing analysis",
    invalidExternalPrefix: "Invalid External Prefix",
    invalidExternalPrefixDesc:
      "This prefix is not valid for external routing analysis",
    invalidHostIpv4: "Must be a valid host IP address",
    invalidHostname: "Invalid hostname",
    invalidInteger: "Must be a whole number",
    invalidIp: "Must be a valid IP address",
    invalidIPaddresses: "Invalid IP Address/es : {errorListValues}",
    invalidIpOrDomain: "Must be a valid domain name or IPv4 address",
    invalidIPv4IPv6: "Invalid IPV4/IPV6 address",
    invalidIPv4Length: "Ex: 12,6,32. Max value 32",
    invalidIPv6Length: "Ex: 12,99,128. Max value 128",
    invalidLatitudeValue: "Invalid Latitude value",
    invalidLongitudeValue: "Invalid Longitude value",
    invalidMed: "Invalid MED value",
    invalidMedList: "Invalid MED value(s) : {errorListValues}",
    invalidMicrosoftTeamsWebhook:
      "Invalid Webhook. Create an Incoming Webhook in Microsoft Teams",
    invalidName: "Invalid character(s) : {errorListValues}",
    invalidNameLength: "Name must be between 3-120 characters long.",
    invalidNumber: "Must be a number",
    invalidPeerId: "Invalid Peer ID",
    invalidPeerIds: "Invalid Peer ID/s : {errorListValues}",
    invalidPeerValue: "Invalid Peer Value",
    invalidPhone: "Invalid phone number, must be 8-20 digits",
    invalidPortNumber: "Port number must be between 1 and 65535",
    invalidPositiveNumber: "Must be a positive number",
    invalidPrefix: "Invalid Prefix",
    invalidPrefixOrAsn: "Invalid Prefix or ASN",
    invalidRange: "Must be between {min} and {max}",
    invalidRegex: "Invalid Regex",
    invalidRequest: "Invalid Request",
    invalidResponseFromServer: "Invalid Response from Server",
    invalidROAMaxLength: "ROA Max length must be equal to or between 1 and 128",
    invalidRPSL: "Invalid RPSL",
    invalidS3Id: "Must be between 16 and 128 characters",
    invalidS3Secret: "Must be base64 encoded secret",
    invalidSignature: "Invalid Signature",
    invalidSlackWebhook: "Invalid Webhook. Create an Incoming Webhook in Slack",
    invalidSpecialCharacters: "Can not contain special characters: \", ', `",
    invalidSubmitDesc: "This issue is preventing you from saving",
    invalidSubmitDescPlural: "These issues are preventing you from saving",
    invalidSubmitTitle: "Unable to {action}",
    invalidSubscriptions: "Invalid subscriptions",
    invalidTelemetryPort:
      "Port number must be greater than 1024 and less than 65535",
    invalidWebexWebhook: "Invalid Webhook. Create an Incoming Webhook in Webex",
    inventory: "Inventory",
    inventoryReporting: "Inventory Reporting",
    iosXRVersion: "IOS XR Version",
    ip: "IP",
    ipAddress: "IP Address",
    ipAddresses: "IP Addresses",
    ipfix: "IP Fix",
    ipfixDescription: "",
    ips: "IPs",
    ipV4: "IPv4",
    ipv4Address: "IPv4 Address",
    ipv4Length: "IPv4 Length",
    ipv4PrefixChanges: "IPv4 Prefix Changes",
    ipv4PrefixesToBeAdvertised: "IPv4 Prefixes To Be Advertised",
    ipv4Unicast: "IPv4 Unicast",
    ipv4UnicastMax: "IPv4 Unicast Max",
    ipv4UnicastMin: "IPv4 Unicast Min",
    ipV6: "IPv6",
    ipv6Address: "IPv6 Address",
    ipv6Length: "IPv6 Length",
    ipv6PrefixChanges: "IPv6 Prefix Changes",
    ipv6PrefixesToBeAdvertised: "IPv6 Prefixes To Be Advertised",
    ipv6Unicast: "IPv6 Unicast",
    ipv6UnicastMax: "IPv6 Unicast Max",
    ipv6UnicastMin: "IPv6 Unicast Min",
    irrRpsl: "IRR / RPSL",
    issueDate: "Issue Date",
    issuer: "Issuer",
    isVerified: "Is Verified",
    item: "item",
    items: "items",
    jflow: "J-Flow",
    jflowDescription: "",
    jobTitle: "Job Title",
    joinTheCommunity: "Join The Community",
    jsonConfigFile: "Upload Config File",
    justNow: "Just now",
    key: "Key",
    keyFunctionalitiesInclude: "Key functionalities include:",
    keyId: "Key Id",
    keys: "Keys",
    keysDeleteConfm: "API Key(s) removed",
    kgf: "Known-Good Files",
    kgfBulkManage: "Selected operation will be applied to ",
    kgfCollection: "Global Known-Good File Collections",
    kgfDeleteMsg:
      "The selected {count} files will be removed from your known-good list.",
    kgfSubHeader:
      "Known-good file collections previously imported from devices",
    kgvForPackage: "Known Good Values For Package",
    kgvHash: "KGV Hash",
    kgvRelease: "KGV Release",
    knownCiscoRelease: "Known Cisco Release",
    knownGoodFiles: "Known Good Files",
    knownGoodValuePackage: "Known Good Value Packages",
    knownGoodValuesHash: "Known Good Values Hash",
    knownHash: "Known Hash",
    knownRelease: "Known Release",
    labRouter: "Lab Router",
    large: "Large",
    last: "Last",
    lastActiveAlarm: "Last Active Alarm",
    lastCollection: "Last Collection",
    lastCollectionAttempt: "Last Collection Attempt",
    lastConnectionLoss: "Lost {type} Connection Timestamp",
    lastDossierStatus: "Last Dossier Status",
    lastErrorDossier: "Last Error Dossier",
    lastLogin: "Last Login",
    lastLoginDate: "Last Login Date",
    lastModified: "Last Modified",
    lastModifiedOnByFmt: "Last modified by {by} on {at} ",
    lastName: "Last Name",
    lastReset: "Time Since Last Reset",
    lastResetReason: "Last Reset Reason",
    lastRun: "Last Run",
    lastScan: "Last Scan",
    lastSeen: "Last Seen",
    lastSuccessfulCollection: "Last Successful Collection",
    lastSuccessfulDossier: "Last Successful Dossier",
    lastValidation: "Last Validation",
    lastValidationAttempt: "Last Validation Attempt",
    lastUpdate: "Last Update",
    lastUpdated: "Last Updated",
    lastUserAction: "Last User Action",
    lastXDataFmt: "Last {x} Data",
    latitude: "Latitude",
    launch: "Launch",
    launchAWS: "Launch AWS",
    launchDownloadSite: "Launch Download Site",
    launchFindFile: "Launch Find Files",
    launchKgf: "Launch Known Good Files",
    learnMore: "Learn More",
    legacyDocumentation: "Legacy Documentation",
    legacyPeer: "Legacy Peer",
    legend: "legend",
    license: "License",
    licenseDescription: "License Description",
    licenses: " {license} Licenses",
    licenseTier: "License Tier",
    licenseType: "License Type",
    licensing: "Licensing",
    lineCard: "Line Card",
    linkAsns: "Link ASNs",
    linkCredentialGroupDescription:
      "Link this credential group to the selected devices. Any previously assigned credential groups will be overwritten",
    linkDevices: "Link Devices",
    linkDevicesModalSubTitle:
      "Link this credential group to the selected devices. Any previously assigned credential groups of the same type will be overwritten.",
    linkDevicesToGroupModalSubTitle:
      "Select the device(s) to link to the device group. This action overwrites any previously assigned device groups.",
    linkedDataGateway: "Linked Data Gateway",
    linkedDevices: "Linked Devices",
    linkedPolicy: "Linked Policy",
    linkedTrafficDevices: "Linked Traffic Devices",
    linkedTrustDevices: "Linked Trust Devices",
    linkEndpointToRule: "Link Endpoint to Rule",
    linkFlowDeviceDescription:
      "Link this Data Gateway to the selected Traffic Analytics enabled devices. Only devices capable of linking to a Traffic Analysis CDG appear.",
    linkPeers: "Link Peers",
    linkPeersDesc:
      "Select the peers you wish to link to this policy. If a peer is already linked to a different policy that connection will be overwritten.",
    linkPolicy: "Link Policy",
    linkPrefixes: "Link Prefixes",
    partialFailureError:
      "Partial failure occurred while completing the last action. Please select items and try again.",
    linkPrefixesDesc:
      "Select the prefixes you wish to link to this policy. If  a prefix is already linked to a different policy that connection will be overwritten.",
    linkPrefixesSuccess: "Prefix(es) linked successfully.",
    linkRules: "Link Rules",
    linkTrafficDevices: "Link Traffic Devices",
    linkTrustDeviceDescription:
      "Link this Data Gateway to the selected Trust Insights-enabled devices. Only devices capable of linking to a Trust CDG appear.",
    linkTrustDevices: "Link Trust Devices",
    linkType: "Save & Link {type}",
    loadBalancingEqualShare: "Load Balance - Equal Share",
    loadBalancingEqualShareDescription: "",
    loadBalancingUnEqualShare: "Load Balance - Unequal Share",
    loadBalancingUnEqualShareDescription: "",
    loading: "Loading",
    loadingAsName: "Loading AS Name...",
    location: "Location",
    login: "Login",
    loginForm: "Login Form",
    loginMessage1: "Enter your cisco.com credentials.",
    loginMessage2: "Forgotten your password? ",
    loginMessage3: "Create an Account? ",
    longitude: "Longitude",
    lookingGlass: "Looking Glass",
    lossOfConnection: "Lost {type} Connection",
    lossOfConnectionTrafficDescription:
      "Select the severity of the alarm when a {type} loses SNMP or BGP Connection. ",
    lossOfConnectionTrustDescription:
      "Select the severity of the alarm when a {type} loses SSH Connection.",
    low: "Low",
    macAddress: "MAC Address",
    makeEdits: "Make Edits",
    malformedNetflow: "Malformed Netflow",
    manage: "Manage",
    manageAlarms: "Manage Alarms",
    managedDevices: "Devices managed by this policy",
    manageDevices: "Manage Devices",
    managedGateway: "Gateways managed by this policy",
    manageExistingPeerDevices: "Manage Existing Peer Devices",
    manageExistingPeerDevicesDescription:
      "Import and edit preexisting device peers.",
    manageInterfaces: "Manage Interfaces",
    manageKGF: "Manage Files",
    management: "Management",
    manual: "Manual",
    manufacturer: "Manufacturer",
    manufacturerName: "Manufacturer Name",
    map: "Map",
    markKnown: "Mark Known",
    markKnownModalContent:
      "These selected files will be added to the Known Good List. We will no longer alert you about these files on any collection made on any device in your organization. In order to reverse this action you will need to find and remove them in the Known Good Files feature.",
    markUnknown: "Mark Unknown",
    massDeviceEditPageCaption:
      "All entered values will overwrite existing values on the selected devices.",
    massDeviceEditRequestProcessing:
      "Please wait. Device edit request is in progress",
    massDeviceEditReviewCaption:
      "These devices will have the following values applied. All values in these columns will be overwritten as shown.",
    massDeviceEditSuccess: "Device(s) updated successfully.",
    massEditDisableTooltip: "You can edit up to 50 devices in one operation",
    massUserDeleteSuccess: "User(s) removed successfully",
    massUserEditPageCaption:
      "All entered values will overwrite existing values on the selected users.",
    massUserEditRequestProcessing:
      "Please wait. User edit request is in progress",
    massUserEditReviewCaption:
      "These users will have the following values applied. All values in these columns will be overwritten as shown.",
    massUserEditSuccess: "User(s) updated successfully.",
    match: "Match",
    matched: "Matched",
    matchingPackagesSubheader:
      'Packages matching "{searchString}" on {deviceName}',
    max: "Max",
    maximumLength: "Maximum Length",
    maximumPrefixLimitReached:
      "Exceeds maximum prefix limit. To proceed, please deselect {exceedingBy} prefixes.",
    maxIpv4PrefixLength: "Max IPv4 Length",
    maxIPv4Validation: "Must be > IPv4 Unicast Min",
    maxIpv6PrefixLength: "Max IPv6 Length",
    maxIPv6Validation: "Must be > IPv6 Unicast Min",
    maxLength: "Must be {max} characters or less",
    maxLengthLabel: "Max Length",
    maxLimitReached:
      "Reached limit of {limit}. Please adjust filter to narrow the results",
    maxNumberPrefixes: "Max Number Prefixes",
    maxPathLength: "Max Path Length",
    maxPeerCountValidation: "Must be > Resolve",
    maxPeers: "Peers to Trigger",
    maxValue: "Max value can be {max}",
    maxValueHint: "Must be ≤ {max}",
    MD5: "MD5",
    MED: "MED",
    med: "Med",
    medium: "Medium",
    megabitPerSecond: "Mbps",
    mem: "Memory",
    memberOf: "Member Of",
    memoryInPercent: "Memory in %",
    memoryUsage: "Memory Usage",
    message: "Message",
    methods: {
      delete: "DELETE",
      get: "GET",
      invalid: "invalid",
      post: "POST",
      put: "PUT",
      patch: "PATCH"
    },
    methodType: "Method Type",
    migrationInProgress: "Migration is in progress",
    migrationLinkExpired:
      "Migration was canceled or the confirmation link has expired.",
    min: "Min",
    mine: "Mine",
    minimum8Characters: "Minimum of 8 characters",
    minimumLength: "Minimum Length",
    minLength: "Must be {min} characters or more",
    minNumberPrefixes: "Min Number Prefixes",
    minPathLength: "Min Path Length",
    minPeerCountValidation: "Must be ≥ 0",
    minPeers: "Peers to Resolve",
    minute: "Minute",
    minuteDown: "Minute Down",
    minutes: "Minutes",
    minuteUp: "Minute Up",
    minValueHint: "Must be ≥ {min}",
    mismatch: "Mismatch",
    mismatchChangesDetected: "Mismatch and changes detected",
    mismatched: "Mismatched",
    mismatchedFiles: "Mismatched Files",
    mismatchedFilesDescription:
      "Detects files with signatures that do not match known Cisco IOS XR artifacts of user defined Known Good Values (KGV).",
    mismatchFilesNotFound:
      "There are no mismatched or changed files detected for this device",
    mismatchKnownUnknown:
      "This file has been validated by Cisco on {date}. It is no longer considered unknown and will not be flagged in the future.",
    mismatchStatus: "Mismatch Status",
    missing: "Missing",
    missingBgpFeed: "Missing BGP Feed",
    missingFeatures:
      "You can upgrade your subscription tier to view additional features. For more information about purchasing please go  ",
    missingGateway: "[Missing Gateway Name]",
    missingInterface: "[Missing Interface Name]",
    missingLookbackType:
      "You can upgrade your subscription tier to view additional {type} data. For more information about purchasing please go  ",
    missingLookbackTypes:
      "You can upgrade your subscription tier to view additional {type}. For more information about purchasing please go  ",
    missingPrefix: "[Missing Prefix]",
    missingROA: "Missing ROA",
    missingRPSL: "Missing RPSL",
    missingSnmpFeed: "Missing SNMP Feed",
    mix: "mix",
    mode: "Mode",
    model: "Model",
    modification: "Modification",
    modified: "Modified",
    modify: "Modify",
    modifyDevices: "Modify Devices",
    modifyGateways: "Modify Gateways",
    modifyingPeers: "Modifying Peers",
    modifyingPrefixes: "Modifying Prefixes",
    modifySearch: "Modify Search Criteria",
    mon: "Mon",
    monitor: "Monitor",
    monitorAsns: "Monitor ASNs",
    monitorAsnSuccess: "ASN(s) monitored successfully.",
    monitorDevice: "Monitor Device",
    monitored: "Monitored",
    monitoredASN: "Monitor ASN",
    monitoredDevices: "Monitored Devices",
    monitoredDnsRootServers: "Monitored DNS Root Servers",
    monitoredGateway: "Monitored Gateways",
    monitorGateway: "Monitor Gateway",
    monitorSave: "Monitor ASN & Save",
    month: "Month",
    monthly: "Monthly",
    months: "Months",
    more: " + {count} more",
    moreInformation: "More Information",
    moreInformationTiers: "More information on tier offerings can be found ",
    moreRecords: " more...",
    moreThan500: "500+",
    mostRecentConnectivityStatus: "Most Recent Connectivity Status",
    mostRecentStatus: "Most Recent Status",
    moveControl1: "Move Control 1",
    moveControl2: "Moved Control 2",
    multiAsnEntry: "Enter one or more AS numbers separated by commas.",
    multiTrafficInstructions:
      "Click and drag bars to modify data summary. Double click to clear selection",
    multiVendor: "Multi Vendor",
    multiVendorDescription: "",
    must: "must",
    mustBeNumber: "Must be a number",
    mustBePositive: "Must be a number ≥ 0",
    myPeer: "My Peer",
    myPeerCount: "My Peer Count",
    myPeers: "My Peers",
    mySettings: "My Settings",
    mySubscriptions: "My Subscriptions",
    na: "n/a",
    name: "Name",
    navigateToCredential: "- Credentials (Configure > Credentials)",
    navigateToDataGateway: "- Data Gateways (Configure > Data Gateways)",
    navigateToDeviceGroups: "- Device Groups (Configure > Device Groups)",
    needed: "Needed",
    needMoreHelp: "Need more help?",
    needMoreHelpDescription:
      "For help with AWS purchases, please contact support through email at ",
    needsAttention: "Needs Attention",
    needToCreateCCOAccount: "New to Crosswork Cloud? Create a CCO Account ",
    needToCreateCCOAccount2:
      "New to Crosswork Cloud or coming from BGPMon? Sign up for Crosswork Cloud ",
    netBlock: "NetBlock",
    netflowDescription: "",
    netflowGetHelp:
      "If green check boxes appear next to all items, indicating setup is complete, and you continue to experience issues, please contact us at ",
    netflowSampler: "Netflow Sampler",
    netflowSourceAddress: "Netflow Source Address",
    netflowv5v9: "Netflow v5,v9",
    netName: "NetName",
    netstreamSwitch: "Netstream Mapping",
    netstreamSwitchNote:
      "Note: This setting enables Netstream ifIndex mapping for 3rd party Devices only.",
    network: "Network",
    networkDevice: "Network Device",
    networkDeviceChanges: "Network Device Changes",
    networkFlow: "Network Flow",
    networkInsights: "Network Insights",
    networkInsightsTier: "Network Insights - External Route Analysis {tier}",
    networkInterfaces: "Network Interfaces",
    never: "Never",
    new: "New",
    newAccountRequest: "New Account Request",
    newAsPathEdgeDescription:
      "Detects unexpected AS path changes due to transient BGP AS relationships.",
    newDownstreamAsPeers: "New Downstream AS Peers",
    newEndpoint: "New Endpoint",
    newFile: "New File",
    newFrequency: "New Frequency",
    newHashTimestamp: "New Hash Timestamp",
    newKeyCreated: "New Key Created",
    newKeyDescription:
      "This is your API Key. It will only be displayed this one time. A new key will have to be created if this key is lost.",
    newPassword: "New Password",
    newPeerings: "New Peerings",
    newPolicy: "New Policy",
    newPrefixCount: "New Prefix Counts",
    newSHAFingerprint: "New SHA 1 Fingerprint",
    newSHATimestamp: "New SHA 1 Timestamp",
    newState: "New State",
    newStatus: "New Status",
    newTags: "New Tags",
    newToCrossworkCloudMessage:
      "New to Crosswork Cloud? Sign up for a FREE account",
    newTokenCreated: "New Token Created",
    newTypeEndpoint: "New {type} Endpoint",
    newUpstreamAsPeers: "New Upstream AS Peers",
    newXRCommitID: "New XR Commit ID",
    nextDevice: "Next Device",
    nextMonth: "Next Month",
    nextPage: "Next Page",
    nextScheduledCollection: "Next Scheduled Collection",
    nextScheduledRun: "Next Scheduled Run",
    nextSteps: "Next Steps",
    niNotLicensed:
      "Provides enhanced functionality and features for the hardware and software running in your network. Click Request Trial below to try External Routing Analysis for a trial period",
    niServiceName: "Network Insights - External Route Analysis",
    no: "No",
    noActiveAlarms: "No Active Alarms",
    noActiveTrials: "No Active Trials Found",
    noAdditionalRules: "No other rules available",
    noAsnsSelected: "No ASNs Selected",
    noAuthNoPriv: "noAuthNoPriv",
    noAvailableTrials: "No Available Trials Found",
    noCCOAccount1: "If you do not have a CCO account, create one ",
    noCCOAccount2: " and return to this page.",
    noChanges: "There are no changes to save",
    noChangesFound: "No changes found",
    node: "Node",
    nodeName: "Node Name",
    nodes: "Nodes",
    noDevicesAvailable: "No Devices Available",
    noIgnoredPrefixesDesc:
      "No prefix balancing recommendations are currently ignored.",
    noItemsInQueue: "No Items in Queue",
    noItemsMatch: "No items match your search",
    noKGVData: "No KGV Data",
    noMatch: "Mismatch",
    none: "None",
    nonPeers: "Non-Peers",
    nonZeroIntegerUptoTenChars:
      "Must be a non-zero integer and 10 characters or less",
    noOfBgpPrefixes: "# Of BGP Prefixes",
    noOfInterfaces: "# Of Interfaces",
    noPrefixesForProvidedASN: "No Prefixes found for ASN {asn}",
    noResponse: "No response",
    noResultsFound: "No results found",
    noReverse: "This action can not be reversed.",
    normal: "Normal",
    normalDescription: "All device interfaces are below 50% utilization",
    noRPSL: "No RPSL",
    northBound: "North Bound",
    noDossiersToCompare: "No Dossier Difference. ",
    noRunningApplicationMsg:
      "No running application. Link devices to start their respective applications.",
    noSelectedTrustDevices: "Selected device(s) are not Trust devices",
    noSpecial: "No special characters allowed",
    notAfter: "Not After",
    notApplicable: "Not applicable",
    notBefore: "Not Before",
    notConnected: "Not Connected",
    noTemplate: "No Template",
    notes: "Notes",
    notesMaxLength: "Notes cannot exceed than {max} characters.",
    notesSaved: "Notes Saved",
    notFound: "Not Found",
    notFoundDesc: "[404] This page does not exist 😔",
    notFoundFmt: "{thing} not found",
    notificationEndpoints: "Notification Endpoints",
    notifications: "Notifications",
    notificationSetup: "Notification Endpoint Setup",
    notificationSubTitle:
      "Configure the notifications to send after a rule is triggered.",
    notificationType: "Notification Type",
    noTimeSummaryError: "Time range not provided in response",
    notLicensed: "Not Licensed",
    notLicensedDetailMsg:
      "You are currently not licensed to perform this action. Click purchase below to see your upgrade options.",
    notPresent: "Not Present",
    notReachable: "Not reachable",
    notSupported: "Not Supported",
    notSure: "Not Sure",
    noValueFound: "No value found",
    noVariationFromKGV: "No variation from KGV ",
    npus: "NPUs",
    null: "--",
    numberOfDifferences: "Number of differences",
    numberOfFiles: "Number of files",
    numberOfInterfaces: "Number Of Interfaces",
    numberOfNodes: "Number of Nodes",
    numberOfPeers: "# Peers",
    numberOfPrefixes: "Number of Prefixes",
    numberOfDevices: "Number of Devices",
    object: "Object",
    observed: "Observed",
    observedAsn: "Observed ASN",
    observedFailures: "Observed Failures",
    observedFiles: "Observed Files",
    observedFilesDossierSubHeader:
      "Select a dossier collection of the files you would like to import",
    observedFilesOnDeviceSubHeader:
      "These files were collected from Device {device} at {date} ",
    observedFilesSubHeader:
      "Select a device to import observed files into the Known Good files signature",
    observedFilesSubtitle:
      "Analysis of files seen running on your device from this software package. ",
    observedHash: "Observed Hash",
    observedHWComponentsWithSUDI: "Observed Components with a SUDI",
    observedMismatchedFiles: "Observed Mismatched Files",
    observedPathsNewASEdges: "Observed Paths with New AS Edges",
    observedPrefix: "Observed Prefix",
    observedRunning: "Observed Running",
    observedUnknownFiles: "Observed Unknown Files",
    observing: "Observing",
    observingPeers: "Observing Peers",
    off: "Off",
    offline: "Offline",
    ofInterfaceUtilization: "of Interface Utilization",
    oldSHAFingerprint: "Old SHA 1 Fingerprint",
    oldViolationPeerNoData: "No updates within the past 6 months found.",
    oldViolationPeerNotShownFmt:
      "Not displayed: {old} violation peers that have updates older than {numDays} day(s).",
    oldXRCommitID: "Last Accepted XR Commit ID",
    on: "On",
    once: "Once",
    onDisk: "Ondisk",
    onDiskMisMatch: "Ondisk Mismatch",
    onDiskHash: "Ondisk Hash",
    onDiskRuntime: "Ondisk & Runtime",
    oneDay: "1 Day",
    oneHour: "1 Hour",
    oneMinute: "1 Minute",
    oneMonth: "1 Month",
    oneWeek: "1 Week",
    oneYear: "1 Year",
    online: "Online",
    onlyAlphaNumeric: "Only alphanumeric characters",
    onlySubscribedPrefixes: "Only subscribed Prefixes",
    onPolicyName: "on {policy}",
    openCase: "Open a Support Case",
    openCrossworkCloud: "Open Crosswork Cloud",
    operatorStatus: "Operator Status",
    optimizationMethod: "Optimization Method",
    optimizePeer: "Optimize Peer",
    optional: "Optional",
    optionalNotificationSetup: "Optional notification setup",
    optionTemplates: "Option Templates",
    optOut: "Opt-Out",
    orderId: "Order: {order}",
    organization: "Organization",
    orgName: "Organization Name",
    orgNameUpdate: "Update organization and preferences",
    orgNameUpdateFail: "Failed Organization update and preferences",
    orgNameUpdateHint: "Update your new organization name",
    orgSetup: "Enter a name for your organization.",
    originASN: "Origin ASN",
    originASNFilter: "Origin ASN Filter",
    originASNs: "Origin ASNs",
    originatingPrefixes: "Originating Prefixes",
    origins: "Origins",
    orLabel: "or",
    osType: "OS Type",
    osVersion: "OS Version",
    other: "Other",
    otherIssue: "Other Issue",
    otherPeerPaths: "Other Peer Paths",
    otherRegion: "Other Region",
    outOfCompliance:
      "You have surpassed the number of {type} supported for your subscription tier. For more information about purchasing please go ",
    outsideUSA: "Outside USA",
    overview: "Overview",
    owner: "Owner",
    p95: "P95",
    package: "Package",
    packageInsights: "Package Insights",
    packageInstallHash: "Package Install Hash",
    packageIntegrity: "Package Integrity",
    packageIntegrityUnknownBanner:
      "We can not inspect the integrity of packages with fingerprinting disabled. Enable fingerprinting by following the instructions found ",
    packageIntegrityUnknownTooltip:
      "With fingerprinting disabled we are not able to fully inspect the integrity of packages. Please enable fingerprinting by following the instructions found ",
    packageMatches: "Package Matches",
    packageName: "Package Name",
    packageRuntimeHash: "Package Runtime Hash",
    packages: "Packages",
    packageSearch: "Package Search",
    packageSearchHint:
      "You must enter at least 3 characters to search. You can use operators AND and OR.",
    packageSignatureAnalysis: "Package Signature Analysis",
    packageStatusNoData:
      "There are no packages detected in uncommitted or deactivated state for this device",
    packageValidation: "package validation",
    packageValidationDescription:
      "Detects when a software package has an unknown installation or running signature.",
    packageValidationFailure: "Package Validation Failure",
    packageVersion: "Package Version",
    pagerDutyDescription:
      "Configure a notification, such as an alarm, to be sent to a Pager Duty account.",
    parentAggregateDescription:
      "Detects when the next most specific prefix gets larger or smaller and indicates when there is a potential impact to a nonadvertised prefix due to a summary prefix change.",
    parentAggregateDescriptionMyPeers:
      "Detects when the next most specific prefix gets larger or smaller and indicates when there is a potential impact to a nonadvertised prefix due to a summary prefix change on my peers.",
    parentRoute: "Parent Route",
    partial: "Partial",
    partiallyAvailable: "Partially Available",
    partiallyDeprecated: "Partially Deprecated",
    partialMatch: "Partial Match",
    password: "Password",
    passwordError: "Password error",
    passwordMismatch: "Password mismatch",
    past24Hrs: "Past 24 Hours",
    path: "Path",
    pathLengthSort: "Path Length Sort",
    pathParams: "Path Parameters",
    pathTopology: "Path Topology",
    paused: "Paused",
    pausedPrefixes: "Paused Prefixes",
    pausedUntil: "Paused Until",
    pauseDuration: "Pause Duration",
    pausePrefix: "Pause Prefix",
    pausePrefixesSuccess: "Prefix(es) successfully paused.",
    paymentIssues: "Payment Issues",
    paymentSupport: "Payment Support",
    paymentSupportSubHeader: "Please write to us if you have payment issues",
    payPerUse: "Pay-Per-Use",
    pdCardType: "Platform Dependent Card Type",
    peak: "Peak",
    peer: "Peer",
    peerActivatedSuccess:
      "Your peer has been successfully enabled, but it might take up to 30 minutes for the peer to connect and for data to appear in the system. The peer status remains Unknown until the process completes.",
    peerAs: "Peer AS",
    peerASN: "Peer ASN",
    peerCount: "Peer Count",
    peerCountry: "Peer Country",
    peerDeactivated: "Peer Disabled successfully",
    peerDeletedSuccess: "Peer(s) removed successfully.",
    peerDeviceBGPUpdateHistory: "Peer Device BGP Update History",
    peerDeviceBGPUpdateHistoryDescription:
      "View relevant peer BGP update details such as peer AS, prefixes, AS routing path, communities path, BGP update type, and so on in a specified timeframe.",
    peerDeviceDetails: "Peer Device Details",
    peerDeviceDetailsDescription:
      "View device peer details such as peer information, alarms, and related BGP updates.",
    peerDownDescription:
      "Detects when the BGP peering session between the Cisco peer and your peer is not in an established state.",
    peerDownExpected: "The Peer is expected to be enabled",
    peerFilter: "Peer Filter",
    peerId: "Peer ID",
    peerImportAlreadyComplete: "Import Already Completed",
    peerImportAlreadyCompleteDetail:
      "You have already used this confirmation link for an import and your peers should be showing",
    peerImportProgress: "Peer Import In Progress",
    peerImportProgressDetail1:
      "You previously requested a import from the BGPmon account ",
    peerImportProgressDetail2:
      ". To complete it please click the confirmation link sent to that address or restart your migration below.",
    peerImportSuccessDetail:
      "Peers successfully imported from BGPmon account {email}. To edit the imported peers, you must use Crosswork Network Insights.",
    peerImportSuccessDetail1:
      "Optionally you can add a policy to your imported peers using the button below. A Peer Policy allows you to setup notifications regarding your peer such as your peer going down.",
    peerImportSuccessful: "Peer Import Successful",
    peeringFeatures: "Peering Features",
    peeringState: "Peering State",
    peeringType: "Peering Type",
    peerIp: "Peer IP",
    peerList: "Peer List",
    peerMigrationConfirm:
      'You have requested migration of peers from BGPmon to Network Insights. After the migration completes successfully, the device(s) will appear in the "Peers" tab of Network Insights.',
    peerMigrationDetail:
      'When you click Request, a confirmation email is sent to the BGPmon account you enter if it exists. To complete the transfer, someone must click the approval link within the email. After the transfer is complete, the devices appear in the "Peers" tab within Network Insights, and you can activate them for use.',
    peerMigrationRequestSubHeader:
      "Send request for peer migration to Network Insights",
    peermon: "Peermon",
    peerPolicy: "Peer Policy",
    peerProspecting: "Peer Prospecting",
    peerProspectingDescription: "Peer Prospecting Description",
    peers: "Peers",
    peersAdvertising: "Peers Advertising",
    peerSavedSuccess: "Peer(s) saved successfully.",
    peersIncomplete:
      "Some peers may not be listed. You can enter them manually",
    peersWithCount: "Peers ({count})",
    peerUnlinkSuccess: "Peer(s) unlinked form Policy",
    pending: "Pending",
    pendingCerts: "Certificate Pending",
    pendingChangesConfirmStep:
      "Review the pending changes. Click Submit to apply these changes or click Previous to go back and make changes.",
    pendingFindPrefixMatch: "Attempting to find prefix",
    percent: "%",
    periodic: "Periodic",
    permanentlyRemoved: "Permanently Removed",
    permissionDenied: "Permission Denied",
    permittedDevices: "Permitted Devices",
    permittedPrefixes: "Permitted Prefixes",
    phone: "Phone",
    phoneNumber: "Phone Number",
    physicalMem: "Physical Memory",
    piCardType: "Platform Independent Card Type",
    platform: "Platform",
    platformAsr9000: "ASR 9000v",
    platformNcs540L: "NCS 540 (LNT)",
    platformNcs8000: "8000 Series",
    pleaseDoOneOfTheFollowing: "Please do one of the following:",
    pluggableOptics: "Pluggable Optics",
    plusNMore: "+ {n} more…",
    policies: "Policies",
    policiesDeleted: "Policies removed successfully",
    policiesToCreate: "Policies to Create ",
    policiesWithCount: "Policies ({count})",
    policy: "Policy",
    policyAssociationRemoveConfirm:
      "{policy} association with {type} {value} will be removed. There might be other alarms in this policy. Are you sure ?",
    policyCloned: "Policy Duplicated",
    policyCreated: "Policy Created",
    policyDataSubheader: "Data included in this policy",
    policyDeleted: "Policy removed successfully",
    policyDevicesSubtitle: "Devices attached to this Policy",
    policyDonut: "Policy",
    policyInterfacesSubtitle: "Interfaces attached to this Policy",
    policyName: "Policy Name",
    policyNotCloned: "Policy was not duplicated",
    policyNotDeleted: "Policy was not deleted",
    policyNotificationEndpoints: "Policy Notification Endpoints",
    policyProgressSaved: "Policy Progress Saved",
    policySaved: "Policy Saved",
    policyToAdd: "Select Policy Type to Add",
    policyType: "Policy Type",
    policyTypeDescription: "Select the policy type",
    policyTypeToAdd: "Select a policy type to add.",
    policyUnlinked: "Policy Unlinked",
    policyUpdated: "Policy Updated with new Upstream ASN(s)",
    policyViolationNotifcation: "Optional Policy Violation Notifications",
    policyViolationsOccurring: "Policy violations occurring now",
    polling: "Polling",
    pollingInterval: "Polling Interval",
    port: "Port",
    postAcceptTerms: " of the Cisco Cloud Offer.",
    preAcceptTerms: "I accept the ",
    preDataGatewayInstallerText:
      "Data shared with the cloud relies on data gathered from Crosswork Data Gateway. You must first install Crosswork Data Gateway before you       can start using Trust Insights or Traffic Analysis. You can download latest Cisco Crosswork Data Gateway from the Cisco Software Download site.",
    prefix: "Prefix",
    prefixRxTraffic: "Prefix Rx Traffic",
    prefixTxTraffic: "Prefix Tx Traffic",
    prefixAddedPolicy: "Prefix added with policy - {policy}",
    prefixAdvertisementDescription:
      "Detects when a prefix is advertised. It can help indicate a route leak or unexpected change for a configured prefix.",
    prefixAdvertisementDescriptionMyPeers:
      "Detects when a prefix is advertised on my peers. It can help indicate a route leak or unexpected change for a configured prefix.",
    prefixAdvertisementExpected: "This prefix is not expected to be advertised",
    prefixBGPData: "Prefix BGP Updates",
    prefixBGPUpdateHistory: "Prefix BGP Update History",
    prefixBGPUpdateHistoryDescription:
      "View relevant prefix BGP update details such as peer AS, associated AS routing path, communities path, BGP update type, and so on in a specified timeframe.",
    prefixCentric: "Prefix Centric",
    prefixCount: "# Prefixes",
    prefixCounts: "Prefix Counts",
    prefixDetailDescription:
      "View Prefix details such as relevant BGP updates, associated alarms, ROAs, RPSL, and so on.",
    prefixDetails: "Prefix Details",
    prefixEditSuccess: "Prefix updated successfully.",
    prefixes: "Prefixes",
    prefixesAssociated: "Prefixes Associated with ",
    prefixesEligibleForSetup:
      "{prefixes} Prefixes Found, {eligiblePrefixes} Eligible for Setup",
    prefixesFoundNotSubscribed:
      "{prefixes} Prefixes Found, {notSubscribed} Not Subscribed",
    prefixesRoutedThroughPeering: "Prefixes Routed through Peering",
    prefixesToSubscribe: "Prefixes to Subscribe ",
    prefixesUploadSuccess: "Prefix(es) uploaded successfully.",
    prefixesWithActiveROA: "Prefixes with Active ROA",
    prefixesWithCount: "Prefixes ({count})",
    prefixesWithExpiringROA: "Prefixes with Expiring ROA",
    prefixesWithMissingROA: "Prefixes with Missing ROA",
    prefixesWithNoValidRPSL: "Prefixes with No Valid RPSL",
    prefixesWithNoWhoisRIR: "Prefixes with No Whois/RIR",
    prefixesWithRPSL: "Prefixes with RPSL",
    prefixesWithValidROA: "Prefixes with Valid ROA",
    prefixesWithValidRPSL: "Prefixes with Valid RPSL",
    prefixExpectedROA: "Expected ROA records",
    prefixFilter: "Prefix Filter",
    prefixIgnored: "Prefix Ignored",
    prefixLookingGlass: "Prefix Looking Glass",
    prefixLookingGlassDescription:
      "View Prefix AS peers, AS paths, communities, and last modification timestamps.",
    prefixMask: "Prefix Mask",
    prefixOverview: "Prefix Overview",
    prefixPolicy: "Prefix Policy",
    prefixPolicyDeleted: "Prefix Policy Removed",
    prefixReviewMsg:
      "Changes will be applied to all prefixes you selected. If you overwrite policies on which there are existing alarms, those alarms are deleted and are not recoverable.  Use caution because you cannot undo this operation.",
    prefixState: "Prefix State",
    prefixTag: "Prefix Tag",
    prefixTopologyDescription:
      "Trace the routes of a prefix from origin through destination ASNs",
    prefixTopologyHistory: "Prefix Path Topology",
    prefixTrackingResumed: "Prefix Tracking Resumed",
    prefixTrafficAnalysis: "Prefix Traffic Analysis",
    prefixTrafficAnalysisDescription: "",
    prefixTrafficGroupAnalysis: "Prefix Traffic Group Analysis",
    prefixTrafficGroupAnalysisDescription: "",
    prefixTrialUsage: "Prefix Trial Usage",
    prefixType: "Prefix Type",
    prefixUsage: "Prefix Usage",
    prefixUtilization: "Prefix Utilization",
    prefixUtilizationDescription:
      "Use the slider to indicate the maximum percentage of total capacity any single prefix can use on the interface",
    prefixWithdrawalDescription: "Detects when a peer has withdrawn a prefix.",
    prefixWithdrawalDescriptionMyPeers:
      "Detects when my peer has withdrawn a prefix.",
    prefixWithdrawnExpected: "This prefix should be advertised",
    prefixWithdrawnObserved: "This prefix has been withdrawn",
    preGenericMissingFeatures: "There are features you are missing. ",
    premier: "Premier",
    premiumAlarms: "Premium Alarms",
    presales: "Presales",
    previewChanges: "Preview Changes",
    previousDevice: "Previous Device",
    previousMonth: "Previous Month",
    previousPage: "Previous Page",
    price: "Price",
    privacyProtocol: "Privacy Protocol",
    privacyPwd: "Privacy Password",
    privacyPwdMismatch: "Privacy password mismatch",
    privacyStatement: "Privacy Statement",
    privateAsn: "Private ASN",
    proceed: "Proceed",
    processing: "Processing",
    processingBgpUpdates: "Processing BGP Updates",
    processIntegrityMeasurementDetails:
      "IOS XR supports a runtime kernel log of processes executed since boot time. Integrity is measured in unique hashes, which are matched to known-good-values (KGVs) published from the IOS XR Release process.",
    processIntegrityValidation: "Process Integrity Validation",
    processIntegrityValidationDescription:
      "Where available, Trust Insights validates runtime process signatures against known-good-values collected in the IOS XR build and release process.",
    processIntegrityValidationDetails:
      "IOS XR supports a runtime kernel log of processes executed since boot time. Integrity is measured in unique hashes, which are matched to known-good-values (KGVs) from the IOS XR Release process. Validation adds recorded digital signatures when available from signed code artifacts.",
    productFamily: "Crosswork Cloud",
    productFeedback: "Product Feedback",
    productFeedbackModalDesc:
      "We’d like to hear what you think. Please complete the form below and we’ll get back to you as quickly as possible.",
    productFeedbackModalTitle: "Submit Product Feedback",
    products: "Products",
    productsOfInterest:
      "Select the Crosswork Cloud products you are interested in purchasing:",
    productType: "Product Type",
    prohibitedGroup: "Prohibited Group",
    prohibitedIpPfxRecordCountMsg:
      "Only reporting first 1,000 prefixes of {actualCount}",
    prohibitedIpPrefixDescription:
      "Detects if a monitored peer has a prohibited prefix in the public IP routing space installed in its RIB or the monitored peer is forwarding it.",
    project: "Project",
    projectTitle: "Cisco Crosswork Cloud",
    prospect: "Prospect",
    prospectIgnored: "Prospect Ignored",
    prospectIncluded: "Prospect Included",
    provideFeedback: "Provide Feedback",
    provider: "Provider",
    providers: {
      oneid: "OneID"
    },
    publicExternal: "Public External",
    publicKey: "Public Key",
    publicKeyFailure: "Public key failure",
    punchlist: "Punchlist",
    purchase: "Purchase",
    purchaseCrossworkCloud: "Purchase Crosswork Cloud Products",
    purchaseDate: "Purchase Date",
    purchasedSeparately: " (Must be Purchased Separately)",
    purchaseHistory: "Purchase History",
    purchaseInProgress:
      "Please check the Licensing page to view latest order status",
    purchaseNoOrderId: "No Order Information found",
    purchaserEmail: "Purchaser Email",
    purchaserFirstName: "Purchaser First Name",
    purchaserLastName: "Purchaser Last Name",
    purchaseSubscription: "Purchase Subscription",
    purchaseTrustSubscription1:
      "In order to claim a Crosswork Trust Insights Voucher from a device, you must first purchase a Trust Insights Essentials Subscription. Please contact ",
    purchaseTrustSubscription2: " for more information. ",
    purchasingHelp1:
      "For customers who wish to purchase via AWS Marketplace Payment Services, we support prepaid monthly and annual transactions.",
    purchasingHelp2:
      "The AWS Marketplace Payment Service supports both Payment Card transactions as well as AWS Marketplace direct invoicing.",
    purchasingHelp3:
      "For customers who wish to purchase directly from Cisco or Partners via traditional channels or where your transaction not permitted via AWS Marketplace, please open a Sales Request below and we will help resolve your questions.",
    purchasingHelp4:
      "If there are issues with Payment transactions contact us via Payment Support. ",
    purchasingHelp5:
      "If you need help to purchase and obtain a subscription ID from Cisco, please click Sales Support.",
    purchasingSupport: "Purchasing Support",
    quality: "Quality",
    queryParams: "Query Parameters",
    queryPrefix: "Query Prefix",
    queue: "Queue",
    queueDepth: "Queue Depth",
    QueueFull: "Queue Full",
    quickSearch: "Quick Search",
    rangeHighBps: "Range High (bps)",
    rangeLowBps: "Range Low (bps)",
    rate: "Rate",
    ratio: "Ratio",
    reason: "Reason",
    receivingNetflow: "Receiving Netflow",
    recommendations: "Recommendations",
    recommended: "Recommended",
    redirect: "Redirecting...",
    refresh: "Refresh",
    refused: "Refused",
    region: "Region",
    registrationErrorOccured:
      "An error occurred while processing registration request.",
    registrationFile: "Registration File",
    relatedThing: "Related {thing}",
    release: "Release",
    releaseNotes: "Release Notes",
    relevantBgpUpdates: "Relevant BGP Updates",
    relevantPrefixes: "Relevant Prefixes",
    remaining: "Remaining",
    remainingExtensions: "Remaining extensions(",
    remainingUses: "Remaining Uses",
    remove: "Remove",
    removing: "Removing",
    removeAsnFromRule: "Remove ASN from Policy",
    removeAsnFromRuleDescription:
      "Action will remove this ASN from the policy and rule that triggered this alarm",
    removeAssociatedEndpoints:
      "Endpoints associated with this rule will also be removed",
    removeAWSSubscriptions:
      " If you decide to unsubscribe an AWS Subscription, those should be manually removed from AWS Marketplace.",
    removed: "Removed",
    removedDevices: "Removed Devices",
    removedKGF: "Known Good Files removed successfully",
    removedOn: "Removed On",
    removePeerFromPolicy: "Remove Peer from Policy",
    removePeerFromRule: "Remove Peer from Policy",
    removePrefixFromRule: "Remove Prefix from Policy",
    removePrefixFromRuleDescription:
      "Action will remove this Prefix from the policy and rule that triggered this alarm",
    removeTableSettings: "Remove Table Settings",
    renewalTerm: "Renewal Term",
    repeatable: "Repeatable",
    replaceable: "Replaceable",
    report: "Report",
    reportConfigureSuccess: "Report configured successfully",
    reportCreated: "Report Created",
    reportCreation: "Report Creation",
    reportDeleteSuccess: "Report deleted successfully",
    reportFmtsuccessfully: "Report {action} successfully",
    reportingFeatures: "Reporting Features",
    reportingPeers: "Reporting Peers",
    reportingPeersNumber: "# Reporting Peers",
    reportingPrefixes: "Reporting Prefixes",
    reportName: "Report Name",
    reportNameHint: "Name of the report to be generated",
    reportNotificationEndpoints: "Report Notification Endpoints",
    reports: "Reports",
    reportStatus: "Report Status",
    reportSubHeader:
      "Manage reports of interest to deliver daily insights into your network traffic",
    reportSubmittedRegenrate: "Request submitted to regenerate report",
    reportsWithCount: "Reports ({count})",
    reportTrigger: "Report Trigger",
    reportType: "Report Type",
    request: "Request",
    requestATrial: "Request a Trial",
    requested: "Requested",
    requestedFmt: "Requested {time} ago",
    requestFree: "Request Free",
    requestFreeAccount: "Request Free Account",
    requestInProgress: "Your request should complete shortly.",
    requestObject: "Request Object",
    requestObjectHeaders: "Request Object Headers",
    requestProcessing: "Please wait. We are processing the request.",
    requestSubmitSuccess: "Request submitted successfully",
    requestTrial: "Request Trial",
    required: "Required",
    requiredDeviceOrGroup: "A Device or Device Group is required",
    requiredFields: "Please provide the values in required fields",
    requiredLicenses: "Required Licenses",
    resellers: "Resellers",
    resendVerfnCode: "Resend Verification Code",
    reserved: "Reserved",
    reservedForASPoolTransition: "Reserved for AS Pool Transition",
    reservedForDocumentation: "Reserved for documentation and sample code",
    reservedForPrivateUse: "Reserved for private use",
    reservedForRPKI: "Reserved for RPKI unallocated space invalidation",
    reset: "Reset",
    resetChart: "Reset Chart",
    resetColumnWidths: "Reset Column Widths",
    resetFilters: "Reset Filters",
    resetToDefault: "Reset to Default",
    resetWidth: "Reset Width",
    resources: "Resources",
    responseObject: "Response Object",
    restartImportReset:
      "This will cancel your current migration and void the previous confirmation link.",
    restore: "Restore",
    restored: "Restored...",
    results: "Results",
    resumeTracking: "Resume Tracking",
    retry: "Retry",
    return: "Return",
    review: "Review",
    reviewAndSave: "Review & Save",
    reviewConfirm: "Review & save changes",
    reviewConfirmChanges: "Review & Save the Changes to the System",
    reviewImport: "Review Import",
    reviewNetworkInfo: "Review Network Information",
    revisions: "Revisions",
    revokeEnrollmentToken: "Revoke Enrollment Token",
    revokeEnrollmentTokenDesc:
      "Other pending devices will be affected by this action",
    revokeToken: "Revoke Token",
    revokeTokenAffectedGateways:
      "These gateways are also using the same enrollment token. If you revoke the token before they are admitted to Crosswork Cloud they will need a new token to be issued.",
    revokeTokenAffectedGatewaysAdditionalInfo:
      "If you would like to remove access to only one of these gateways, grant access to the other gateways first and then revoke the token. This will allow the existing token to be used to add the desired gateways.",
    revokeTokenAffectedGatewaysInfo:
      "If you would like to deny all the devices access then revoke the token and the gateways will no longer request enrollment",
    revokeTokenInfo:
      "By revoking this enrollment token the pending device will no longer be able to request access to this system. A new enrollment token will have to be assigned to this device to attempt future enrollment. Are you sure you would like to revoke this token? ",
    rightToUse: "Right to Use",
    rirInformation: "RIR Information",
    rirLocation: "RIR Location",
    rirState: "RIR State",
    roa: "ROA",
    roaAsn: "ROA ASN",
    roaAsnFilterErrorMsg: "Use numeric value for ASNs, separated by space ( )",
    roaAsnFilterHint: "Use a space ( ) to separate each ASN",
    roaExpiryDescription:
      "Detects when a Route Origin Authorization (ROA) will expire and alerts you according to the days you specify before the expiration date.",
    roaFailureDescription:
      "Detects invalid Resource Public Key Infrastructure (RPKI) information.",
    roaHealth: "ROA Health of AS Prefixes",
    roaMaxLength: "ROA's max length does not cover this prefix",
    roaMaxLengthLabel: "ROA Max Length",
    roaNotFoundDescription:
      "Detects when a monitored prefix does not have a valid ROA certificate.",
    roaPrefix: "ROA Prefix",
    roaRecords: "ROA Records",
    roaSource: "ROA Source",
    roaSources: "Prefix ROA Sources",
    roaStatus: "ROA Status",
    roaWasNotFound: "ROA not found",
    role: "Role",
    roles: ["Invalid", "Admin", "Read Write", "Read Only"],
    route: "Route",
    routeRefresh: "Route Refresh",
    routerId: "Router ID",
    routersCollected: "Number of Routers Collected",
    routingKey: "Routing Key",
    routeOriginValidation: "Route Origin Validation",
    routeOriginValidationDescription:
      "Compare observed origin ASN and maximum prefix length to global prefix registries.",
    rovInvalidIntervalMsg:
      "Request start time & end time gap can't be greater than 7 days",
    rovStatus: "ROV Status",
    rovViolations: "ROV Violations",
    rpsl: "RPSL",
    rpslClassificationStatuses: `[
      "Mismatch: The observed origin ASN for thix prefix differs from the one listed in the RPSL database.",
      "Exact Match: The prefix matches the ASN in RPSL and WhoIS databases.",
      "Partial Match: The prefix matches the parent ASN in the RPSL, but not exactly.",
      "No RPSL: No RPSL records found for the prefix or its parent."
    ]`,
    rpslStatus: "RPSL Status",
    rule: "Rule",
    ruleEndpoints: "Rule Endpoints",
    rules: "Rules",
    ruleSpecificNotificationEndpoints: "Rule Specific Notification Endpoints",
    running: "Running",
    runningHash: "Running Hash",
    runningMismatch: "Running Mismatch",
    runtime: "Runtime",
    runtimeProtectionsOSCASLRXSpaceDetails:
      "Built-in Runtime protections within the IOS XR Operating System to prevent common vulnerabilities. Includes object size checking, address space layout randomization, and executable space protections.",
    rx: "RX",
    rxRatio: "RX: Ratio",
    rxAverage: "RX: Average",
    rxAvg: "RX: Avg",
    rxMax: "RX: Max",
    rxNetflow: "RX: Netflow",
    rxP95: "RX: P95",
    rxSnmp: "RX: SNMP",
    rxTraffic: "RX Traffic",
    rxTransitAverage: "RX: Transit Average",
    rxTxTraffic: "Device traffic flowing in the past hour",
    s3: "Amazon S3",
    s3Description:
      "Configure a notification, such as an alarm, to be sent to an Amazon Simple Storage Service account.",
    salesProspect: "Sales support request from {user} in {org}\n\n",
    salesPurchase: "Sales Purchase",
    salesPurchaseDescription:
      "Please contact our sales team if you would like to purchase a license for Cisco Traffic Analysis, Trust Insights and External Route Analysis.",
    salesSupport: "Sales Support",
    salesSupportSuccess: "Request sent to sales successfully",
    salesTeamMessage:
      "Hello Sales Team,\n\nI'm interested in purchasing Crosswork Cloud subscription and would like more information.\n\n\nRegards\n\n{user}\n{org}",
    salesTeamSubHeader:
      "Please give a brief description to our sales team why you would like a further extension      to try out our products and we will get back to you soon.",
    sampleAsPath: "Sample AS Paths",
    sampleAsPathHint:
      "Separate multiple paths by using a comma “,” or by putting it on a new line",
    netflowSamplingRateOverride: "Netflow Sampling Rate Override",
    netflowSamplingRateOverrideHint:
      "Maximum value is {max}. A value of zero will configure automatic sampling rate detection from netflow.",
    saveVerify: "Save & Verify",
    savingProgress: "Saving Progress, please wait",
    scanTime: "Scan Time",
    schema: "Schema",
    scope: "Scope",
    search: "Search",
    searchAll: "Search All",
    searchAsNamePreReq:
      "Please enter at least 3 characters to search an AS name",
    searchAsPrefixOrIp:
      "Search an ASN name or number, prefix, or IP to view its details.",
    searchingAsName: "Searching AS Name",
    second: "Second",
    secureJTAGDetails:
      "Advanced hardening for protection against attacks on internal JTAG systems interfaces (often used in manufacturing and advanced debugging processes).",
    securityLevel: "Security Level",
    seenBefore: "Seen Before",
    seenElseWhere: "Seen Elsewhere",
    select: "Select",
    selectAll: "Select All",
    selectAPolicyType: "Select a Policy Type",
    selectAsn: "Select ASN",
    selectCountry: "Select Country",
    selectCredential: "Select Credential",
    selectDate: "Select a date",
    selectDateTime: "Select Date & Time",
    selectDevice: "Select Device",
    selectDevices: "Select Devices",
    selectDevicesDescription:
      "Select the device checkbox you wish to change (monitor or ignore) or click the device name to view more details.",
    selectedForSetup: "{selected} Selected for Setup",
    selectedToSubscribe: "{selected} Selected to Subscribe",
    selectedTotal: "Selected Total",
    selectedUnknownFiles: "Selected unknown files to be added",
    selectExistingPolicy:
      "Select a existing prefix policy to link to the prefixes",
    selectFmt: "Select {obj}",
    selectGateways: "Select Gateways",
    selectGatewaysDescription: "Gateways to monitor or ignore",
    selectInterfaces: "Select Interfaces to Monitor",
    selectInterfacesDescription1:
      "Use filters to help identify the interfaces you want to monitor. By default, only interfaces with unassigned policies are displayed",
    selectInterfacesDescription2:
      "To add an interface, click Add under the Device Interface Queue column ",
    selectNetworkType: "Select Type of Network",
    selectNone: "Select None",
    selectNotification:
      "Select an existing or add a new notification endpoint to which we will send notifications when alarm conditions are met. If you skip this step, you can add notification endpoints to policies at a later time.",
    selectPeerAsn: "Select a Peer ASN",
    selectPeerAsnDetails: "A Peer ASN is required to optimize a peer",
    selectPolicyDevicesDescription1:
      "Use filters to help identify the devices you want to monitor. By default, only devices with unassigned policies are displayed.",
    selectPolicyDevicesDescription2:
      "To add a device, click Add under the Queue column or Add Filter Results (if applicable)",
    selectPurchaseMethod: "Select Purchase Method",
    selectSingleDevice: "Select a Device",
    selectWhatToCompare: "Select What to Compare",
    selectWhatToCompareDesc:
      "Select what type of data you would like to compare",
    send: "Send",
    sendActivationEmail: "Send Activation Email",
    sendOnTrigger: "Send On Trigger",
    sendTo: "Send To",
    separateLabelWithComma: "Enter a comma (,) as you type label to commit it.",
    serial: "Serial",
    serialNumber: "Serial Number",
    series: "Series",
    serviceLevel: "Service Level",
    serviceProvider: "Service Provider",
    serviceTier: "Service Tier",
    sessionsEstablishedDropped: "Sessions Established/Dropped",
    setExternal: "Set External",
    setInterfaceTooltip: "Please select interfaces below",
    setInternal: "Set Internal",
    setOrgName: "Set Organization Name",
    settings: "Settings",
    settingsSystem: "System Information - {orgName}",
    setup: "Setup",
    setupChecklist: "Setup Checklist",
    setupNetflow: "Enable Netflow Traffic Analysis",
    setupNetflowSubHeader: "Generate Netflow data in Crosswork Cloud",
    setupPrefixes: "Set up Prefixes",
    setYourPreferences: "Set Your Preferences",
    severity: "Severity",
    sfpModule: "SFP Module",
    SHA1: "SHA1",
    SHA224: "SHA224",
    SHA256: "SHA256",
    sha256File: "File: SHA-256",
    SHA384: "SHA384",
    SHA512: "SHA512",
    shaFingerprint: "SHA 1 Fingerprint",
    show: "SHOW",
    showPaths: "Show Paths",
    signatureAlgorithm: "Signature Algorithm",
    signingKeyChangeDetected: "Signing Key Change Detected",
    signOnSupport: "Sign on Support",
    signOnSupportDescription: "Technical support for Single Sign-on",
    signOut: "Sign Out",
    signOutForm: "Sign Out form",
    signUp: "Sign Up",
    signupFooter: "If you need help creating an account, see the ",
    signupSuccess1:
      "You have successfully created a new account in Crosswork Cloud. In order to get started and create your new organization, please visit crosswork.cisco.com from a desktop browser.",
    singleDevice: "Single Device",
    singleSignOn: "Single Sign On",
    siteMap: "Site Map",
    skewed: "Skewed",
    skewedClocks: "Skewed Clocks",
    skipForNow: "Skip for Now",
    skipStep: "Skip this Step",
    slack: "Slack",
    slackDescription:
      "Configure a notification, such as an alarm or report, to be sent to a Slack channel.",
    small: "Small",
    smallToMediumBusiness: "Small To Medium Business",
    sms: "SMS",
    smsDescription:
      "Configure a notification, such as an alarm or report, to be sent as SMS message.",
    snapshot: "Snapshot",
    snmp: "SNMP",
    snmpAddress: "SNMP Address",
    snmpCredential: "SNMP Credential",
    snmpLastSeen: "SNMP Last Seen",
    snmpStatus: "SNMP Status",
    snmpV2: "SNMPv2",
    snmpV2Description: "",
    snmpV3: "SNMPv3",
    snmpV3Description: "",
    snoozeAlarms: "Snooze {alarm}?",
    snoozeAlarmsDesc:
      "Snoozed alarms will not send notifications until a specific time has passed or they are unsnoozed. Select how long you would like to ignore this alarm.",
    snoozeDuration: "Snooze Duration",
    snoozeOnceModalOption: "Once (until cleared)",
    snoozePrefixesSuccess: "Prefix(es) successfully snoozed.",
    snoozeFailed: "Unable to snooze alarm(s)",
    snoozeSuccess: "Alarm(s) Snoozed",
    snoozedUntil: "Snoozed Until",
    snoozeUntilPrefix: "Until ",
    snoozed: "Snoozed",
    software: "Software",
    softwareChanges: "Software Changes",
    softwareDifferences: "Software Differences",
    softwareIntegrityAnalysis: "Software Integrity Analysis",
    softwareIntegrityRuleSubtitle:
      "Indicate the number of software integrity failures required to trigger a notification.",
    softwareRevision: "Software Revision",
    softwareType: "Software Type",
    softwareUsage: "Software Usage",
    softwareValidation: "Software Integrity Validation",
    softwareValidationDescription:
      "Trust Insights validates software measurements against known-good-values collected in the IOS XR build and release processes.\n",
    softwareVersion: "Software Version",
    source: "Source",
    sourceDataGateway: "Source Data Gateway",
    sourceDevice: "Source Device",
    sources: "Sources",
    southBound: "South Bound",
    southBoundStaticRoute: "South Bound Static Route",
    speed: "Speed",
    speedOption1: "Upto 1 Gbps",
    speedOption2: "1Gbps - 2Gbps",
    speedOption3: "2Gbps - 10Gbps",
    speedOption4: "10Gbps - 100Gbps",
    speedOption5: "100Gbps - 400Gbps",
    spoolAge: "Spool Age",
    spoolDiscards: "Spool Discards",
    spoolFull: "Spool Full",
    ssh: "SSH",
    sshCredential: "SSH Credential",
    sshDidNotInitialize: "SSH did not initialize",
    sshFailure: "SSH failure",
    sshHostKeyApprovalPending: "SSH host key approval pending",
    sshHostKeyMismatch: "SSH Host Key Mismatch",
    sshHostKeySuccess: "SSH host key accepted successfully.",
    sshHostKeyViolationRuleSubtitle:
      "Choose the severity level of the alarm when a device SSH host key is invalid.",
    sshKeyCopyCommand: "cat <ssh key> | cut -f 2 -d ' ' | base64 -d > key.bin ",
    sshKeyCopyInstruction: "copy key.bin onto the XR device",
    sshKeyCreatedModalDescription:
      "Copy the public key using the button below then run",
    sshPassword: "SSH Password",
    sshPort: "SSH Port",
    sshPublicKey: "SSH Public Key",
    sshv2: "SSHv2",
    sshv2Description: "",
    stale: "Stale",
    staleAlarmBanner: "Alarm is stale",
    standard: "Standard",
    start: "Start",
    startAdvancedTrial: "Start Advanced Trial",
    startAdvancedTrialDetail:
      "To get more functionality and features. Start a trial of advanced features.",
    startATrial: "Start a Trial",
    startDate: "Start Date",
    startsWithFilterDesc: "Starts-With Filter",
    startTime: "Start Time",
    startTrial: "Start Trial",
    state: "State",
    stateTransitionAt: "State Transition At",
    staticRouteHelpText:
      "Enter comma (,) separated IP addresses of static route for south bound interface (eth1)",
    staticRoutes: "Static Routes",
    staticRouting: "Static Routing",
    stats: "Stats",
    statsUpdated: "Stats Updated",
    status: "Status",
    statusType: "Status Type",
    step1Text:
      "Enter the ASN you want to monitor. The ASN is used to find prefixes to subscribe to.",
    step2Text:
      "We discovered the following prefix(es) based on the ASN(s) you entered. Select the prefixes you wish to setup.  We will subscribe and assign a policy rule set for each selected prefix.",
    step3Text:
      "Next we will add policies to your tracked ASNs and Prefixes. By default newly created policies will include all possible rules.",
    step4Text:
      "Select and/or add destinations you would like attached to the newly created policies and rules, if any.",
    step5Text:
      "Below are the changes that will be made once you click “submit”. Please verify as they cannot be easily undone once you click “submit”.",
    stepsForParity: " Steps for Parity",
    string: "string",
    submitConfirmation:
      "Click Submit to save changes and start using the system.",
    subPageSelection: "Sub-Page Selection",
    subPrefixAdvertisementDescription:
      "Detects when a specific subnet (a longer prefix match) of the prefix is advertised.",
    subprefixAlarmDetailsOverviewDescription1: "There are currently ",
    subprefixAlarmDetailsOverviewDescription2:
      " unexpected Subprefixes being announced under ",
    subprefixes: "Subprefixes",
    subprefixRuleMaskIPv4Header: "IPv4 Prefixes",
    subprefixRuleMaskIPv6Header: "IPv6 Prefixes",
    subprefixRuleMaskSectionDescription:
      "Set the sliders to set the threshold to ignore longer prefixes",
    subprefixRuleMaskSectionHeader: "Ignore Prefix Mask Length Threshold",
    subscribe: "Subscribe",
    subscribeAsnsTextareaHint: "Use a comma (,) or space to separate each ASN",
    subscribeCrossworkCloud: "Subscribe Crosswork Cloud",
    subscribed: "Subscribed",
    subscribedPrefixes: "Subscribed Prefixes",
    subscribePeersPolicyFieldInfoText:
      "Changes to this field are applied to all selected peers.",
    subscribePrefixesSuccess: "Prefix(es) subscribed successfully.",
    subscribePrefixTextareaHint:
      "Use a comma (,) or space to separate each prefix",
    subscribeSubprefixes: "Subscribe Subprefixes",
    subscribeToPrefixes: "Subscribe to Prefixes",
    subscribeTopSubprefixes: "Subscribe Top {count} Subprefixes",
    subscription: "Subscription",
    subscriptionFailure:
      "At least one of the subscription IDs entered was invalid or already claimed. Verify you entered the correct ID. If the error remains, please contact the support team at ",
    subscriptionId: "Subscription ID",
    subscriptionIdForTenancy:
      "Subscription ID (If available or a free account will be created)",
    subscriptionIds: "Subscription ID's",
    subscriptionIdsErrorHint:
      "The subscription ID you entered cannot be claimed.",
    subscriptionIdsHint: "Enter Subscription ID's separated by comma",
    subscriptionPlans: "Subscription Plans",
    subscriptions: "Subscriptions",
    subscriptionStatus: "Subscription Status",
    subscriptionsWillBeRemoved:
      "The following subscriptions will be removed from the current subscription claim",
    subscriptionTerm: "Subscription Term",
    subscriptionTiers: "Subscription Tiers",
    success: "Success",
    sudi: "SUDI",
    SUDIDetails:
      "Secure Unique Device Identity (SUDI) is a manufacturer certificate and private key stored in tamper-resistant Trust Anchor Module (TAm). SUDI can provide tamper-resistant cryptographic verification of component authenticity, serial number and part number.",
    summarizing: "Summarizing",
    summary: "Summary",
    support: "Support",
    supportDesc: "Select an option below to get help from Cisco.",
    swIntegrityAnalysisSubHeader:
      "Analysis of software files and packages on your device",
    system: "System",
    systemChangeHistory: "System Change History",
    systemChangeHistoryDescription:
      "Trust Insights will detect changes in reported operational events such as device reboot and configuration rollback.",
    systemObject: "System Object",
    systemObjectDescription: "Choose the object type you wish to track.",
    table: "Table",
    tableSettings: "{action} Table Settings",
    tableSettingsRemoved: "Table Settings removed successfully",
    tableSettingsSaved: "Table Settings saved successfully",
    tableView: "Table View",
    tag: "Tag",
    tags: "Tags",
    tagsDonut: "Tags",
    tagsListUnavailable: "Tags List Unavailable",
    tagsListUnavailableDesc:
      "Auto-completion of existing tags will not be available when editing",
    tagsNotSavedFmt: "Tags Not Saved: {error}",
    tagsSaved: "Tags Saved",
    teamsDescription:
      "Configure a notification, such as an alarm or report, to be sent to a Microsoft Teams account.",
    technicalSupport: "Technical Support",
    technicalSupportDescription:
      "Obtain technical support when using Crosswork Cloud APIs.",
    telemetry: "Telemetry",
    telemetryDevices: "Telemetry Enabled Devices",
    telemetryDisabledTooltip:
      "Telemetry settings can be applied to only one device",
    telemetryEnabling: "Telemetry Enabling",
    telemetryPortHint: "Port number must be greater than 1024",
    telemetrySettings: "Telemetry Settings",
    telemetrySettingsUpdateSuccess: "Telemetry settings updated successfully",
    term: "Term",
    terminated: "Terminated",
    termMonths: "{month}  month(s)",
    termsAndConditions: "Terms & Conditions",
    termsDisclaimer:
      "Free trials are subject to approval by Cisco and may be cancelled at any time",
    testing: "Testing",
    testResults: "Test Results",
    thanks: "Thanks,",
    theGateway: "the Gateway",
    theme: "Theme",
    themes: {
      dark: "Dark (default)",
      highContrast: "High Contrast",
      light: "Light"
    },
    thingBeingComparedFmt: "{thing} Being Compared",
    thirtyMinutes: "30 Minutes",
    thisPeer: "This Peer",
    threeMonth: "3 Months",
    tier: "Tier",
    tierInformation: "Tier Information",
    tierSelection: "Tier Selection",
    time: "Time",
    timeComparison: "Time Comparison",
    timedOut: "Timed out",
    timeframe: "Timeframe",
    timeOutOfRange:
      "The selected time must be between {minTime} and {maxTime}, correct and submit again",
    timeSinceCollected: "Time Since Collected",
    timestamp: "Timestamp",
    timezone: "Timezone",
    title: "Cisco Crosswork Cloud",
    to: "to",
    toFrom: "To/From",
    toGetStarted:
      "Cisco Crosswork External Analysis allows you to track relevant changes to the prefixes you care about. ",
    tokenCreatedDesc:
      "This is your new token. It will only be displayed this one time. A new token will have to be created if this key is lost. ",
    tokenId: "Token Id",
    enrollmentTokens: "Enrollment Tokens",
    tokenName: "Token",
    tokenNumUses: "Number of Uses",
    tokensDeleteConfm: "Bearer Token(s) removed",
    tools: "Tools",
    topActiveSeverity: "Top Active Severity",
    topDeviceToFromAsns: "Top Device To/From ASNs",
    topDeviceTransitAsns: "Top Device Transit ASNs",
    topSeverity: "Top Severity",
    total: "Total",
    totalChanges: "Total Changes",
    totalKGVInPackage: "Total KGV in Package",
    totalPrefixCount: "Total Prefix Count",
    totalPrefixes: "Total Prefixes",
    totalTraffic: "Total Traffic",
    totalViolationPeers: "Total Violation Peers",
    trackedDevices: "Tracked Devices",
    trackedDevicesDescription:
      "The listed devices will be monitored by the above triggers.",
    trackedInterfaces: "Tracked Interfaces",
    trackedInterfacesDescription:
      "The listed interfaces will be monitored by the above triggers.",
    trademarks: "Trademarks",
    traffic: "Traffic",
    trafficAggregation: "Traffic Aggregation",
    trafficAlarms: "Traffic Alarms",
    trafficAnalysis: "Traffic Analysis",
    trafficAnalysisGateway: "Traffic Analysis Gateway",
    trafficExpectationInfo1: "The interface",
    trafficExpectationInfo2: "traffic is expected to be",
    trafficExpectationInfo3: "than",
    trafficExpectationInfo4: "and",
    trafficExpectationInfo5: "of the capacity",
    trafficAnalysisSetup: "Traffic Analysis Setup",
    trafficAnalysisTier: "Traffic Analysis {tier}",
    trafficAnalysisTrial: "Traffic Analysis Trial",
    trafficArticle:
      "Crosswork Traffic Analysis aggregates traffic flow data across multiple devices, giving operators a view of the traffic matrix across the whole network. It adds critical context to observed traffic flows based on the existing rich data sets of routing data from the Crosswork Network Insights service. This allows operators to gain a deeper understanding of traffic flows on their networks, as well as the impacts of changes in external and internal routing state and policy. Traffic Analysis enables network operators to engineer automated peering solutions as well as understand the impact of new peering to their network infrastructure.",
    trafficComingSoon:
      "We are working hard to release enhanced functionality and features to help you understand your network traffic.",
    trafficComparison: "Traffic Comparison",
    trafficData: "Traffic Data",
    trafficDecription:
      "Visualize, analyze, and optimize network traffic at distributed peering points.",
    trafficDevices: "Traffic Analysis Enabled Devices",
    trafficDrilldown: "Traffic Drilldown",
    trafficGrouping: "Traffic Grouping",
    trafficNotLicensed: "For more information about Traffic Analysis, contact ",
    trafficPort:
      "Traffic Analysis requires that routers send Netflow traffic to destination port UDP/2055",
    trafficTraversing: "Traffic is Traversing Internal/External",
    transit: "Transit",
    transitAsns: "Transit ASN(s)",
    trends: "Trends",
    trial: "Trial",
    trial90Days: "Up to 90 Day Trial",
    trialAccountRequest: "Trial Account Request",
    trialAvailable: "30 days trial available",
    trialConfirmation:
      "Please follow the instructions in your activation email.",
    trialCreated: "Trial Created successfully",
    trialDisclaimer:
      "Trial accounts are subject to Cisco approval and can be cancelled at any time.",
    trialGuide: " Cisco Crosswork Cloud Trial Guide.",
    trialGuideFooter:
      "If you run into any issues or need more information, see the ",
    trialHeader: "Trial Account Request",
    trialHeaderDescription:
      "Sign up for a trial account of Cisco Crosswork Network Insights",
    trialLicenses: "Trial Licenses",
    trialRequest: "Network Insights Trial Account Request",
    trialRequestDescription:
      "To activate your trial subscription, please provide the requested information, accept terms, and request trial",
    trialRequested: "Trial Requested",
    trialRequestedHeader: "Thank you for requesting a trial",
    trials: "Trials",
    trialSentTo: "Activation link will be sent to",
    trigger: "Trigger",
    triggerAlarm: "Trigger Alarm",
    triggerRulesSubtitle: "Add rules to trigger events you want to monitor",
    triggerRulesTitle: "{type} Rules",
    triggers: "Triggers",
    triggersPolicySubheader:
      "Events that set off an alarm when a rule is enabled",
    trust: "Trust",
    trustAlarms: "Trust Alarms",
    trustAnalysis: "Trust Analysis",
    trustAnchor: "Expirations",
    trustAnchorValue: "{anchor} ROA for {prefix} expires at {time}",
    trustArticle:
      "Crosswork Trust Insights helps to track, analyze, and prove hardware and software components running in your network. Trust Insights works with IOS XR to deliver unique capabilities to gather signed evidence collected from supported devices. We provide a reporting framework that verifies the integrity of this evidence against Known Good Values (KGVs) from the Cisco manufacturing. This delivers a unique view of the trust posture of your network infrastructure and provides an audit of your systems throughout their lifecycle.",
    trustAttestationDetails:
      'IOS XR support for a signed "Trust Dossier" for systems inventory and integrity reporting. Trust Attestation is required for support in Crosswork Trust Insights.',
    trustCDGCannotBeSameAsFlowCDG:
      "Trust Data Gateway cannot be same as Traffic Data Gateway",
    trustData: "Trust Data",
    trustDecription:
      "Gain operational visibility to preserve the trustworthiness of your network infrastructure.",
    trustDevices: "Trust Insights Enabled Devices",
    trustFlowInsights: "Trust/Traffic",
    trustGateway: "Trust Gateway",
    trustHardwareSoftware: "Trust Hardware & Software",
    trustInsights: "Trust Insights",
    trustInsightsDevicesOnly: "Trust Insights devices only",
    trustInsightsTier: "Trust Insights {tier}",
    trustInsightsTrial: "Trust Insights Trial",
    trustMismatchedFilesRuleSubtitle:
      "Indicate the number of files that do not match Cisco IOS XR artifacts or user defined KGVs required to trigger a notification and choose the alarm severity.",
    trustNotLicensed: "For more information about Trust Insights, contact ",
    trustOnlineDocumentation: "Crosswork Trust Insights Documentation",
    trustPolicyConfigAddFilterResultsDisableTooltip:
      "Filtered results are either more than the allowed limit or there are no more items to be added.",
    trustReporting: "Trust Reporting",
    trustTerms:
      "I have read and accept the Terms and Service and Privacy Statement",
    trustUnknownFilesRuleSubtitle:
      "Indicate the number of files that either do not match known Cisco IOS XR signatures or KGVs required to trigger a notification.",
    turnFlowTrustOn:
      "Turn on “Trust Insights” and/or “Traffic Analysis” to save this device.",
    turnTrustFlow: "Turn on ”Trust” or ”Traffic” ",
    twoMonth: "2 Months",
    tx: "TX",
    txRatio: "TX: Ratio",
    txAverage: "TX: Average",
    txAvg: "TX: Avg",
    txMax: "TX: Max",
    txNetflow: "TX: Netflow",
    txP95: "TX: P95",
    txSnmp: "TX: SNMP",
    txTraffic: "TX Traffic",
    type: "Type",
    uiComponentLibrary: "UI Component Library",
    uint32: "uint32",
    uint62: "uint64",
    unableToLoadUrl: "Unable to load the Url",
    unableToPurchaseAWS: "No offerings available",
    unackFailed: "Failed to Unacknowledge Alarm(s)",
    unackSuccess: "Alarm(s) Unacknowledged",
    unassigned: "Unassigned",
    unavailable: "Unavailable",
    unchanged: "unchanged",
    unclaimFlowLicense:
      "The following device right-to-use licensing will be removed from your organization licence pool as they are associated with subscription ",
    unclaimLicense:
      "The selected licenses will be removed from your organization.",
    unclaimLicensePool:
      "The selected licenses will be removed from your organization license pool",
    unclaimSubscriptionFailure: "Failure to Unclaim Subscription(s)",
    unclaimSubscriptionSuccess: "Subscription(s) unclaimed successfully.",
    uncommited: "Uncommited",
    unconfiguredBGPSessions: "Unconfigured BGP Sessions",
    unexpectedAsPrefixDescription:
      "Detects unexpected changes for an AS where a new prefix was not previously there.",
    unexpectedData: "Unexpected data",
    unexpectedError: "Unexpected Error",
    units: "Units",
    unknown: "Unknown",
    unknownFiles: "Unknown Files",
    unknownFilesDescription:
      "Detects unknown files that do not match known Cisco IOS XR signatures or user defined Known Good Values (KGV).",
    unknownFilesNotAvailable:
      "Unknown files reporting is unavailable for this XR release",
    unknownFilesNotFound: "There are no unknown files detected for this device",
    unknownFilesTooltip:
      "The details in the observed section are based on the state as of the last dossier collection. Files added to the Known Good List will not be reported in the next collection.",
    unknownNetflowExporters: "Unknown Netflow Exporters",
    unlimitedUsers: "Unlimited Users",
    unlimitedUsersDescription:
      "Allows an unlimited amount of users to access Crosswork Cloud applications with one set of login credentials.",
    unlinked: "Unlinked",
    unlinkPrefixesSuccess: "Prefix(es) successfully unlinked.",
    unmonitorAsnSuccess: "ASN(s) unmonitored successfully.",
    unsnoozeFailed: "Failed to Unsnooze Alarm(s)",
    unsnoozeSuccess: "Alarm(s) Unsnoozed",
    unpausePrefix: "Resume Prefix",
    unpausePrefixes: "Resume Prefix(es)",
    unpausePrefixesSuccess: "Prefix(es) successfully resumed.",
    unreachable: "Unreachable",
    unrecognizedFRU: "Unrecognized Field Replaceable Unit",
    unsavedChangesDesc:
      "You have unsaved changes on this page that will be lost if you navigate away. To keep your changes, continue editing and save your changes before leaving this page.",
    unsavedChangesTitle: "Unsaved Changes",
    unsnoozePrefixes: "Unsnooze Prefix(es)",
    unsnoozePrefixesSuccess: "Prefix(es) successfully unsnoozed.",
    unspecified: "Unspecified",
    unsubscribed: "Unsubscribed",
    unSubscribePrefixesSuccess: "Prefix(es) unsubscribed successfully.",
    unsupportedTrafficType: "Unsupported Traffic Type",
    unverified: "Unverified",
    up: "Up",
    updateAdvertisedPrefixCount: "Update Advertised Prefix Count",
    updated: "Updated",
    updatedAt: "Updated At",
    updateDevice: "Update Device",
    updatedKGF: "Updated Known Good Files status successfully",
    updatedOrgSuccess: "Organization Name updated successfully",
    updatePolicyPeerCounts: "Update Policy Peer Counts",
    updateType: "Update Type",
    uploadFile: "Upload File",
    uploadGoogleKeyFile: "Upload JSON Key File",
    uploadingPrefixes: "Uploading prefixes...",
    uploadVMCdgBundle: "Upload VM CDG Bundle",
    upstream: "Upstream",
    upstreamAs: "Upstream AS",
    upstreamAsChangeDescription:
      "Detects a route leak to an existing peer that should not propagate a prefix.",
    upstreamAsChangeDescriptionMyPeers:
      "Detects a route leak to an existing peer that should not propagate a prefix on my peers.",
    upstreamAsCount: "Upstream AS Count",
    upstreamASNs: "Upstream ASNs",
    upTime: "Uptime",
    upToFivePrefixes: "up to five prefixes for a year. ",
    usageExamples: "Usage Examples",
    USCANADACallTimings: "US/CANADA | 5am-5pm Pacific",
    useChartToModifySelection: "Use the chart below to modify selection",
    used: "Used",
    useOriginAsns: "Use Origin ASNs",
    user: "User",
    userAccepted: "User Accepted",
    userAddFailure: "At least one of the users couldn't be added.",
    userAnalyticsTooltip:
      "Allow Crosswork Cloud to track activity and errors to help us improve user experience and streamline tasks.",
    userAnalyticsTracking: "User Analytics Tracking",
    userDeleteFailure: "Could not remove user",
    userDeleteSuccess: "User Removed",
    userIdsErrorHint: "These user IDs couldn't be added",
    username: "Username",
    userNavigation: "User Navigation",
    userOptions: "User Options",
    userPreference:
      "Set your user preferences. You can modify these settings later if needed.",
    userPrefUpdateSuccess: "Preferences updated successfully.",
    userProfile: "Profile",
    users: "Users",
    userSaveFailure: "Could not save user",
    userSaveSuccess: "User Saved",
    userSpaceName: "User Name",
    userUpdateSuccess: "User updated successfully.",
    utilization: "Utilization",
    v4IpDestinations: "V4 IP Destinations",
    valid: "Valid",
    validArray: "Must be an array",
    validASPathDescription: "Detects unexpected BGP AS-path changes.",
    validASPathDescriptionMyPeers:
      "Detects unexpected BGP AS-path changes on my peers.",
    validDossiers: "Valid Dossiers",
    validFrom: "Valid From",
    validity: "Validity",
    validityFrom: "Validity From",
    validityTo: "Validity To",
    validMaxArrayLength: "Must not have more than {maxLen} {items}",
    validROA: "Valid ROA",
    validROACount: "Valid ROA Count",
    validRPSL: "Valid RPSL",
    validRPSLCoverage: "Valid RPSL Coverage",
    validRPSLPrefixCoverage: "Valid RPSL Prefix Coverage",
    validUntil: "Valid Until",
    value: "Value",
    values: "Values",
    vendorType: "Vendor Type",
    verification: "Verification",
    verificationCode: "Verification Code",
    verificationCodeHint:
      "Enter the verification code that was sent to the endpoint",
    verificationConfmn:
      "We have sent a verification code to the {endpoint}. Enter the code that was sent in the field below to verify ownership over this endpoint and activate it.",
    verificationResent:
      "A new verification code has been sent to this endpoint",
    verificationSent: "Verification Sent",
    verified: "Verified",
    verify: "Verify",
    verifyAuthenticationPwd: "Verify Authentication Password",
    verifyNewPassword: "Verify New Password",
    verifyPassword: "Verify Password",
    verifyPrivacyPassword: "Verify Privacy Password",
    verifySuccess: "Endpoint Verified",
    version: "Version",
    versionChangeSeperator: " to ",
    viaDistributor: "Via Distributor",
    viaPartner: "Via Partner",
    view: "View",
    viewAll: "View All",
    viewAllDevices: "View All Devices",
    viewAllGateways: "View All Gateways",
    viewAllTags: "View All Tags",
    viewAsns: "View ASNs",
    viewDetails: "View Details",
    viewDevices: "View Devices",
    viewEnrollmentToken: "View Enrollment Token",
    viewFlowReplicationSubTitle:
      "Use this page to view and configure duplicate Netflow traffic from devices and Data Gateway instances.",
    viewing: "Viewing",
    viewingSelected: "Viewing Selected",
    viewingSnapshot: "Viewing Snapshot",
    viewPeermonMigrationGuide: "View Peermon Migration Guide",
    viewPrefixes: "View Prefixes",
    viewPunchlist: "View Punchlist",
    viewSubscriptions: "View Subscriptions",
    viewViolations: "View Violations",
    violating: "Violating",
    violatingPath: "Violating Path",
    violatingPrefix: "Violating Prefix",
    violatingPrefixes: "Violating Prefixes",
    violationAt: "Violation At",
    violationPeers: "Violation Peers",
    volume: "Volume",
    warning: "Warning",
    watchVideo: "Watch Video",
    webexDescription:
      "Configure a notification, such as an alarm or report, to be sent to a Webex Teams account.",
    webhookUrl: "Webhook URL",
    week: "Week",
    weekly: "Weekly",
    weeklyAvg: "Weekly Avg",
    weekOf: "Week Of",
    welcome: "Welcome",
    whoIsData: "Whois Data",
    willBe: "Will be",
    withdrawals: "Withdrawals",
    withdrawn: "Withdrawn",
    withdrawnPrefixes: "Withdrawn Prefixes",
    working: "Working",
    worstCpu: "Worst CPU",
    worstMem: "Worst Memory",
    worstNpu: "Worst NPU",
    xMins: "{x} mins",
    xHour: "{x} hour",
    xHours: "{x} hours",
    xrEnhancedFileSystemIntegrityReporting:
      "IOS XR Enhanced File System Integrity Reporting",
    xrEnhancedFileSystemIntegrityReportingDescription:
      "Trust Insights reports on runtime file modification as reported by the IOS XR enhanced filesystem integrity process.",
    xrPackageSignatureSupport: "IOS XR Package Signature Support",
    xrPackageSignatureSupportDescription:
      "Trust Insights compares IOS install-time and runtime package signatures against known-good-values from the IOS XR build and release process.",
    xwCloudAskAssistanceEmailSubject: "Login Problems",
    xwCloudAskAssistanceLine1:
      "If you encounter any login issues, please contact ",
    xwCloudAskAssistanceLine2: " for assistance.",
    xwCloudAskAssistanceTrafficChecklistEmailSubject:
      "Having Issues with Traffic Analysis Setup",
    year: "Year",
    yes: "Yes",
    you: "You",
    yourQueueIsFull: "Your queue is full",
    yourTrialHasBeenExpanded:
      "Your trial has been expanded to support {newLimit} devices. Previous trial limit was {originalLimit} devices.",
    zoom: "Zoom"
  }
};
