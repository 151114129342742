import * as React from "react";
import {
  CctaCloudIntegrationRuleType,
  CctaCloudIntegrationRuleTypeMap,
  CctaDeviceSupportRuleType,
  CctaDeviceSupportRuleTypeMap,
  CctaFunctionPackRuleType,
  CctaFunctionPackRuleTypeMap,
  CctaTrafficAnalysisRuleType,
  CctaTrafficAnalysisRuleTypeMap,
  DataLookbackRuleType,
  DataLookbackRuleTypeMap,
  SubscriptionRuleType,
  SubscriptionRuleTypeMap,
  SubscriptionTierMap,
  ContentRuleTypeMap,
  ContentRuleType
} from "generated-proto-files/ccsubs_gen_pb";
import { label } from "../../../core/global";
import { PlanCategories } from "./commonTierRules";
import { unreachable } from "../../../utils/switch";
import "./commonTier.scss";
import { SubscriptionType } from "generated-proto-files/subscriptions_pb";

export const trafficPlanCategory = [
  "devices",
  "subscription",
  "alarms",
  "lookback",
  "analysis",
  "functionpack",
  "xwintegration",
  "devicesupport",
  "sso"
] as const;

export const enforceCCTARules = (): boolean => {
  return !!window.xw.tierInfo?.featureTierInfo?.getCctaRules()?.getDoEnforce();
};

export const enforceCCTAContentRules = (): boolean => {
  const enforceContentRules = !!window.xw.tierInfo?.featureTierInfo
    ?.getCctaRules()
    ?.getDoEnforceContentRules();
  // if false, revert back to default behavior
  if (!enforceContentRules) {
    return enforceCCTARules();
  }
  return enforceContentRules;
};

export const getCurrentFlowPlan = ():
  | SubscriptionTierMap[keyof SubscriptionTierMap]
  | undefined => {
  return window.xw.tierInfo?.featureTierInfo?.getCctaRules()?.getFeatureTier();
};

export const getFlowCategoriesLabel = (
  categories: PlanCategories
): React.ReactNode => {
  const trafficCategories = categories as typeof trafficPlanCategory[number];
  switch (trafficCategories) {
    case "devices":
      return label.permittedDevices;
    case "subscription":
      return label.subscription;
    case "alarms":
      return label.trafficAlarms;
    case "lookback":
      return label.dataLookBack;
    case "analysis":
      return label.trafficAnalysis;
    case "functionpack":
      return (
        <div className="xw-common-ccta-tier">
          {label.functionPack}
          <div className="fp-header"> {label.purchasedSeparately}</div>
        </div>
      );
    case "xwintegration":
      return label.crossworkCloudIntegrations;
    case "devicesupport":
      return label.deviceSupport;
    case "sso":
      return label.singleSignOn;
    default:
      unreachable(trafficCategories);
      return label.null;
  }
};

export const isCCTATrial = (): boolean => {
  return (
    window.xw.tierInfo?.features?.getTrafficAnalysisType() ===
    SubscriptionType.TYPE_TRIAL
  );
};

export const trafficAnalysisLabelDescription = (
  taRuleMap: CctaTrafficAnalysisRuleTypeMap[keyof CctaTrafficAnalysisRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (taRuleMap) {
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_PREFIX:
      return {
        label: label.prefixTrafficAnalysis,
        description: label.prefixTrafficAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_PREFIX_GROUP:
      return {
        label: label.prefixTrafficGroupAnalysis,
        description: label.prefixTrafficGroupAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_ASN:
      return {
        label: label.asnTrafficAnalysis,
        description: label.asnTrafficAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_ASN_GROUP:
      return {
        label: label.asnTrafficGroupAnalysis,
        description: label.asnTrafficGroupAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_INTERFACE:
      return {
        label: label.interfaceTrafficAnalysis,
        description: label.interfaceTrafficAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_INTERFACE_GROUP:
      return {
        label: label.interfaceTrafficGroupAnalysis,
        description: label.interfaceTrafficGroupAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_DEVICE:
      return {
        label: label.deviceTrafficAnalysis,
        description: label.deviceTrafficAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_DEVICE_GROUP:
      return {
        label: label.deviceTrafficGroupAnalysis,
        description: label.deviceTrafficGroupAnalysisDescription
      };
    case CctaTrafficAnalysisRuleType.CCTA_TRAFFIC_ANALYSIS_RECOMMENDATIONS:
      return {
        label: label.recommendations,
        description: label.deviceTrafficAnalysisRecommendationDescription
      };
    case CctaTrafficAnalysisRuleType.INVALID_CCTA_TRAFFIC_ANALYSIS_RULE_TYPE:
      return undefined;
    default:
      unreachable(taRuleMap);
      return undefined;
  }
};

export const trafficFunctionPackLabelDescription = (
  taFunctionPackRuleMap: CctaFunctionPackRuleTypeMap[keyof CctaFunctionPackRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (taFunctionPackRuleMap) {
    case CctaFunctionPackRuleType.CCTA_FUNCTION_PACK_PEER_PROSPECTING:
      return {
        label: label.peerProspecting,
        description: label.peerProspectingDescription
      };
    case CctaFunctionPackRuleType.CCTA_FUNCTION_PACK_PEERING_OPTIMIZATION_EQUAL:
      return {
        label: label.loadBalancingEqualShare,
        description: label.loadBalancingEqualShareDescription
      };
    case CctaFunctionPackRuleType.CCTA_FUNCTION_PACK_PEERING_OPTIMIZATION_UNEQUAL:
      return {
        label: label.loadBalancingUnEqualShare,
        description: label.loadBalancingUnEqualShareDescription
      };
    case CctaFunctionPackRuleType.INVALID_CCTA_FUNCTION_PACK_RULE_TYPE:
      return undefined;
    default:
      unreachable(taFunctionPackRuleMap);
      return undefined;
  }
};

export const trafficIntegrationLabelDescription = (
  taIntegrationRuleMap: CctaCloudIntegrationRuleTypeMap[keyof CctaCloudIntegrationRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (taIntegrationRuleMap) {
    case CctaCloudIntegrationRuleType.CCTA_CLOUD_INTEGRATION_IR_ROUTING:
      return {
        label: label.internalRoutingAnalytics,
        description: label.internalRoutingAnalyticsDescription
      };
    case CctaCloudIntegrationRuleType.CCTA_CLOUD_INTEGRATION_FLOW_REPLICATION:
      return {
        label: label.flowReplication,
        description: label.flowReplicationSubscriptionDescription
      };
    case CctaCloudIntegrationRuleType.INVALID_CCTA_CLOUD_INTEGRATION_RULE_TYPE:
      return undefined;
    default:
      unreachable(taIntegrationRuleMap);
      return undefined;
  }
};

export const deviceSupportLabelDescription = (
  deviceSupportRuleMap: CctaDeviceSupportRuleTypeMap[keyof CctaDeviceSupportRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (deviceSupportRuleMap) {
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_MULTI_VENDOR:
      return {
        label: label.multiVendor,
        description: label.multiVendorDescription
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_SSHV2:
      return {
        label: label.sshv2,
        description: label.sshv2Description
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_BGP:
      return {
        label: label.bgp,
        description: label.bgpDescription
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_NETFLOW_V5_V9:
      return {
        label: label.netflowv5v9,
        description: label.netflowDescription
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_IP_FIX:
      return {
        label: label.ipfix,
        description: label.ipfixDescription
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_JFLOW:
      return {
        label: label.jflow,
        description: label.jflowDescription
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_SNMP_V2:
      return {
        label: label.snmpV2,
        description: label.snmpV2Description
      };
    case CctaDeviceSupportRuleType.CCTA_DEVICE_SUPPORT_SNMP_V3:
      return {
        label: label.snmpV3,
        description: label.snmpV3Description
      };
    case CctaDeviceSupportRuleType.INVALID_CCTA_DEVICE_SUPPORT_RULE_TYPE:
      return undefined;
    default:
      unreachable(deviceSupportRuleMap);
      return undefined;
  }
};

export const flowSubscriptionLabelDescription = (
  subscriptionsRuleMap: SubscriptionRuleTypeMap[keyof SubscriptionRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (subscriptionsRuleMap) {
    case SubscriptionRuleType.SUBSCRIPTION_TERM:
      return {
        label: label.subscriptionTiers,
        description: label.cctaSubscriptionTermDescription
      };
    case SubscriptionRuleType.INVALID_SUBSCRIPTION_RULE_TYPE:
      return undefined;
    default:
      unreachable(subscriptionsRuleMap);
      return undefined;
  }
};

export const dataRetentionLabelDescription = (
  dataLookbackRuleMap: DataLookbackRuleTypeMap[keyof DataLookbackRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (dataLookbackRuleMap) {
    case DataLookbackRuleType.DATA_RETENTION_AND_LOOKBACK:
      return {
        label: label.dataRetentionLookback,
        description: label.cctaDataRetentionLookbackDescription
      };
    case DataLookbackRuleType.INVALID_DATA_LOOKBACK_RULE_TYPE:
      return undefined;
    default:
      unreachable(dataLookbackRuleMap);
      return undefined;
  }
};

export const contentRuleLabelDescription = (
  contentRuleMap: ContentRuleTypeMap[keyof ContentRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (contentRuleMap) {
    case ContentRuleType.CONTENT_RULE_ALARM_HISTORY:
      return {
        label: label.alarmHistory,
        description: label.alarmHistoryDescription
      };
    default:
      return undefined;
  }
};
